import React from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer/Footer';
import LandingPage from './pages/LandingPage';

const PharmacistDashboard = () => {
    return (
        <>
            <NavBar />

            <LandingPage />

            <Footer />
        </>
    )
}

export default PharmacistDashboard;
