import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './NavBar.css';
import { Dropdown as DropdownAnt, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaSearch} from 'react-icons/fa';
import { FaBell, FaShoppingCart, FaUserCircle } from 'react-icons/fa';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';

function NavBar() {
    const [activeButton, setActiveButton] = useState(1);
    const navigate = useNavigate()
    const [expanded, setExpanded] = useState(false);
    const [showCategoryDrop, setShowCategoryDrop] = useState(false);



    const items = [
        {
            key: '1',
            label: 'Soins du visage',
            children: [
                {
                    key: '1-1',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes hydratantes',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums et huiles',
                        },
                        {
                            key: '11-3',
                            label: 'Masques',
                        }
                    ],
                },
                {
                    key: '1-2',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes anti-rides',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums anti-âge',
                        }
                    ],
                },
                {
                    key: '1-3',
                    label: 'Nettoyants visage ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Gels nettoyants',
                        },
                        {
                            key: '11-2',
                            label: 'Laits démaquillants',
                        }
                    ],
                },
                {
                    key: '1-4',
                    label: 'Soins anti-imperfections ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Soins acné',
                        },
                        {
                            key: '11-2',
                            label: 'Correcteurs de teint',
                        }
                    ],
                },
            ],
        },
        {
            key: '2',
            label: 'Soins du corps',
            children: [
                {
                    key: '2-1',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes hydratantes',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums et huiles',
                        },
                        {
                            key: '11-3',
                            label: 'Masques',
                        }
                    ],
                },
                {
                    key: '2-2',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes anti-rides',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums anti-âge',
                        }
                    ],
                },
                {
                    key: '3-3',
                    label: 'Nettoyants visage ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Gels nettoyants',
                        },
                        {
                            key: '11-2',
                            label: 'Laits démaquillants',
                        }
                    ],
                },
                {
                    key: '3-4',
                    label: 'Soins anti-imperfections ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Soins acné',
                        },
                        {
                            key: '11-2',
                            label: 'Correcteurs de teint',
                        }
                    ],
                },
            ],
        },
        {
            key: '3',
            label: 'Soins des cheveux',
            children: [
                {
                    key: '3-1',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes hydratantes',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums et huiles',
                        },
                        {
                            key: '11-3',
                            label: 'Masques',
                        }
                    ],
                },
                {
                    key: '3-2',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes anti-rides',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums anti-âge',
                        }
                    ],
                },
                {
                    key: '3-3',
                    label: 'Nettoyants visage ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Gels nettoyants',
                        },
                        {
                            key: '11-2',
                            label: 'Laits démaquillants',
                        }
                    ],
                },
                {
                    key: '3-4',
                    label: 'Soins anti-imperfections ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Soins acné',
                        },
                        {
                            key: '11-2',
                            label: 'Correcteurs de teint',
                        }
                    ],
                },
            ],
        },
        {
            key: '4',
            label: 'Hygiène',
            children: [
                {
                    key: '4-1',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes hydratantes',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums et huiles',
                        },
                        {
                            key: '11-3',
                            label: 'Masques',
                        }
                    ],

                },
                {
                    key: '4-2',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes anti-rides',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums anti-âge',
                        }
                    ],
                },
                {
                    key: '4-3',
                    label: 'Nettoyants visage ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Gels nettoyants',
                        },
                        {
                            key: '11-2',
                            label: 'Laits démaquillants',
                        }
                    ],
                },
                {
                    key: '4-4',
                    label: 'Soins anti-imperfections ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Soins acné',
                        },
                        {
                            key: '11-2',
                            label: 'Correcteurs de teint',
                        }
                    ],
                },
            ],
        },
        {
            key: '5',
            label: 'Compléments alimentaires',
            children: [
                {
                    key: '5-1',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes hydratantes',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums et huiles',
                        },
                        {
                            key: '11-3',
                            label: 'Masques',
                        }
                    ],
                },
                {
                    key: '5-2',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes anti-rides',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums anti-âge',
                        }
                    ],
                },
                {
                    key: '5-3',
                    label: 'Nettoyants visage ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Gels nettoyants',
                        },
                        {
                            key: '11-2',
                            label: 'Laits démaquillants',
                        }
                    ],
                },
                {
                    key: '5-4',
                    label: 'Soins anti-imperfections ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Soins acné',
                        },
                        {
                            key: '11-2',
                            label: 'Correcteurs de teint',
                        }
                    ],
                },
            ],
        },
        {
            key: '6',
            label: 'Bébé et maternité',
            children: [
                {
                    key: '6-1',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes hydratantes',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums et huiles',
                        },
                        {
                            key: '11-3',
                            label: 'Masques',
                        }
                    ],

                },
                {
                    key: '6-2',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes anti-rides',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums anti-âge',
                        }
                    ],

                },
                {
                    key: '6-3',
                    label: 'Nettoyants visage ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Gels nettoyants',
                        },
                        {
                            key: '11-2',
                            label: 'Laits démaquillants',
                        }
                    ],
                },
                {
                    key: '6-4',
                    label: 'Soins anti-imperfections ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Soins acné',
                        },
                        {
                            key: '11-2',
                            label: 'Correcteurs de teint',
                        }
                    ],
                },
            ],
        }
        ,
        {
            key: '7',
            label: 'Produits de protection',
            children: [
                {
                    key: '7-1',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes hydratantes',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums et huiles',
                        },
                        {
                            key: '11-3',
                            label: 'Masques',
                        }
                    ],
                },
                {
                    key: '7-2',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes anti-rides',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums anti-âge',
                        }
                    ],
                },
                {
                    key: '7-3',
                    label: 'Nettoyants visage ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Gels nettoyants',
                        },
                        {
                            key: '11-2',
                            label: 'Laits démaquillants',
                        }
                    ],
                },
                {
                    key: '7-4',
                    label: 'Soins anti-imperfections ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Soins acné',
                        },
                        {
                            key: '11-2',
                            label: 'Correcteurs de teint',
                        }
                    ],
                },
            ],
        },
        {
            key: '8',
            label: 'Soins solaires',
            children: [
                {
                    key: '8-1',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes hydratantes',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums et huiles',
                        },
                        {
                            key: '11-3',
                            label: 'Masques',
                        }
                    ],

                },
                {
                    key: '8-2',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes anti-rides',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums anti-âge',
                        }
                    ],
                },
                {
                    key: '8-3',
                    label: 'Nettoyants visage ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Gels nettoyants',
                        },
                        {
                            key: '11-2',
                            label: 'Laits démaquillants',
                        }
                    ],

                },
                {
                    key: '8-4',
                    label: 'Soins anti-imperfections ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Soins acné',
                        },
                        {
                            key: '11-2',
                            label: 'Correcteurs de teint',
                        }
                    ],
                },
            ],
        },
        {
            key: '9',
            label: 'Bien-être et relaxation',
            children: [
                {
                    key: '9-1',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes hydratantes',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums et huiles',
                        },
                        {
                            key: '11-3',
                            label: 'Masques',
                        }
                    ],

                },
                {
                    key: '9-2',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes anti-rides',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums anti-âge',
                        }
                    ],
                },
                {
                    key: '9-3',
                    label: 'Nettoyants visage ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Gels nettoyants',
                        },
                        {
                            key: '11-2',
                            label: 'Laits démaquillants',
                        }
                    ],

                },
                {
                    key: '9-4',
                    label: 'Soins anti-imperfections ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Soins acné',
                        },
                        {
                            key: '11-2',
                            label: 'Correcteurs de teint',
                        }
                    ],
                },
            ],
        },
        {
            key: '10',
            label: 'Matériel médical et accessoires',
            children: [
                {
                    key: '10-1',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes hydratantes',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums et huiles',
                        },
                        {
                            key: '11-3',
                            label: 'Masques',
                        }
                    ],
                },
                {
                    key: '10-2',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes anti-rides',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums anti-âge',
                        }
                    ],

                },
                {
                    key: '10-3',
                    label: 'Nettoyants visage ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Gels nettoyants',
                        },
                        {
                            key: '11-2',
                            label: 'Laits démaquillants',
                        }
                    ],
                },
                {
                    key: '10-4',
                    label: 'Soins anti-imperfections ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Soins acné',
                        },
                        {
                            key: '11-2',
                            label: 'Correcteurs de teint',
                        }
                    ],
                },
            ],
        },
        {
            key: '11',
            label: 'Produits bio et naturels',
            children: [
                {
                    key: '11-1',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes hydratantes',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums et huiles',
                        },
                        {
                            key: '11-3',
                            label: 'Masques',
                        }
                    ],
                },
                {
                    key: '11-2',
                    label: 'Hydratants et Crèmes',
                    children: [
                        {
                            key: '11-1',
                            label: 'Crèmes anti-rides',
                        },
                        {
                            key: '11-2',
                            label: 'Sérums anti-âge',
                        }
                    ],
                },
                {
                    key: '11-3',
                    label: 'Nettoyants visage ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Gels nettoyants',
                        },
                        {
                            key: '11-2',
                            label: 'Laits démaquillants',
                        }
                    ],
                },
                {
                    key: '11-4',
                    label: 'Soins anti-imperfections ',
                    children: [
                        {
                            key: '11-1',
                            label: 'Soins acné',
                        },
                        {
                            key: '11-2',
                            label: 'Correcteurs de teint',
                        }
                    ],
                },
            ],
        }
    ];
    const handleButtonClick = (buttonId) => {
        // Set the clicked button as active
        setActiveButton(buttonId);
    };
    const handleNavLinkClick = () => {
        setExpanded(false);
    };
    return (
        <div className=''>
            <div className='text-center p-2' style={{ backgroundColor: '#262F63', color: '#fff' }}>
                🚚Livraison offerte dès 49€ d'achat. 💳Paiement 3x sans frais dès 99€ d'achat.
            </div>
            <div>
                <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary" expanded={expanded}>
                    <Container>
                        {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(prevState => !prevState)} />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="w-100 justify-content-between" variant="" style={{ zIndex: 3 }}>
                                {/* Pharmacies de garde Link */}
                                <Nav.Item className="nav-item-hover">
                                    <Nav.Link href="#Pharmacies-de-garde" >Pharmacies de garde
                                        <div className="dropdown-menu-custom">
                                            <div>Trouvez rapidement une pharmacie ouverte près de chez vous.
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>

                                {/* Assurances Link */}
                                <Nav.Item className="nav-item-hover nav-item-hover-custom d-none d-lg-block ">
                                    <Nav.Link href="#Assurances">Assurances
                                        <div className="dropdown-menu-custom dropdown-menu-custom1">
                                            <div>Vérifiez quelles pharmacies acceptent votre couverture santé.
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item-hover  d-block d-lg-none">
                                    <Nav.Link href="#Assurances">Assurances
                                        <div className="dropdown-menu-custom dropdown-menu-custom1">
                                            <div>Vérifiez quelles pharmacies acceptent votre couverture santé.
                                            </div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>

                                {/* Notice et prix des médicaments Link */}
                                <Nav.Item className="nav-item-hover">
                                    <Nav.Link href="#Notice-et-prix-des-medicaments">Notice et prix des médicaments
                                        <div className="dropdown-menu-custom">
                                            <div>Consultez les prix des médicaments ainsi que la notice avant de vous rendre en pharmacie</div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>

                                {/* Recherche de médicaments Link */}
                                <Nav.Item className="nav-item-hover">
                                    <Nav.Link href="#Recherche-de-medicaments">Recherche de médicaments
                                        <div className="dropdown-menu-custom">
                                            <div>Envoyez une requête pour vérifier la disponibilité de vos médicaments dans toutes les pharmacies de Côte d’Ivoire avec une option de réservation</div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>

                                {/* Portefeuille électronique Link */}
                                <Nav.Item className="nav-item-hover">
                                    <Nav.Link href="#Portefeuille-electronique">Portefeuille électronique
                                        <div className="dropdown-menu-custom">
                                            <div>Problème de jeton ? recevez votre petite monnaie directement sur votre téléphone et réutilisez la plus tard dans toutes les autres pharmacies</div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>

                                {/* Vaccination Link */}
                                <Nav.Item className="nav-item-hover nav-item-hover-custom d-none d-lg-block">
                                    <Nav.Link href="#Vaccination">Vaccination
                                        <div className="dropdown-menu-custom dropdown-menu-custom1">
                                            <div>Suivez vos calendriers vaccinaux, configurez des rappels et accédez aux prix des vaccins en un click</div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item-hover  d-block d-lg-none">
                                    <Nav.Link href="#Vaccination">Vaccination
                                        <div className="dropdown-menu-custom dropdown-menu-custom1">
                                            <div>Suivez vos calendriers vaccinaux, configurez des rappels et accédez aux prix des vaccins en un click</div>
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
            <div className='container position-relative d-flex flex-column flex-lg-row justify-content-between align-items-center px-4 px-lg-2 py-2 ' style={{ borderBottom: '1px solid #DEDEDE' }} >
                <div className='d-flex   col-lg-4 col-12  align-items-center mb-2 mb-lg-0' >
                    <div className='d-flex align-items-center justify-content-between  py-2 w-100 ps-0 ps-lg-2'>
                        <div className='col-6'>
                            <Link to={"/"}><img height={'50px'} width={'120px'} src={require('../assets/logo.png')} /></Link>
                        </div>
                        <div className='px-2 col-6'>
                            <button className='btn' style={{
                                fontWeight: 600,
                                fontSize: '14px',
                                display: 'flex'
                            }} onClick={() => setShowCategoryDrop(!showCategoryDrop)}>
                                shop for Category
                                <span style={{ padding: '2px' }}><DownOutlined /></span>
                            </button>
                            {/* <Dropdown> */}
                            {/* <Dropdown.Toggle id="dropdown-basic" className="text-dropdown-toggle">
                                    Shop by Category
                                </Dropdown.Toggle> */}


                            {/* <Dropdown.Menu>
                                    <Dropdown.Item >Action</Dropdown.Item>
                                    <Dropdown.Item >Another action</Dropdown.Item>
                                    <Dropdown.Item >Something else</Dropdown.Item>
                                </Dropdown.Menu> */}
                            {/* </Dropdown> */}
                        </div>
                    </div>
                </div>
                {showCategoryDrop ?
                    <div className='position-absolute col-12 col-md-10 bg-white text-nowrap p-4 rounded-3 border position-right-50px' style={{ zIndex: 4, top: '100%', }}>
                        <div className='d-flex col-12 flex-column flex-md-row'>
                            <div className='d-flex flex-column ps-4 ps-md-0 col-3'>
                                <span className='mt-2 fw-bold'>Soins du visage</span>
                                <span className='mt-2'>Hydratants et Crèmes </span>
                                <span className='mt-2'>Anti-âge et anti-rides </span>
                                <span className='mt-2'>Nettoyants visage </span>
                                <span className='mt-2'>Soins anti-imperfections </span>
                            </div>
                            <div className='d-flex flex-column  ps-4 col-3'>
                                <span className='mt-2 fw-bold'>Soins du corps</span>
                                <span className='mt-2'>Hydratation et nutrition  </span>
                                <span className='mt-2'>Exfoliants et gommages  </span>
                                <span className='mt-2'>Soins raffermissants  </span>
                                <span className='mt-2'>Soins des mains et des pieds </span>
                            </div><div className='d-flex flex-column ps-4 col-3 '>
                                <span className='mt-2 fw-bold'>Soins des cheveux</span>
                                <span className='mt-2'>Shampoings </span>
                                <span className='mt-2'>Après-shampoings et soins  </span>
                                <span className='mt-2'>Huiles capillaires  </span>
                                <span className='mt-2'>Traitements spécifiques  </span>
                            </div><div className='d-flex flex-column ps-4 col-3'>
                                <span className='mt-2 fw-bold'>Matériel médical et accessoires</span>
                                <span className='mt-2'>Matériel orthopédique  </span>
                                <span className='mt-2'>Dispositifs médicaux  </span>
                                <span className='mt-2'>Inhalateurs et nébuliseurs  </span>
                                <span className='mt-2'>Bandages et pansements  </span>
                            </div>
                        </div>
                        <div className='d-flex col-12 mt-4 flex-column flex-md-row'>
                            <div className='d-flex flex-column ps-4 ps-md-0  col-3'>
                                <span className='mt-2 fw-bold'>Hygiène</span>
                                <span className='mt-2 '>Hygiène bucco-dentaire  </span>
                                <span className='mt-2'>Hygiène intime  </span>
                                <span className='mt-2'>Hygiène corporelle  </span>
                                <span className='mt-2'>Hygiène des mains  </span>
                            </div><div className='d-flex flex-column ps-4 col-3'>
                                <span className='mt-2 fw-bold'>Compléments alimentaires</span>
                                <span className='mt-2'>Vitamines et minéraux  </span>
                                <span className='mt-2'>Immunité et énergie  </span>
                                <span className='mt-2'>Beauté et bien-être  </span>
                                <span className='mt-2'>Perte de poids et gestion du poids  </span>
                            </div><div className='d-flex flex-column ps-4 col-3'>
                                <span className='mt-2 fw-bold'>Bébé et maternité</span>
                                <span className='mt-2'>Soins pour bébé  </span>
                                <span className='mt-2'>Hygiène bébé  </span>
                                <span className='mt-2'>Compléments et nutrition infantile  </span>
                                <span className='mt-2'>Soins post-accouchement  </span>
                            </div><div className='d-flex flex-column ps-4 col-3'>
                                <span className='mt-2 fw-bold'>Produits bio et naturels</span>
                                <span className='mt-2'>Soins visage bio </span>
                                <span className='mt-2'>Soins capillaires bio  </span>
                                <span className='mt-2'>Hygiène bio  </span>
                                <span className='mt-2'>Compléments alimentaires naturels  </span>
                            </div>
                        </div>
                        <div className='d-flex col-12 mt-4 flex-column flex-md-row'>
                            <div className='d-flex flex-column ps-4 ps-md-0 col-3 '>
                                <span className='mt-2 fw-bold'>Produits de protection</span>
                                <span className='mt-2'>Masques et désinfectants  </span>
                                <span className='mt-2'>Gants et protections  </span>
                                <span className='mt-2'>Thermomètres et accessoires  </span>
                            </div><div className='d-flex flex-column ps-4 col-3'>
                                <span className='mt-2 fw-bold'>Soins solaires</span>
                                <span className='mt-2'>Crèmes solaires  </span>
                                <span className='mt-2'>Après-soleil  </span>
                                <span className='mt-2'>Autobronzants </span>
                            </div><div className='d-flex flex-column ps-4 col-3'>
                                <span className='mt-2 fw-bold'>Bien-être et relaxation</span>
                                <span className='mt-2 '>Aromathérapie </span>
                                <span className='mt-2'>Produits de relaxation  </span>
                                <span className='mt-2'>Massages </span>
                            </div>
                        </div>

                    </div> : null}
                <div className='d-flex col-sm-12 col-md-4 justify-content-center align-items-center mb-2 mb-lg-0 '  >
                    <InputGroup   >
                        <InputGroup.Text className="no-border" style={{ borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }}>
                            <FaSearch />
                        </InputGroup.Text>
                        <Form.Control style={{ backgroundColor: '#f8f9fa', border: 'none' }} aria-label="Text input with dropdown button" placeholder='Search here......' />
                        <DropdownAnt

                            menu={{
                                items,
                            }}
                        >
                            <button
                                type='button'
                                onClick={(e) => e.preventDefault()}
                                style={{
                                    borderTopRightRadius: '20px',
                                    borderBottomRightRadius: '20px',
                                    backgroundColor: '#f8f9fa',
                                    border: 'none',
                                    color: 'gray',
                                }}>
                                <Space>
                                    All Category
                                    <DownOutlined />
                                </Space>
                            </button>
                        </DropdownAnt>
                    </InputGroup>
                </div>
                <div className='d-flex justify-content-between col-lg-4 col-12 align-items-center ps-2 ps-lg-5' style={{ paddingLeft: '50px', paddingRight: '8px' }}>
                    <div onClick={()=> navigate("auths/auth-login") } className='nav-link'><FaUserCircle /> connexion</div>
                    <div className='nav-link'><FaShoppingCart /> cart</div>
                    <div className='nav-link'><FaBell /> Notification</div>
                </div>
            </div>
            <div className="d-flex flex-wrap justify-content-start justify-content-lg-between align-items-center row px-0 px-lg-4 ps-3  mx-0 mx-lg-5 py-2">
                {/* First Row of Buttons */}
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(1) }} className={activeButton === 1 ? 'detail-btn' : 'btn-outline'}>Bon plan</div>
                </div>

                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(2) }} className={activeButton === 2 ? 'detail-btn' : 'btn-outline'}>Santé</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(3) }} className={activeButton === 3 ? 'detail-btn' : 'btn-outline'}>Hygiène</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(4) }} className={activeButton === 4 ? 'detail-btn' : 'btn-outline'}>Visage</div>
                </div>
                {/* Second Row of Buttons */}
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(5) }} className={activeButton === 5 ? 'detail-btn' : 'btn-outline'}>Corps</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(6) }} className={activeButton === 6 ? 'detail-btn' : 'btn-outline'}>Cheveux</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(7) }} className={activeButton === 7 ? 'detail-btn' : 'btn-outline'}>Nutrition</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(8) }} className={activeButton === 8 ? 'detail-btn' : 'btn-outline'}>Bébé</div>
                </div>
                {/* Third Row of Buttons */}
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(9) }} className={activeButton === 9 ? 'detail-btn' : 'btn-outline'}>Bio</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(10) }} className={activeButton === 10 ? 'detail-btn' : 'btn-outline'}>Solaires</div>
                </div>
                <div className="col-3 col-lg-1 mb-3">
                    <div onClick={() => { handleButtonClick(11) }} className={activeButton === 11 ? 'detail-btn' : 'btn-outline'}>Vétérinaires</div>
                </div>
            </div>
        </div>
    );
}

export default NavBar;