import React from "react";
import { Routes, Route } from "react-router-dom";
import { useAuth } from "../plugins/context/AppContext";
import { ScrollToTop } from "../components";

//Pages
import Blank from "../pages/Blank";
import Home from "../pages/Home";
import Profile from "../pages/admin/Profile";
import ProfileSettings from "../pages/admin/ProfileSettings";
import AuthLogin from "../pages/auths/AuthLogin";
import AuthReset from "../pages/auths/AuthReset";
import NotFound from "../pages/error/NotFound";
import PharmacistDashboard from "../pages/Pharmacist/PharmacistDashboard";
import VerifyOtp from "../pages/auths/VerifyOtp";
import ChangePassword from "../pages/auths/ChangePassword";
import Commune from "../pages/admin/Commune";
import PharmacyPage from "../pages/admin/PharmacyPage";
import UsersDetails from "../pages/admin/Users/UsersDetails";
import DrugsDetails from "../pages/admin/Drugs/DrugsDetails";
import Register from "../pages/auths/Register";
import PharmacitsDetails from "../pages/admin/Pharmacits/PharmacitsDetails";
import PharmacistHome from "../pages/Pharmacist/pages/dashboard/PharmacistHome";
import MyProfile from "../pages/Pharmacist/pages/Profile/MyProfile";
import ProfileTimeline from "../pages/Pharmacist/pages/Profile/ProfileTimeline";
import ProductCatalog from "../pages/Pharmacist/pages/ProductCatalog/ProductCatalog";
import PharmacistsDrugs from "../pages/Pharmacist/pages/Drugs/PharmacistsDrugs";
import OrderManagersDetails from "../pages/Pharmacist/pages/OrderManagers/OrderManagersDetails";
import DrugRequest from "../pages/OrderManager/DrugRequest/DrugRequest";
import DrugHistory from "../pages/OrderManager/DrugRequest/DrugHistory";
import ViewPharmacies from "../pages/admin/Pharmacies/ViewPharmacies";
import AssuranceList from "../pages/admin/Assurance/AssuranceList";
import DrugSheet from "../pages/admin/Drugs/DrugSheet";
import PaymentMethodList from "../pages/admin/PaymentMethod/PaymentMethodList";
import NotificationMain from "../pages/Pharmacist/pages/Profile/NotificationMain";
import CashierDetails from "../pages/Pharmacist/pages/Cashier/CashierDetails";
import CashierDashboard from "../pages/Pharmacist/pages/Cashier/CashierDashboard";
import ResetPassword from "../pages/Password/ResetPassword";
import RechargeWallet from "../pages/Pharmacist/pages/dashboard/RechargeWallet";
import TransferMoneyToPatient from "../pages/Pharmacist/pages/Cashier/TransferMoneyToPatient";
import TransactionLimits from "../pages/admin/Transactions/TransactionLimits";
import TransferMoney from "../pages/admin/Transactions/TransferMoney";
import ModeratorList from "../pages/admin/Moderator/ModeratorList";
import ModeratorDashboard from "../pages/admin/Moderator/ModeratorDashboard";
import TransactionHistory from "../pages/admin/Transactions/TransactionHistory";


function Router() {
  const { user } = useAuth();

  return (
    <ScrollToTop>
      <Routes>
        {user?.token ? (
          user?.role === "ADMIN" ? (
            <>
              <Route path="blank" element={<Blank />} />
              <Route path="/" element={<Home />} />
              <Route path="/dashboard" element={<Home />} />
              <Route path="admin">
                <Route path="profile" element={<Profile />} />
                <Route path="profile-settings" element={<ProfileSettings />} />
              </Route>
              <Route path="/commune" element={<Commune />} />
              <Route path="/pharmacy/:communeId" element={<PharmacyPage />} />
              <Route path="/users-details" element={<UsersDetails />} />
              <Route path="/drugs" element={<DrugsDetails />} />
              <Route path="/pharmacits-details" element={<PharmacitsDetails />} />
              <Route path="/moderator-details" element={<ModeratorList />} />
              <Route path="/assurances" element={<AssuranceList />} />
              <Route path="/payment-methods" element={<PaymentMethodList />} />
              <Route path="/notifications" element={<NotificationMain />} />
              <Route path="/drug-sheet" element={<DrugSheet />} />
              <Route path="not-found" element={<NotFound />} />
              <Route path="/pharmacies" element={<ViewPharmacies />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/transaction-limit" element={<TransactionLimits />} />
              <Route path="/transfer-money" element={<TransferMoney />} />
              <Route path="/transaction-history" element={<TransactionHistory />} />
              <Route path="/profile" element={<MyProfile />} />
              <Route path="*" element={<NotFound />} />
            </>
          ) : user?.role === "MODERATOR" ? (
            <>
              <Route path="/" element={<ModeratorDashboard />} />
              <Route path="/dashboard" element={<ModeratorDashboard />} />
              <Route path="/profile" element={<MyProfile />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/commune" element={<Commune />} />
              <Route path="/pharmacy/:communeId" element={<PharmacyPage />} />
              <Route path="/pharmacies" element={<ViewPharmacies />} />
              <Route path="/drugs" element={<DrugsDetails />} />
            </>
          ) : user?.role === "PHARMACIST" ? (
            <>
              <Route path="/dashboard" element={<PharmacistHome />} />
              <Route path="/pharmacist-dashboard" element={<PharmacistHome />} />
              <Route path="/profile" element={<MyProfile />} />
              <Route path="/timeline" element={<ProfileTimeline />} />
              <Route path="/notifications" element={<NotificationMain />} />
              <Route path="/product-catalog" element={<ProductCatalog />} />
              <Route path="/drugs-details" element={<PharmacistsDrugs />} />
              <Route path="/order-manager" element={<OrderManagersDetails />} />
              <Route path="/cashier" element={<CashierDetails />} />
              <Route path="/drug-request" element={<DrugRequest />} />
              <Route path="/drug-history" element={<DrugHistory />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/recharge-wallet" element={<RechargeWallet />} />
            </>
          ) : user?.role === "ORDER_MANAGER" ? (
            <>
              <Route path="/dashboard" element={<PharmacistHome />} />
              <Route path="/drug-request" element={<DrugRequest />} />
              <Route path="/drug-history" element={<DrugHistory />} />
              <Route path="/profile" element={<MyProfile />} />
              <Route path="/timeline" element={<ProfileTimeline />} />
              <Route path="/notifications" element={<NotificationMain />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </>
          ) : user?.role === "CASHIER" ? (
            <>
              <Route path="/dashboard" element={<CashierDashboard />} />
              <Route path="/profile" element={<MyProfile />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/transfer-money-to-patient" element={<TransferMoneyToPatient />} />
              <Route path="/transaction-history" element={<TransactionHistory />} />
            </>
          ) :
            (
              <Route path="not-found" element={<NotFound />} />
            )
        ) : (
          <>
            <Route path="/" element={<PharmacistDashboard />} />
            <Route path="auths">
              <Route path="auth-login" element={<AuthLogin />} />
              <Route path="register" element={<Register />} />
              <Route path="auth-reset" element={<AuthReset />} />
              <Route path="verify-otp" element={<VerifyOtp />} />
              <Route path="change-password" element={<ChangePassword />} />
            </Route>
          </>
        )}
      </Routes>
    </ScrollToTop>
  );
}

export default Router;
