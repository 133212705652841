import React, { useEffect, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import Layout from "../../../../layout/default";
import useValidator from '../../../../plugins/useValidator';
import pharmacist from '../../../../Model/pharmacist';
import CreateCashier from './CreateCashier';
import Swal from 'sweetalert2';
import { Table } from 'antd';


const defaultCahierData = {
    firstName: '',
    name: '',
    email: '',
    mobileNumber: '',
    countryCode: {
        code: 'CI',
        dial_code: '+225',
        flag: '🇨🇮',
    },
    sex: '',
    password: '',
};



const CashierDetails = () => {
    const [cashierData, setCashierData] = useState(defaultCahierData);
    const [pharmacyData, setPharmacyData] = useState();
    const [isCreateCashier, setIsCreateCashier] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validator, showMessage] = useValidator();


    const error = {
        firstName: validator.message("First Name", cashierData.firstName, "required"),
        name: validator.message("Full Name", cashierData?.name, "required"),
        email: validator.message("Email", cashierData?.email, "required|email"),
        mobileNumber: validator.message("Contact", cashierData?.mobileNumber, "required|phone|min:10|max:10"),
        password: validator.message("Password", cashierData?.password, "required"),
        sex: validator.message("sex", cashierData?.sex, "required"),
    };


    useEffect(() => {
        getMyPharmacyData();
    }, []);

    const getMyPharmacyData = () => {
        pharmacist.getMyPharmacyData()
            .then((res) => {
                setPharmacyData(res);
            }).catch((error) => {
                console.log("Error in Get Pharmacy Data");
            })
    };

    const handelChange = (name, e) => {
        if (name === "sex") {
            setCashierData((prev) => ({
                ...prev,
                [name]: e
            }))
        }
        else {
            setCashierData((prev) => ({
                ...prev,
                [name]: e.target.value
            }))
        }
    };

    const handleCreateCashier = () => {
        if (validator.allValid()) {
            setLoading(true);
            pharmacist.createCashier(pharmacyData._id, cashierData)
                .then((res) => {
                    Swal.fire({
                        title: 'Cashier created successfully',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    getMyPharmacyData();
                    setIsCreateCashier(false);
                    setCashierData(defaultCahierData);
                }).catch((error) => {
                    Swal.fire({
                        title: error.error,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                }).finally(() => setLoading(false))
        } else {
            showMessage(true);
        }
    };

    const columns = [
        {
            title: "SrNo",
            key: "number",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Cashier Name",
            dataIndex: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Contact Number",
            dataIndex: "mobileNumber",
        },
    ];


    return (
        !isCreateCashier ? (
            <Layout title="Cashier">
                <Card>
                    <Row className='p-3'>
                        <div className='d-flex justify-content-between'>
                            <div><h2 className="">Cashier</h2></div>
                            <button onClick={() => setIsCreateCashier(true)} className="btn btn-success" >
                                <i className="bi bi-plus-circle"></i> Add Cashier
                            </button>
                        </div>
                        <div className='mt-3'>
                            <Table
                                columns={columns}
                                dataSource={pharmacyData?.cashier ? pharmacyData?.cashier : []}
                            />
                        </div>
                    </Row>
                </Card>
            </Layout>
        ) : (
            <CreateCashier
                cashierData={cashierData}
                handelChange={handelChange}
                error={error}
                setIsCreateCashier={setIsCreateCashier}
                showMessage={showMessage}
                handleCreateCashier={handleCreateCashier}
                loading={loading}
            />
        )
    )
}

export default CashierDetails;
