import classNames from "classnames";
import { Dropdown } from "react-bootstrap";

import {
  Icon,
  MediaGroup,
  MediaText,
  Media,
  LinkList,
  LinkListItem,
  CustomDropdownToggle,
  CustomDropdownMenu,
} from "../../../components";
import logo from "../../../assets/logo.png";

import ToggleSidebar from "../Toggle/Sidebar";

import { useLayout, useLayoutUpdate } from "./../LayoutProvider";
import Swal from "sweetalert2";
import { useAuth } from "../../../plugins/context/AppContext";

function QuickNav({ className, ...props }) {
  const compClass = classNames({
    "nk-quick-nav": true,
    [className]: className,
  });
  return <ul className={compClass}>{props.children}</ul>;
}

function QuickNavItem({ className, ...props }) {
  const compClass = classNames({
    "d-inline-flex": true,
    [className]: className,
  });
  return <li className={compClass}>{props.children}</li>;
}

function Header() {
  const { setUser, user } = useAuth();
  const layout = useLayout();
  const layoutUpdate = useLayoutUpdate();

  const compClass = classNames({
    "nk-header nk-header-fixed": true,
    [`is-${layout.headerVariant}`]: layout.headerVariant,
  });

  const navClass = classNames({
    "nk-header-menu nk-navbar": true,
    "navbar-active": layout.headerActive,
    // eslint-disable-next-line
    "navbar-mobile":
      layout.headerTransition ||
      eval(`layout.breaks.${layout.headerCollapse}`) > window.innerWidth,
  });


  const Logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setUser(JSON.parse(localStorage.getItem("user")));
    Swal.fire({
      title: "Logout  Successfully",
      icon: "success",
      showConfirmButton: false,
      timer: 1000,
    });
  };

  return (
    <>
      <div className={compClass}>
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-header-logo">
              <ToggleSidebar variant="zoom" icon="menu" />
              {/* <ToggleNavbar className="me-2" /> */}
              <img src={logo} alt="logo" width={"100px"} className="mt-3 " />
            </div>
            {layout.headerActive && (
              <div
                className="navbar-overlay"
                onClick={layoutUpdate.headerMobile}
              ></div>
            )}
            <nav className={navClass}>{/* <Menu /> */}</nav>
            <div className="nk-header-tools">
              <QuickNav>
                <Dropdown as={QuickNavItem}>
                  <Dropdown.Toggle bsPrefix as={CustomDropdownToggle}>
                    <div className="d-inline-flex d-sm-none">
                      <Media shape="circle" size="md">
                        <img
                          alt="logo"
                          style={{
                            height: "40px",
                            width: "40px",
                            objectFit: "contain",
                            border: "1px solid",
                          }}
                          src={logo}
                          staticImage
                          thumbnail
                        />
                      </Media>
                    </div>
                    <div className="d-none d-sm-flex">
                      <Media shape="circle">
                        <img
                          alt="logo"
                          style={{
                            height: "40px",
                            width: "40px",
                            objectFit: "contain",
                            border: "1px solid",
                          }}
                          src={logo}
                          staticImage
                          thumbnail
                        />
                      </Media>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu-md"
                    as={CustomDropdownMenu}
                  >
                    {user.role === "ADMIN" && (
                      <>
                        <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                          <MediaGroup>
                            <Media size="lg" shape="circle">
                              <img
                                alt="logo"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  objectFit: "contain",
                                  border: "1px solid",
                                }}
                                src={logo}
                                staticImage
                                thumbnail
                              />
                            </Media>
                            <MediaText>
                              <div className="lead-text">Admin</div>
                              <span className="sub-text">
                                Owner &amp; Founder
                              </span>
                            </MediaText>
                          </MediaGroup>
                        </div>
                        <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                          <LinkList>
                            <LinkListItem to="/profile">
                              <Icon name="user"></Icon>
                              <span>My Profile</span>
                            </LinkListItem>
                          </LinkList>
                          <LinkList>
                            <LinkListItem to="/reset-password">
                              <Icon name="repeat"></Icon>
                              <span>Reset Password</span>
                            </LinkListItem>
                          </LinkList>
                        </div>
                      </>
                    )}

                    {user?.role === "MODERATOR" && (
                      <>
                        <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                          <MediaGroup>
                            <Media size="lg" shape="circle">
                              <img
                                alt="logo"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  objectFit: "contain",
                                  border: "1px solid",
                                }}
                                src={logo}
                                staticImage
                                thumbnail
                              />
                            </Media>
                            <MediaText>
                              <div className="lead-text">Moderator</div>
                            </MediaText>
                          </MediaGroup>
                        </div>
                        <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                          <LinkList>
                            <LinkListItem to="/profile">
                              <Icon name="user"></Icon>
                              <span>My Profile</span>
                            </LinkListItem>
                          </LinkList>
                          <LinkList>
                            <LinkListItem to="/reset-password">
                              <Icon name="repeat"></Icon>
                              <span>Reset Password</span>
                            </LinkListItem>
                          </LinkList>
                        </div>
                      </>
                    )}
                    {(user.role === "PHARMACIST" || user.role === "ORDER_MANAGER") && (
                      <>
                        <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                          <MediaGroup>
                            <Media size="lg" shape="circle">
                              <img
                                alt="logo"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  objectFit: "contain",
                                  border: "1px solid",
                                }}
                                src={logo}
                                staticImage
                                thumbnail
                              />
                            </Media>
                            <MediaText>
                              <div className="lead-text">{user.name}</div>
                              <span className="sub-text">{user.role}</span>
                            </MediaText>
                          </MediaGroup>
                        </div>
                        <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                          <LinkList>
                            <LinkListItem to="/profile">
                              <Icon name="user"></Icon>
                              <span>My Profile</span>
                            </LinkListItem>
                          </LinkList>
                          <LinkList>
                            <LinkListItem to="/timeline">
                              <Icon name="activity"></Icon>
                              <span>Timeline</span>
                            </LinkListItem>
                          </LinkList>
                          <LinkList>
                            <LinkListItem to="/notifications">
                              <Icon name="bell"></Icon>
                              <span>Notification</span>
                            </LinkListItem>
                          </LinkList>
                          <LinkList>
                            <LinkListItem to="/reset-password">
                              <Icon name="repeat"></Icon>
                              <span>Reset Password</span>
                            </LinkListItem>
                          </LinkList>
                        </div>
                      </>
                    )}
                    {user.role === "CASHIER" && (
                      <>
                        <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                          <MediaGroup>
                            <Media size="lg" shape="circle">
                              <img
                                alt="logo"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  objectFit: "contain",
                                  border: "1px solid",
                                }}
                                src={logo}
                                staticImage
                                thumbnail
                              />
                            </Media>
                            <MediaText>
                              <div className="lead-text">{user.name}</div>
                              <span className="sub-text">{user.role}</span>
                            </MediaText>
                          </MediaGroup>
                        </div>
                        <div className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                          <LinkList>
                            <LinkListItem to="/profile">
                              <Icon name="user"></Icon>
                              <span>My Profile</span>
                            </LinkListItem>
                          </LinkList>
                          <LinkList>
                            <LinkListItem to="/reset-password">
                              <Icon name="repeat"></Icon>
                              <span>Reset Password</span>
                            </LinkListItem>
                          </LinkList>
                        </div>
                      </>
                    )}
                    <div
                      onClick={() => Logout()}
                      className="dropdown-content dropdown-content-x-lg py-3">
                      <LinkList>
                        <LinkListItem to="/auths/auth-login">
                          <Icon name="signout"></Icon>
                          <span>Log Out</span>
                        </LinkListItem>
                      </LinkList>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </QuickNav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
