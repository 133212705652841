import axios from "../plugins/axios";

const commune = {
    getAllCommune() {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token); 
                axios
                    .get('/admin/get-all-communes')
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    deleteCommune(Id) {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token); 
                axios
                    .delete(`/admin/delete-commune/${Id}`)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    updateCommune(Id,data) {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token); 
                axios
                    .put(`/admin/update-commune/${Id}`,data)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
};

export default commune;