
import axios from "../plugins/axios";
const users = {
    getAllUsers() {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token); 
                axios
                    .get(`/admin/get-all-users`)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    registerUser(Data) {
        return new Promise((resolve, reject) => {
                axios
                    .post(`/auth/register-user`,Data)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
        });
    },
    deleteUser(Id) {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token); 
                axios
                    .delete(`/admin/delete-user/${Id}`)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    updateUser(Id,data) {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token); 
                axios
                    .put(`/admin/update-user/${Id}`,data)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    getAllData() {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token); 
                axios
                    .get(`/admin/get-month-data`)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    getAuthUser(){
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token); 
                axios
                    .get(`/auth/get-auth-user`)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    getTimelineData(){
            return new Promise((resolve, reject) => {
                const token = JSON.parse(localStorage.getItem('token'));
                if (token) {
                    axios.setToken(token); 
                    axios
                        .get(`/pharmacist/get-updated-records`)
                        .then(({ data }) => {
                            resolve(data);
                        })
                        .catch(({ response }) => {
                            reject(response?.data);
                        });
                } else {
                    reject({ message: 'Token not found' });
                }
            });

    }
};

export default users;