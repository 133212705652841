import React from 'react';
import { Row, Col, Card } from "react-bootstrap";
import Layout from "../../../layout/default";


const ModeratorDashboard = () => {

    return (
        <Layout title="Analytics">
            <Row className="g-gs">
                <h2>Moderator Dashboard Working in progress</h2>
            </Row>
        </Layout>
    )
};

export default ModeratorDashboard;
