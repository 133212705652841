import React from "react";
import { Card, Row, Col, Button, Image } from "react-bootstrap";
import profile from "../../../assets/images/profile.png";
import Layout from "../../../layout/default";


const UserProfile = ({ user, setViewUser, setUser, defaultUser }) => {

    return (
        <Layout title="User Profile">
            <Card className="p-4">
                <Row className="mb-4">
                    <Col className="d-flex justify-content-between align-items-center">
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                setViewUser(false);
                                setUser(defaultUser);
                            }}
                            className="d-flex align-items-center"
                        >
                            <i className="fa fa-arrow-left me-2" aria-hidden="true"></i> Back
                        </Button>
                        <h4 className="m-0">User Profile</h4>
                    </Col>
                </Row>

                <Row className="align-items-center">
                    <Col md={3} className="text-center">
                        <Image
                            src={user?.image ? user?.image : profile}
                            alt="userImage"
                            roundedCircle
                            className="border border-secondary shadow-sm"
                            style={{
                                height: "120px",
                                width: "120px",
                                objectFit: "cover",
                            }}
                        />
                        <h5 className="mt-3 text-primary">{user?.name || "N/A"}</h5>
                    </Col>
                    <Col md={9}>
                        <Row>
                            <Col md={6} className="mb-3">
                                <strong>First Name:</strong>
                                <div className="text-muted">{user?.firstName || "N/A"}</div>
                            </Col>
                            <Col md={6} className="mb-3">
                                <strong>Email:</strong>
                                <div className="text-muted">{user?.email || "N/A"}</div>
                            </Col>
                            <Col md={6} className="mb-3">
                                <strong>Contact Number:</strong>
                                <div className="text-muted">
                                    +225{user?.mobileNumber || "N/A"}
                                </div>
                            </Col>
                            <Col md={6} className="mb-3">
                                <strong>Address:</strong>
                                <div className="text-muted">
                                    {user?.customer_address || "N/A"}
                                </div>
                            </Col>
                            <Col md={6} className="mb-3">
                                <strong>Country:</strong>
                                <div className="text-muted">{user?.customer_country || "N/A"}</div>
                            </Col>
                            <Col md={6} className="mb-3">
                                <strong>City:</strong>
                                <div className="text-muted">{user?.customer_city || "N/A"}</div>
                            </Col>
                            <Col md={6} className="mb-3">
                                <strong>State:</strong>
                                <div className="text-muted">{user?.customer_state || "N/A"}</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Layout>
    );
};

export default UserProfile;
