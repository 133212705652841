
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import useValidator from '../../plugins/useValidator';
import Swal from 'sweetalert2';
import users from '../../Model/users';
import flag from "../../assets/flag.png"
import logo from "../../assets/logo.png"
import Layout from "../../layout/fullpage";
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Icon } from '@iconify/react/dist/iconify.js';
const defaultUser = {
    firstName: '',
    name: '',
    email: '',
    mobileNumber: '',
    countryCode: {
        code: 'CI',
        dial_code: '+225',
        flag: '🇨🇮',
    },
    sex: '',
    password: '',
    role: 'PHARMACIST',
};
const Register = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(defaultUser);
    const [validator, showMessage] = useValidator()
    const [eyeVisible, setEyeVisible] = useState(false);

    const handelChnage = (name, value) => {
        setUser((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleVisibiltyPassword = () => setEyeVisible((prev) => !prev);
    const error = {
        firstName: validator.message("First Name", user.firstName, "required"),
        name: validator.message("Full Name", user?.name, "required"),
        email: validator.message("Email", user?.email, "required|email"),
        mobileNumber: validator.message("Contact", user?.mobileNumber, "required|phone|min:10"),
        password: validator.message("Password", user?.password, "required"),
        sex: validator.message("sex", user?.sex, "required"),
    }

    const registerUser = (e) => {
        if (validator.allValid()) {
            e?.preventDefault();
            users
                .registerUser(user)
                .then((response) => {
                    Swal.fire({
                        title: 'Registration successfully done',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    navigate("auths/auth-login")
                    
                })
                .catch((error) => {
                    Swal.fire({
                        title: error.error,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        } else {
            showMessage(true)
        }
    };

    return (
        <>
            <Layout title="Login" centered>
                <div className="container p-2 p-sm-4">
                    <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                        <Row className="g-0 flex-lg-row-reverse flex-column-reverse  ">
                            <Col lg="5" className="shadow rounded ">
                                <Card.Body className="h-100 d-flex flex-column justify-content-center">
                                    <div className="nk-block-head text-center">
                                        <div className="nk-block-head-content">
                                            <h3 className="nk-block-title mb-1">Register Account</h3>
                                            <p className="small">
                                                Please sign-up your account and start the adventure.
                                            </p>
                                        </div>
                                    </div>
                                    <Form method="post">
                                        <Row className="gy-3">
                                            <Col className="col-12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="firstName">First Name</Form.Label><span className='text-danger'>*</span>
                                                    <div className="form-control-wrap">
                                                        <Form.Control
                                                            placeholder="Enter your first name"
                                                            name='firstName'
                                                            value={user?.firstName}
                                                            onChange={(e) => handelChnage("firstName", e.target.value)}
                                                        />
                                                        {error?.firstName && (
                                                            <span className="error" style={{ color: "red" }}>
                                                                {" "}
                                                                {error?.firstName}
                                                            </span>
                                                        )}
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="name">First Name</Form.Label><span className='text-danger'>*</span>
                                                    <div className="form-control-wrap">
                                                        <Form.Control
                                                            placeholder="Enter your full name"
                                                            name='name'
                                                            value={user?.name}
                                                            onChange={(e) => handelChnage("name", e.target.value)}
                                                        />
                                                        {error?.name && (
                                                            <span className="error" style={{ color: "red" }}>
                                                                {" "}
                                                                {error?.name}
                                                            </span>
                                                        )}
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="email">Email</Form.Label><span className='text-danger'>*</span>
                                                    <div className="form-control-wrap">
                                                        <Form.Control
                                                            type="email"
                                                            id="email"
                                                            placeholder="Enter email"
                                                            name='email'
                                                            value={user?.email}
                                                            onChange={(e) => handelChnage("email", e.target.value)}
                                                            required
                                                        />
                                                        {error?.email && (
                                                            <span className="error" style={{ color: "red" }}>
                                                                {" "}
                                                                {error?.email}
                                                            </span>
                                                        )}
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="email">Image</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Control
                                                             type="file"
                                                             name="image"
                                                             accept="image/*"
                                                             onChange={(e) => handelChnage("image", e.target.value)}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="password">Password</Form.Label><span className='text-danger'>*</span>
                                                    <div className="form-control-wrap">
                                                        {!eyeVisible ? (
                                                            <div
                                                                className="position-absolute"
                                                                style={{
                                                                    top: "30%",
                                                                    right: "2%",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={handleVisibiltyPassword}
                                                            >
                                                                <Icon name="eye-off" />
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="position-absolute"
                                                                style={{
                                                                    top: "30%",
                                                                    right: "2%",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={handleVisibiltyPassword}
                                                            >
                                                                <Icon name="eye" />
                                                            </div>
                                                        )}
                                                        <Form.Control
                                                            type={eyeVisible ? "text" : "password"}
                                                            placeholder="Enter your password"
                                                            value={user?.password}
                                                            onChange={(e) => handelChnage("password", e.target.value)}
                                                            name="password"
                                                            required
                                                        />
                                                        {error?.password && (
                                                            <span className="error" style={{ color: "red" }}>
                                                                {" "}
                                                                {error?.password}
                                                            </span>
                                                        )}
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="contactnumber">Contact number</Form.Label><span className='text-danger'>*</span>
                                                    <div className="form-control-wrap">
                                                        <div className='d-flex'>
                                                            <div className='rounded-2 align-items-center d-flex px-2 py-1 mx-1' style={{ background: '#f2f0f0' }}>
                                                                <img style={{ height: "15px", width: "15px" }} src={flag} alt='' />
                                                                <span style={{ fontSize: "12px" }} className='fw-bold mx-2'>+225</span>
                                                            </div>
                                                            <Form.Control
                                                                type=""
                                                                placeholder="Enter your Contact Number"
                                                                name='mobileNumber'
                                                                value={user?.mobileNumber}
                                                                onChange={(e) => handelChnage("mobileNumber", e.target.value)}
                                                                required
                                                            />
                                                            </div>
                                                            {error?.mobileNumber && (
                                                                <span className="error" style={{ color: "red" }}>
                                                                    {" "}
                                                                    {error?.mobileNumber}
                                                                </span>
                                                            )}
                                                        </div>
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="contactnumber">Contact number</Form.Label><span className='text-danger'>*</span>
                                                    <div className="form-control-wrap">
                                                     
                                                            <Form.Select 
                                                            value={user?.sex}
                                                            onChange={(e) => handelChnage("sex", e.target.value)}
                                                            >
                                                            <option>Open this select menu</option>
                                                                <option value="MALE">MALE</option>
                                                                <option value="FEMALE">FEMALE</option>
                                                                <option value="OTHER">OTHER</option>
                                                                </Form.Select>
                                                           
                                                            {error?.sex && (
                                                                <span className="error" style={{ color: "red" }}>
                                                                    {" "}
                                                                    {error?.sex}
                                                                </span>
                                                            )}
                                                        </div>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                            
                                            </Col>
                                            <Col className="col-12">
                                                <div className="d-grid">
                                                    <Button  onClick={(e)=> registerUser(e)} >
                                                        Register account
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Col>
                            <Col lg="7">
                                <Card.Body className="is-theme has-mask has-mask-1 h-100 d-flex flex-column align-items-center   ">
                                    <div className="brand-logo">
                                        {/* <Logo /> */}
                                        <img
                                            src={logo}
                                            alt="logo"
                                            width={"300px"}
                                            className="mt-3 "
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-11">
                                            <div className="mt-4">
                                                <div className="h1 text-center text-black title mb-3">
                                                    Welcome back to  Pharmaconsults
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Layout>
          
        </>
    );
};

export default Register