import React, { useEffect, useState } from 'react'
import Layout from "../../../layout/default"
import { Card, Modal, Row } from 'react-bootstrap'
import users from '../../../Model/users'
import Swal from 'sweetalert2'
import { Icon } from '@iconify/react/dist/iconify.js'
import { Input, Select, Table } from 'antd'
import CreatePharmacits from './CreatePharmacits'
import useValidator from '../../../plugins/useValidator'
import pharmacy from '../../../Model/pharmacy'
import pharmacist from '../../../Model/pharmacist'
import UserProfile from '../Users/UserProfile'

const defaultUser = {
    firstName: '',
    name: '',
    email: '',
    mobileNumber: '',
    countryCode: {
        code: 'CI',
        dial_code: '+225',
        flag: '🇨🇮',
    },
    sex: '',
    password: '',
    fcmToken: ''
};
const PharmacitsDetails = () => {
    const [usersData, setUsersData] = useState()
    const [deleteId, setdeleteId] = useState()
    const [isDelete, setIsDelete] = useState()
    const [modal, setModal] = useState(false)
    const [searchData, setSearchData] = useState()
    const [user, setUser] = useState(defaultUser)
    const [isCreatePharmacist, setIsCreatePharmacist] = useState(false)
    const [validator, showMessage] = useValidator()
    const [isPharmacySelected, setIsPharmacySelected] = useState()
    const [pharmacyId, setPharmacyId] = useState()
    const [pharmacyData, setPharmacyData] = useState()
    const [ownerName, setOwnerName] = useState()
    const [viewUser, setViewUser] = useState()
    useEffect(() => {
        getAllUsers();
        getAllPharmacy();
    }, [])

    const getAllPharmacy = () => {
        pharmacy
            .getAllPharmacy()
            .then((response) => {
                setPharmacyData(response)
            })
            .catch((error) => {
                Swal.fire({
                    title: error.message,
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500,
                });
            })
    }


    const [eyeVisible, setEyeVisible] = useState(false);

    const handelChnage = (name, e) => {
        if (name === "sex") {
            setUser((prev) => ({
                ...prev,
                [name]: e
            }))
        }
        else if (name === "pharmacyId") {
            setPharmacyId(e)
            const matchData = pharmacyData?.find((i) => i._id === e)
            setOwnerName(matchData?.ownerName)
            setIsPharmacySelected(!!e);
        }
        else {
            setUser((prev) => ({
                ...prev,
                [name]: e.target.value
            }))
        }
    };

    const handleVisibiltyPassword = () => setEyeVisible((prev) => !prev);
    const error = {
        firstName: validator.message("First Name", user.firstName, "required"),
        name: validator.message("Full Name", user?.name, "required"),
        email: validator.message("Email", user?.email, "required|email"),
        mobileNumber: validator.message("Contact", user?.mobileNumber, "required|phone|regex:^\\d{10}$"),
        password: validator.message("Password", user?.password, "required|min:6"),
        sex: validator.message("sex", user?.sex, "required"),
    }

    const registerUser = (e) => {
        if (validator.allValid()) {
            e?.preventDefault();
            pharmacist
                .createPharmacist(user, pharmacyId)
                .then((response) => {
                    Swal.fire({
                        title: 'Pharmacist create successfully',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    getAllUsers()
                    getAllPharmacy()
                    setIsPharmacySelected(false)
                    setPharmacyId()
                    setIsCreatePharmacist(false)
                })
                .catch((error) => {
                    Swal.fire({
                        title: error.error,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                });
        } else {
            showMessage(true)
        }
    };

    const getAllUsers = () => {
        users
            .getAllUsers()
            .then((resposne) => {
                setUsersData(resposne.users.filter(user => user.role === "PHARMACIST"))

            })
            .catch((error) => {
                Swal.fire({
                    title: error.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
    }

    const columns = [
        {
            title: "SrNo",
            key: "number",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Pharmacist Name",
            dataIndex: "name",
            render: (text, record) => (
                <div onClick={() => { setUser(record); setViewUser(true) }} style={{ color: "blue", cursor: "pointer" }}>{text}</div>

            )
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Contact Number",
            render: (text) => (
                <div>
                   <span>+225{text.mobileNumber}</span>
                </div>

            )
        },
        {
            title: "Actions",
            render: (text, record) => (
                <div class="dropdown">
                    <span className="dropdown-toggle" data-bs-toggle="dropdown">
                    </span>
                    <ul class="dropdown-menu" style={{ height: 'fit-content' }}>
                        <Icon name="more-v"></Icon>
                        {/* <li>
                            <p
                                class="dropdown-item d-flex align-items-center p-1 text-warning "
                                onClick={() => { setUser(record); setIsCreatePharmacist(true) }}
                            >
                                <Icon icon="wpf:edit" className="me-1 " />
                                <span className="col-5 ">Update</span>
                            </p>
                        </li> */}
                        <li>
                            <p
                                class="dropdown-item d-flex align-items-center p-1 text-danger "
                                onClick={() => { setIsDelete(true); setdeleteId(record._id) }}
                            >
                                <Icon icon="mingcute:delete-2-line" className="me-1 " />
                                <span className="col-5 ">Delete</span>
                            </p>
                        </li>
                    </ul>
                </div>
            ),
        },
    ]

    const deleteConfirm = () => {
        users
            .deleteUser(deleteId)
            .then((resposne) => {
                setIsDelete(false)
                setdeleteId()
                Swal.fire({
                    title: "Pharmacits delete successfuly",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                })
                setIsPharmacySelected(false)
                getAllPharmacy()
                setIsCreatePharmacist(false)
                getAllUsers();
            })
            .catch((error) => {
                Swal.fire({
                    title: error.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                })

            })
    }

    const onSearching = (e) => {
        const { value } = e?.target;
        setSearchData(() => {
            return usersData?.filter((item) => {
                return (
                    item?.name?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.email?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.mobileNumber?.includes(value)
                );
            });
        });
    };


    const updateUser = () => {
        const data = {
            firstName: user?.firstName,
            name: user?.name,
            email: user?.email,
            mobileNumber: user?.mobileNumber,
            image: user?.image
        }
        users
            .updateUser(user?._id, data)
            .then((response) => {
                Swal.fire({
                    title: "Pharmacits update successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                })
                getAllUsers();
                setUser()
                setModal(false)
                setIsCreatePharmacist(false)
            })
            .catch((error) => {
                Swal.fire({
                    title: error.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                })
                setUser()
                setIsCreatePharmacist(false)
                setModal(false)

            })
    }
    return (
        <>
            <Modal centered show={isDelete} onHide={() => setIsDelete(false)}>
                <Modal.Body>
                    <p
                        className="text-center"
                        style={{ fontSize: "23px", fontWeight: "bold" }}>
                        Confirm delete
                    </p>
                    <p className="text-center">
                        <p className='mt-2'>Are you sure to delete this Pharmacits</p>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="col-12 d-flex gap-2  justify-content-center"
                        style={{ marginTop: "20px" }}
                    >
                        <button
                            className="col-4 cancel-btn"
                            onClick={() => { setIsDelete(false); setdeleteId() }}>
                            Cancel
                        </button>
                        <button
                            onClick={() => deleteConfirm()}
                            className="col-4 delete-btn"
                            style={{ marginRight: "15px" }}>
                            Delete
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal centered show={modal} onHide={() => setModal(false)}>
                <Modal.Body>
                    <p
                        className="text-center"
                        style={{ fontSize: "23px", fontWeight: "bold" }}>
                        Update User
                    </p>
                    <div className='container d-flex flex-wrap '>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="name" className="form-label">
                                <strong>Pharmacits First Name</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter first name of the user"
                                name='firstName'
                                value={user?.firstName}
                                onChange={(e) => handelChnage("firstName", e)}
                            />
                            {error?.firstName && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.firstName}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="full name" className="form-label">
                                <strong>Pharmacits Full Name</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter Full name of the User"
                                name='name'
                                value={user?.name}
                                onChange={(e) => handelChnage("name", e)}
                            />
                            {error?.name && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.name}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="email" className="form-label">
                                <strong>Pharmacits Email </strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type="email"
                                id="email"
                                placeholder="Enter email"
                                name='email'
                                value={user?.email}
                                onChange={(e) => handelChnage("email", e)}
                                required
                            />
                            {error?.email && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.email}
                                </span>
                            )}
                        </div>

                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="Password" className="form-label">
                                <strong>Password</strong><span className='text-danger'>*</span>
                            </label>
                            {!eyeVisible ? (
                                <div
                                    className="position-absolute"
                                    style={{
                                        top: "30%",
                                        right: "2%",
                                        cursor: "pointer",
                                    }}
                                    onClick={handleVisibiltyPassword}
                                >
                                    <Icon name="eye-off" />
                                </div>
                            ) : (
                                <div
                                    className="position-absolute"
                                    style={{
                                        top: "30%",
                                        right: "2%",
                                        cursor: "pointer",
                                    }}
                                    onClick={handleVisibiltyPassword}
                                >
                                    <Icon name="eye" />
                                </div>
                            )}
                            <Input
                                type={eyeVisible ? "text" : "password"}
                                placeholder="Enter your password"
                                value={user?.password}
                                onChange={(e) => handelChnage("password", e)}
                                name="password"
                                required
                            />
                            {error?.password && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.password}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="mobileNumber" className="form-label">
                                <strong>Pharmacits Contact Number</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter Contact Number"
                                name='namobileNumberme'
                                value={user?.mobileNumber}
                                onChange={(e) => handelChnage("mobileNumber", e)}
                            />
                            {error?.mobileNumber && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.mobileNumber}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="mobileNumber" className="form-label">
                                <strong>Sex</strong><span className='text-danger'>*</span>
                            </label>
                            <Select
                                className='col-12'
                                placeholder="Please select sex"
                                value={user?.sex}
                                onChange={(value) => handelChnage("sex", value)}
                                options={[
                                    { label: "MALE", value: "MALE" },
                                    { label: "FEMALE", value: "FEMALE" },
                                    { label: "OTHER", value: "OTHER" }
                                ]}
                            />
                            {error?.sex && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.sex}
                                </span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="col-12 d-flex gap-2  justify-content-center"
                        style={{ marginTop: "20px" }}
                    >
                        <button
                            className="col-4 primary-btn bg-light text-black "
                            onClick={() => { setUser(); setModal(false) }}>
                            Cancel
                        </button>
                        <button
                            onClick={() => updateUser()}
                            className="col-4 primary-btn"
                            style={{ marginRight: "15px" }}>
                            Update
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            {!viewUser ? (
                !isCreatePharmacist ? (
                    <Layout title="Manage Users Data" centered>
                        <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth ">
                            <Row className=" p-3">
                                <div>
                                    <h2 className="my-4">Pharmacist</h2>
                                    <div className="mb-3 d-flex justify-content-between">
                                        <input
                                            type="text"
                                            className="form-control w-50"
                                            placeholder="Search Users..."
                                            onChange={(e) => onSearching(e)}
                                        />
                                        <button className="btn btn-success" onClick={() => setIsCreatePharmacist(true)}>
                                            <i className="bi bi-plus-circle"></i> Add Pharmacist
                                        </button>
                                    </div>

                                    <div>
                                        <Table columns={columns} dataSource={searchData || usersData} />
                                    </div>
                                </div>
                            </Row>
                        </Card>
                    </Layout>
                ) : (
                    <>
                        <CreatePharmacits
                            user={user}
                            handelChnage={handelChnage}
                            error={error}
                            setIsCreatePharmacist={setIsCreatePharmacist}
                            showMessage={showMessage}
                            registerUser={registerUser}
                            setPharmacyId={setPharmacyId}
                            setUser={setUser}
                            defaultUser={defaultUser}
                            isPharmacySelected={isPharmacySelected}
                            setIsPharmacySelected={setIsPharmacySelected}
                            pharmacyId={pharmacyId}
                            pharmacyData={pharmacyData}
                            ownerName={ownerName} />
                    </>
                )
            ) : (
                <>
                    <UserProfile user={user} setViewUser={setViewUser} setUser={setUser} defaultUser={defaultUser} />
                </>
            )}
        </>

    )
}

export default PharmacitsDetails