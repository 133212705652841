import React, { useEffect, useState } from 'react';
import Layout from "../../../../layout/default";
import { Card, Row, Col } from 'react-bootstrap';
import notification from '../../../../Model/notification';
import { useNavigate } from 'react-router-dom';
import '../../../../App.css';
import './Profile.css';
import { Icon } from "../../../../components";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const NotificationMain = () => {
    const [notificationData, setNotificationData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getMyNotification();
    }, []);

    const getMyNotification = () => {
        notification.getMyNotifications()
            .then((res) => {
                setNotificationData(res.notification);
            }).catch((error) => {
                console.log("Error in get my notifications");
            })
    };

    const readNotification = (notificationId) => {
        notification.readMyNotifications(notificationId)
            .then((res) => {
                setNotificationData((prevData) => prevData.filter(notification => notification._id !== notificationId));
            }).catch((error) => {
                console.log("Error in reading notification");
            })
    };

    return (
        <Layout title="Notifications">
            <Card>
                <Row className='p-3'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="d-flex align-items-center position-relative mb-2">
                                    <span
                                        onClick={() => navigate("/dashboard")}
                                        style={{ position: "absolute", left: 0, cursor: "pointer" }}
                                    >
                                        <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                    </span>
                                    <div className="mx-auto fw-bold text-center" style={{ fontSize: "20px" }}>
                                        Notifications
                                    </div>
                                </div>

                                {notificationData.length === 0 ? (
                                    <div className="no-data">
                                        <div className="no-data-message">
                                            <i className="fas fa-exclamation-circle"></i>
                                            <p>Notifications not available</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            maxHeight: '334px',
                                            overflowY: 'auto',
                                            scrollbarWidth: 'none',
                                            msOverflowStyle: 'none',
                                        }}
                                        className="hide-scrollbar"
                                    >
                                        <TransitionGroup>
                                            <Row>
                                                {notificationData?.map((notification, index) => (
                                                    <CSSTransition
                                                        key={notification._id}
                                                        timeout={300}
                                                        classNames="fade"
                                                    >
                                                        <Col md={12} className="mb-3">
                                                            <Card className="h-70">
                                                                <div className="d-flex justify-content-between align-items-start p-2">
                                                                    <Card.Img
                                                                        variant="top"
                                                                        src={notification.image}
                                                                        alt={notification.title}
                                                                        className="notification-image"
                                                                        style={{ height: '100px', width: '100px' }}
                                                                    />
                                                                    <Card.Body>
                                                                        <Card.Title>{notification.title}</Card.Title>
                                                                        <Card.Text>{notification.description}</Card.Text>
                                                                        <div className="d-flex justify-content-between">
                                                                            <small className="text-muted">
                                                                                {new Date(notification.createdAt).toLocaleDateString()}
                                                                            </small>
                                                                        </div>
                                                                    </Card.Body>
                                                                    <button
                                                                        className="btn"
                                                                        onClick={() => readNotification(notification._id)}
                                                                    >
                                                                        <Icon name="cross" ></Icon>
                                                                    </button>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    </CSSTransition>
                                                ))}
                                            </Row>
                                        </TransitionGroup>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Row>
            </Card>
        </Layout>
    );
};

export default NotificationMain;
