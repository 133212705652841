import React, { useEffect, useState } from 'react'
import Layout from "../../../layout/default"
import { Card, Modal, Row } from 'react-bootstrap'
import drugs from '../../../Model/drugs'
import Swal from 'sweetalert2'
import { Input, Table } from 'antd'
import { Icon } from '@iconify/react/dist/iconify.js'
import useValidator from '../../../plugins/useValidator'
import TextArea from 'antd/es/input/TextArea'
import CreateDrugs from './CreateDrugs'
import ViewDrug from './ViewDrug'
import { useAuth } from '../../../plugins/context/AppContext'

const defaultDrug = {
    commercialName: "",
    activePrinciple: "",
    medicineImage: "",
    notice: "",
    price: "",
    substituteMedicine: ""
}

const DrugsDetails = () => {
    const [drugsData, setDrugsData] = useState()
    const [searchData, setSearchData] = useState()
    const [modal, setModal] = useState(false)
    const [data, setData] = useState(defaultDrug)
    const [deleteId, setDeleteId] = useState()
    const [isDelete, setIsDelete] = useState()
    const [validator, showMessage] = useValidator()
    const [isCreateDrug, setIsCreateDrug] = useState(false);
    const [viewDrug, setViewDrug] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();


    const handleChange = (e, name) => {
        if (name === "medicineImage") {
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result.split(",")[1];
                    setData((prev) => ({
                        ...prev,
                        [name]: base64String,
                    }));
                };
                reader.readAsDataURL(file);
            }
        }
        else {
            setData((prev) => ({
                ...prev,
                [name]: e.target.value

            }))
        }
    }

    useEffect(() => {
        getAllDrugs()
    }, [])
    const getAllDrugs = () => {
        drugs
            .getAllDrugsData()
            .then((res) => {
                setDrugsData(res)
            })
            .catch((error) => {
                Swal.fire({
                    title: error.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
    }

    const columns = [
        {
            title: "SrNo",
            key: "number",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Medicine Name",
            dataIndex: "commercialName",
            render: (text, record) => (
                <div onClick={() => { setData(record); setViewDrug(true) }} style={{ color: "blue", cursor: "pointer" }}>{text}</div>

            )
        },
        {
            title: "Active Principle",
            dataIndex: "activePrinciple",
        },
        {
            title: "Price(XOF)",
            dataIndex: "price",
        },
        {
            title: "Substitute Medicine",
            dataIndex: "substituteMedicine",
        },
        {
            title: "Actions",
            render: (text, record) => (
                <div class="dropdown">
                    <span className="dropdown-toggle" data-bs-toggle="dropdown">
                    </span>
                    <ul class="dropdown-menu" style={{ height: 'fit-content' }}>
                        <Icon name="more-v"></Icon>
                        <li>
                            <p

                                class="dropdown-item d-flex align-items-center p-1 text-warning "
                                onClick={() => { setData(record); setIsCreateDrug(true) }}
                            >
                                <Icon icon="wpf:edit" className="me-1 " />
                                <span className="col-5 ">Update</span>
                            </p>
                        </li>
                        {user?.role === "ADMIN" && (
                            <li>
                                <p
                                    class="dropdown-item d-flex align-items-center p-1 text-danger "
                                    onClick={() => { setIsDelete(true); setDeleteId(record._id) }}
                                >
                                    <Icon icon="mingcute:delete-2-line" className="me-1 " />
                                    <span className="col-5 ">Delete</span>
                                </p>
                            </li>
                        )}
                    </ul>
                </div>
            ),
        },
    ]

    const error = {
        commercialName: validator.message("Medicine Name", data?.commercialName, "required"),
        activePrinciple: validator.message("Owner Name", data?.activePrinciple, "required"),
        notice: validator.message("Address", data?.notice, "required"),
        image: validator.message("Image", data?.medicineImage, "required"),
        price: validator.message("price", data?.price, "required"),
        substituteMedicine: validator.message("Substitute Medicine", data?.substituteMedicine, "required"),
    }

    const onSearching = (e) => {
        const { value } = e?.target;
        setSearchData(() => {
            return drugsData?.filter((item) => {
                return (
                    item?.commercialName?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.precautions?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.substituteMedicine?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.sideEffects?.toLowerCase().includes(value?.toLowerCase())
                );
            });
        });
    };

    const handelClose = () => {
        setModal(false)
        setData(defaultDrug)
        showMessage(false)
    }

    const deleteConfirm = () => {
        drugs
            .deleteDrug(deleteId)
            .then((res) => {
                setDeleteId()
                setIsDelete(false)
                getAllDrugs()
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
            .catch((error) => {
                Swal.fire({
                    title: error.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                })
                setDeleteId()
                setIsDelete(false)
            })
    }

    const updateDrug = () => {
        if (validator.allValid()) {
            setLoading(true);
            drugs
                .updateDrug(data._id, data)
                .then((res) => {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    getAllDrugs()
                    setModal(false)
                    setIsCreateDrug(false)
                    setData(defaultDrug)

                })
                .catch((error) => {
                    setModal(false)
                    setIsCreateDrug(false)
                    setData(defaultDrug)
                    Swal.fire({
                        title: error.message,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                }).finally(() => setLoading(false))
        }
        else {
            showMessage(true)
        }
    }

    const saveDrug = () => {
        if (validator.allValid()) {
            setLoading(true);
            drugs
                .saveDrug(data)
                .then((res) => {
                    Swal.fire({
                        title: res.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    getAllDrugs()
                    setModal(false)
                    setIsCreateDrug(false)
                    setData(defaultDrug)
                })
                .catch((error) => {
                    setModal(false)
                    setData(defaultDrug)
                    setIsCreateDrug(false)
                    Swal.fire({
                        title: error.message,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                }).finally(() => setLoading(false))
        }
        else {
            showMessage(true)
        }
    }

    return (
        <>
            <Modal centered show={isDelete} onHide={() => setIsDelete(false)}>
                <Modal.Body>
                    <p
                        className="text-center"
                        style={{ fontSize: "23px", fontWeight: "bold" }}
                    >
                        Confirm delete
                    </p>
                    <p className="text-center">

                        <p className='mt-2'>Are you sure to delete this Drug</p>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="col-12 d-flex gap-2  justify-content-center"
                        style={{ marginTop: "20px" }}
                    >
                        <button
                            className="col-4 cancel-btn"
                            onClick={() => { setIsDelete(false); setDeleteId() }}

                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => deleteConfirm()}

                            className="col-4 delete-btn "
                            style={{ marginRight: "15px" }}
                        >
                            Delete
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal size='md' centered show={modal} onHide={handelClose}>
                <Modal.Body>
                    <p
                        className="text-center"
                        style={{ fontSize: "23px", fontWeight: "bold" }}
                    >
                        {data?._id ? 'Update Drug' : 'Create Drug'}
                    </p>
                    <div className='container d-flex flex-wrap ' >
                        <div className='col-12 col-md-6 px-2'>
                            <label htmlFor="commercialName " className="form-label">
                                <strong>Drug Name</strong><span className='text-danger'>*</span>
                            </label>
                            <Input

                                placeholder="Enter name of the Drug"
                                name='commercialName '
                                value={data?.commercialName}
                                onChange={(e) => handleChange(e, "commercialName ")}
                            />
                            {error?.commercialName && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.commercialName}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2'>
                            <label htmlFor="activePrinciple" className="form-label">
                                <strong>activePrinciple</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter name of the activePrinciple"
                                name='activePrinciple'
                                value={data?.activePrinciple}
                                onChange={(e) => handleChange(e, "activePrinciple")}
                            />
                            {error?.activePrinciple && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.activePrinciple}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2'>
                            <label htmlFor="medicineImage" className="form-label">
                                <strong>Medicine Image</strong>
                            </label>
                            <Input
                                type="file"
                                name="medicineImage"
                                accept="image/*"
                                onChange={(e) => handleChange(e, "medicineImage")}
                            />

                        </div>
                        <div className='col-12 col-md-6 px-2'>
                            <label htmlFor="No Of notice" className="form-label">
                                <strong>No Of notice</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter No Of notice per day"
                                name='notice'
                                value={data?.notice}
                                onChange={(e) => handleChange(e, "notice")}
                            />
                            {error?.notice && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.notice}
                                </span>
                            )}
                        </div>

                        <div className='col-12 col-md-6 px-2'>
                            <label htmlFor="sideEffects" className="form-label">
                                <strong>Side Effects</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type={"text"}
                                placeholder="Enter Side Effects"
                                name='sideEffects'
                                value={data?.sideEffects}
                                onChange={(e) => handleChange(e, "sideEffects")}
                            />
                            {error?.sideEffects && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.sideEffects}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2'>
                            <label htmlFor="precautions" className="form-label">
                                <strong>Precautions</strong><span className='text-danger'>*</span>
                            </label>

                            <Input
                                placeholder="Enter Precautions"
                                name='precautions'
                                value={data?.precautions}
                                onChange={(e) => handleChange(e, "precautions")}
                            />
                            {error?.precautions && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.precautions}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2'>
                            <label htmlFor="price" className="form-label">
                                <strong>Price</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type={"number"}
                                placeholder="Enter Price of drug"
                                name='price'
                                value={data?.price}
                                onChange={(e) => handleChange(e, "price")}
                            />
                            {error?.price && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.price}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2'>
                            <label htmlFor="instructions" className="form-label">
                                <strong>Instructions</strong><span className='text-danger'>*</span>
                            </label>
                            <div>
                                <TextArea
                                    className='col-12'
                                    placeholder='Enter Instructions'
                                    name="instructions"
                                    value={data?.instructions}
                                    onChange={(date) => handleChange(date, "instructions")}
                                />
                            </div>
                            {error?.instructions && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.instructions}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2'>
                            <label htmlFor="substituteMedicine" className="form-label">
                                <strong>Substitute Medicine</strong><span className='text-danger'>*</span>
                            </label>
                            <div>
                                <Input
                                    className='col-12'
                                    placeholder='Enter Substitute Medicine Name'
                                    name="substituteMedicine"
                                    value={data?.substituteMedicine}
                                    onChange={(date) => handleChange(date, "substituteMedicine")}
                                />
                            </div>
                            {error?.substituteMedicine && (
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    {error?.substituteMedicine}
                                </span>
                            )}
                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div
                        className="col-12 d-flex gap-2  justify-content-center"
                        style={{ marginTop: "20px" }}
                    >
                        <button
                            className="col-4 cancel-btn bg-light text-dark"
                            onClick={handelClose}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => data?._id ? updateDrug() : saveDrug()}

                            className="col-4 primary-btn "
                            style={{ marginRight: "15px" }}
                        >
                            {data?._id ? 'Update' : 'Save'}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            {!viewDrug ? (
                !isCreateDrug ? (
                    <Layout title="Manage Drugs Data" centered>
                        <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth ">
                            <Row className=" p-3">
                                <div className="container">
                                    <h2 className="my-4">Drugs Details</h2>
                                    <div className="mb-3 d-flex justify-content-between">
                                        <input
                                            type="text"
                                            className="form-control w-50"
                                            placeholder="Search Drug..."
                                            onChange={(e) => onSearching(e)}
                                        />
                                        <button className="btn btn-success" onClick={() => setIsCreateDrug(true)}>
                                            <i className="bi bi-plus-circle"></i> Add Drug
                                        </button>
                                    </div>
                                    <div>
                                        <Table columns={columns} dataSource={searchData || drugsData} />
                                    </div>
                                </div>

                            </Row>
                        </Card>

                    </Layout>
                ) : (
                    <>
                        <CreateDrugs
                            data={data}
                            setData={setData}
                            handleChange={handleChange}
                            error={error}
                            showMessage={showMessage}
                            defaultDrug={defaultDrug}
                            setIsCreateDrug={setIsCreateDrug}
                            saveDrug={saveDrug}
                            updateDrug={updateDrug}
                            loading={loading}
                        /></>
                )
            ) : (
                <>
                    <ViewDrug setViewDrug={setViewDrug}
                        data={data}
                        setData={setData}
                        defaultDrug={defaultDrug} />
                </>
            )}
        </>
    )
}

export default DrugsDetails