import { Row, Col, Card } from "react-bootstrap";

import Layout from "../layout/default";
import { Media, Icon, ChartLabel } from "../components";
import { ChartBar, ChartLine } from "../components/Chart/Charts";
import { Colors } from "../utilities/index";
import hexRGB from "../utilities/hexRGB";
import Swal from "sweetalert2";
import users from "../Model/users";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [usersData, setUsersData] = useState();
  const [userChartLabels, setUserChartLabels] = useState([]);
  const [pharmacistChartLabels, setPharmacistChartLabels] = useState([]);
  const [userChart, setUserChart] = useState({
    labels: [],
    xAxis: false,
    borderDash: [8, 4],
    barThickness: 6,
    datasets: [
      {
        borderRadius: 2,
        borderColor: Colors.primary,
        backgroundColor: Colors.primary,
        label: "Users",
        data: [],
      },
    ],
  });

  const [pharmacistData, setPharmacistData] = useState({
    labels: [],
    yAxis: false,
    xAxis: false,
    datasets: [
      {
        tension: 0.4,
        label: "Pharmacist",
        borderColor: Colors.success,
        borderWidth: 2,
        backgroundColor: hexRGB(Colors.success, 0.2),
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: Colors.success,
        fill: true,
        data: []
      },
    ],
  });

  useEffect(() => {
    if (usersData?.registeredPatients) {
      const labels = usersData.registeredPatients.map((user) =>
        new Date(user.date).toLocaleDateString("en-US", {
          day: "numeric",
          month: "short",
        })
      );

      if (labels.length > 0) {
        const first = labels[0];
        const middle = labels[Math.floor(labels.length / 2)];
        const last = labels[labels.length - 1];

        setUserChartLabels([first, middle, last]);
      }
      const data = usersData.registeredPatients.map((user) => user.count);
      setUserChart((prevChart) => ({
        ...prevChart,
        labels,
        datasets: [
          {
            ...prevChart.datasets[0],
            data,
          },
        ],
      }));
    }
    if (usersData?.registeredPharmacists) {
      const labels = usersData.registeredPharmacists.map(pharmacist => 
        new Date(pharmacist.date).toLocaleDateString('en-US', { day: 'numeric', month: 'short' })
      );
      const data = usersData.registeredPharmacists.map(pharmacist => pharmacist.count);
      if (labels.length > 0) {
        const first = labels[0];
        const middle = labels[Math.floor(labels.length / 2)];
        const last = labels[labels.length - 1];

        setPharmacistChartLabels([first, middle, last]);
      }
  
      setPharmacistData(prevChart => ({
        ...prevChart,
        labels,
        datasets: [
          {
            ...prevChart.datasets[0],
            data
          }
        ]
      }));
    }
  }, [usersData]);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    users
      .getAllData()
      .then((resposne) => {
        setUsersData(resposne);
        console.log(resposne, "USER");
      })
      .catch((error) => {
        Swal.fire({
          title: error.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };


  return (
    <Layout title="Analytics">
      <Row className="g-gs">
        <Col sm="6" xxl="3">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group align-items-start">
                <div className="card-title">
                  <h4 className="title">Patients</h4>
                </div>
                <Media size="sm" shape="circle" variant="primary-soft">
                  <Icon name="user-alt-fill"></Icon>
                </Media>
              </div>
              <div className="mt-2 mb-4">
                <div className="amount h1">{usersData?.patientCount}</div>
                <div className="d-flex align-items-center smaller">
                  <div className="change up">
                    <Icon name="upword-alt-fill"></Icon>
                    <span className="ms-1"></span>
                  </div>
                  <span className="text-light">In this month</span>
                </div>
              </div>
              <div className="nk-chart-analytics-session">
                <ChartBar data={userChart} />
              </div>
              <ChartLabel.Group className="justify-content-between mt-1">
                {userChartLabels.map((label, index) => (
                  <ChartLabel key={index} className="chart-label-small">
                    {label}
                  </ChartLabel>
                ))}
              </ChartLabel.Group>
            </Card.Body>
          </Card>
        </Col>

        <Col sm="6" xxl="3">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group align-items-start">
                <div className="card-title">
                  <h4 className="title">Pharmacist</h4>
                </div>
                <Media size="sm" shape="circle" variant="success-soft">
                  <Icon name="bar-chart-fill"></Icon>
                </Media>
              </div>
              <div className="mt-2 mb-4">
                <div className="amount h1">{usersData?.pharmacistCount}</div>
                <div className="d-flex align-items-center smaller">
                  <div className="change up">
                    <Icon name="upword-alt-fill"></Icon>
                    <span className="ms-1"></span>
                  </div>
                  <span className="text-light">In this month</span>
                </div>
              </div>
              <div className="nk-chart-analytics-session">
                <ChartLine data={pharmacistData} />
              </div>
              <ChartLabel.Group className="justify-content-between mt-1">
                {pharmacistChartLabels.map((label, index) => (
                  <ChartLabel key={index} className="chart-label-small">
                    {label}
                  </ChartLabel>
                ))}
              </ChartLabel.Group>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default Home;
