import React, { useEffect, useState } from "react";
import Layout from "../../../layout/default";
import { Card, Row, Col } from "react-bootstrap";
import payment from "../../../Model/payment";
import { DatePicker } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;


const TransactionHistory = () => {
    const [transactionList, setTransactionList] = useState([]);
    const [filteredTransactionList, setFilteredTransactionList] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getTransactionHistory();
    }, []);

    const getTransactionHistory = () => {
        setLoading(true);
        payment
            .getTransactionHistory()
            .then((res) => {
                setTransactionList(res);
                setFilteredTransactionList(res);
            })
            .catch((error) => {
                console.log("Error: ", error);
            })
            .finally(() => setLoading(false));
    };

    const handleFilter = (dates) => {
        if (!dates || dates.length !== 2) {
            setFilteredTransactionList(transactionList);
            return;
        }

        setLoading(true);
        const formattedDates = {
            startDate: dates[0].startOf('day').format('YYYY-MM-DD'),
            endDate: dates[1].endOf('day').format('YYYY-MM-DD'),
        };

        console.log('Selected Date Range:', formattedDates);

        const filteredTransactions = transactionList?.filter((item) => {
            const paymentDate = moment(item.paymentDate, 'YYYY-MM-DD');
            return paymentDate.isBetween(formattedDates.startDate, formattedDates.endDate, null, '[]');
        });
        setFilteredTransactionList(filteredTransactions);
        setLoading(false);
    };


    return (
        <Layout>
            <Row>
                <Col md={12}>
                    <div className="d-flex justify-content-between">
                        <h3 className="mb-4">Transaction History</h3>
                        <span>
                            <RangePicker
                                disabledDate={(current) => current.isAfter(moment())}
                                picker="date"
                                id={{
                                    start: 'startInput',
                                    end: 'endInput',
                                }}
                                onChange={(dates) => handleFilter(dates)}
                            />
                        </span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div
                        style={{
                            maxHeight: '365px',
                            overflowY: 'auto',
                            scrollbarWidth: 'none',
                            msOverflowStyle: 'none',
                        }}
                        className="d-flex flex-column gap-3 hide-scrollbar"
                    >
                        {loading ? (
                            <div className="loader-container">
                                <span className="loader"></span>
                            </div>
                        ) : (
                            filteredTransactionList.length === 0 ? (
                                <div className="no-data">
                                    <div className="no-data-message">
                                        <i className="fas fa-exclamation-circle"></i>
                                        <p>No Transaction History</p>
                                    </div>
                                </div>
                            ) : (
                                filteredTransactionList?.map((transaction) => (
                                    <Card key={transaction._id} className="w-100">
                                        <Card.Body>
                                            <h6 className="mb-3">
                                                Transaction ID:{" "}
                                                <span className="text-primary" style={{ fontSize: '0.875rem' }}>
                                                    {transaction.transactionId}
                                                </span>
                                            </h6>
                                            <p className="mb-1" style={{ fontSize: '0.875rem' }}>
                                                <strong>Amount:</strong> {transaction.amount} {transaction.currency}
                                            </p>
                                            <p className="mb-1" style={{ fontSize: '0.875rem' }}>
                                                <strong>Status:</strong>{" "}
                                                <span
                                                    className={
                                                        transaction.status === "COMPLETED"
                                                            ? "text-success"
                                                            : "text-danger"
                                                    }
                                                >
                                                    {transaction.status}
                                                </span>
                                            </p>
                                            <p className="mb-1" style={{ fontSize: '0.875rem' }}>
                                                <strong>Payment Date:</strong>{" "}
                                                {transaction.paymentDate}
                                            </p>
                                            <p className="mb-1" style={{ fontSize: '0.875rem' }}>
                                                <strong>Receiver:</strong> {transaction.receiverId.name || "N/A"}
                                            </p>
                                            <p className="mb-1" style={{ fontSize: '0.875rem' }}>
                                                <strong>Payment Method:</strong> {transaction.paymentMethod}
                                            </p>
                                            <p className="mb-1" style={{ fontSize: '0.875rem' }}>
                                                <strong>Transaction Type:</strong> {transaction.transactionType}
                                            </p>
                                        </Card.Body>
                                    </Card>
                                ))
                            )
                        )}
                    </div>
                </Col>
            </Row>
        </Layout>
    );
};

export default TransactionHistory;
