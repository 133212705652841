import React from 'react'
import { Input } from 'antd';
import { Card, Row, Spinner } from 'react-bootstrap';
import Layout from "../../../layout/default";


const CreateDrugs = ({
    data,
    setData,
    handleChange,
    error,
    showMessage,
    defaultDrug,
    setIsCreateDrug,
    saveDrug,
    updateDrug,
    loading
}) => {

    return (
        <Layout title="Manage Drugs Data" centered>
            <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth ">
                <Row className=" p-3">
                </Row>
                <div className="container d-flex">
                    <h2 className="my-4" style={{ cursor: "pointer" }} onClick={() => { setData(defaultDrug); setIsCreateDrug(false); showMessage(false) }}>Drugs /  </h2>
                    <h2 className="my-4" style={{ color: "#8CBF22" }}>{data?._id ? "Update Drug" : "Create Drug"}</h2>
                </div>
                <div className='container d-flex flex-wrap ' >
                    <div className='col-12 col-md-6 px-2'>
                        <label htmlFor="commercialName" className="form-label">
                            <strong>Drug Name</strong><span className='text-danger'>*</span>
                        </label>
                        <Input

                            placeholder="Enter name of the Drug"
                            name='commercialName'
                            value={data?.commercialName}
                            onChange={(e) => handleChange(e, "commercialName")}
                        />
                        {error?.commercialName && (
                            <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                {" "}
                                {error?.commercialName}
                            </span>
                        )}
                    </div>
                    <div className='col-12 col-md-6 px-2'>
                        <label htmlFor="activePrinciple" className="form-label">
                            <strong>Active Principle</strong><span className='text-danger'>*</span>
                        </label>
                        <Input
                            placeholder="Enter name of the activePrinciple"
                            name='activePrinciple'
                            value={data?.activePrinciple}
                            onChange={(e) => handleChange(e, "activePrinciple")}
                        />
                        {error?.activePrinciple && (
                            <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                {" "}
                                {error?.activePrinciple}
                            </span>
                        )}
                    </div>
                    <div className='col-12 col-md-6 px-2'>
                        <label htmlFor="medicineImage" className="form-label">
                            <strong>Medicine Image</strong><span className='text-danger'>*</span>
                        </label>
                        <Input
                            type="file"
                            name="medicineImage"
                            accept="image/*"
                            onChange={(e) => handleChange(e, "medicineImage")}
                        />
                        {error?.image && (
                            <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                {" "}
                                {error?.image}
                            </span>
                        )}

                    </div>
                    <div className='col-12 col-md-6 px-2'>
                        <label htmlFor="price" className="form-label">
                            <strong>Price</strong><span className='text-danger'>*</span>
                        </label>
                        <Input
                            type={"number"}
                            placeholder="Enter Price of drug"
                            name='price'
                            value={data?.price}
                            onChange={(e) => handleChange(e, "price")}
                        />
                        {error?.price && (
                            <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                {" "}
                                {error?.price}
                            </span>
                        )}
                    </div>

                    <div className='col-12 col-md-6 px-2'>
                        <label htmlFor="substituteMedicine" className="form-label">
                            <strong>Substitute Medicine</strong><span className='text-danger'>*</span>
                        </label>
                        <div>
                            <Input
                                className='col-12'
                                placeholder='Enter Substitute Medicine Name'
                                name="substituteMedicine"
                                value={data?.substituteMedicine}
                                onChange={(date) => handleChange(date, "substituteMedicine")}
                            />
                        </div>
                        {error?.substituteMedicine && (
                            <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                {" "}
                                {error?.substituteMedicine}
                            </span>
                        )}
                    </div>

                    <div className='col-12 px-2'>
                        <label htmlFor="notice" className="form-label">
                            <strong>Notice</strong><span className='text-danger'>*</span>
                        </label>
                        <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            placeholder='Enter notice'
                            name='notice'
                            value={data?.notice}
                            rows="4"
                            onChange={(e) => handleChange(e, "notice")}
                        />
                        {error?.notice && (
                            <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                {" "}
                                {error?.notice}
                            </span>
                        )}
                    </div>

                </div>
                <div className="col-12 d-flex justify-content-center px-2 py-5">
                    <button
                        onClick={() => { data?._id ? updateDrug() : saveDrug() }}
                        className="col-4 primary-btn "
                        style={{ marginRight: "15px", backgroundColor: '#8CBF22' }}
                    >
                        {loading ? (
                            <Spinner animation="border" size="sm" />
                        ) : (
                            data?._id ? "Update" : "Save"
                        )}
                    </button>
                </div>
            </Card>
        </Layout>

    )
}

export default CreateDrugs