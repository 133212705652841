import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import Layout from "../../../layout/default";
import drugs from "../../../Model/drugs";
import Swal from "sweetalert2";
import '../../../App.css';


const DrugHistory = () => {
    const [drugHistory, setDrugHistory] = useState([]);


    useEffect(() => {
        getDrugHistory();
    }, []);

    const getDrugHistory = () => {
        drugs
            .getDrugHistory()
            .then((response) => {
                setDrugHistory(response);
            })
            .catch((error) => {
                Swal.fire({
                    title: error?.error,
                    timer: 1500,
                    showConfirmButton: false,
                    icon: "error",
                });
            });
    };

    const handleUpdateDrugStatus = (id) => {
        drugs.isMedicineDelivered(id)
            .then((res) => {
                getDrugHistory();
            }).catch((error) => {
                console.log("Error in handle update drug status");
            })
    };

    return (
        <Layout title="Drug Request History">
            <div className="p-4">
                <h2 className="text-center mb-4 text-primary">Drug Request History</h2>
                <div>
                    {drugHistory?.map((item, index) => (
                        <Card key={index} className="mb-3 p-3 shadow-sm border-0">
                            <Row className="align-items-center">
                                {/* Prescription Image */}
                                <Col md={2} className="text-center">
                                    <img
                                        src={item?.respondedDrug?.prescriptionImage}
                                        alt="Prescription"
                                        className="rounded"
                                        style={{
                                            width: "100px",
                                            height: "100px",
                                            objectFit: "cover",
                                            border: "2px solid #007bff",
                                            padding: "5px",
                                        }}
                                    />
                                </Col>

                                {/* Drug Details */}
                                <Col md={8}>
                                    <h5 className="fw-bold text-dark mb-2">
                                        {item?.respondedDrug?.medicineName || "Medicine Name"}
                                    </h5>
                                    <div className="d-flex justify-content-between flex-wrap">
                                        <div>
                                            <div className="text-muted">Response:</div>
                                            <div
                                                className={
                                                    item?.response ? "text-success fw-bold" : "text-danger fw-bold"
                                                }
                                            >
                                                {item?.response ? "AVAILABLE" : "NOT AVAILABLE"}
                                            </div>
                                        </div>
                                        <div>
                                            <div className="text-muted">Response Date:</div>
                                            <div className="fw-bold">{item?.respondedDate.split("T")[0]}</div>
                                        </div>
                                        <div>
                                            <div className="text-muted">Response Time:</div>
                                            <div className="fw-bold">{item?.respondedTime}</div>
                                        </div>
                                    </div>
                                </Col>

                                {/* Booked Medicine Details */}
                                {item?.isBooked && (
                                    <Col md={2} className="text-center">
                                        <div className="p-2 bg-light rounded">
                                            <h6 className="fw-bold text-success mb-1">Booked</h6>
                                            <div className="text-muted">Date:</div>
                                            <div className="fw-bold">{item?.bookedDate.split("T")[0]}</div>
                                            <div className="text-muted mt-1">Time:</div>
                                            <div className="fw-bold">{item?.bookedTime}</div>
                                        </div>
                                    </Col>
                                )}
                                {
                                    item?.isBooked && item.respondedDrug.isDelivered && (
                                        <Col md={12} className="text-center">
                                            <h5>Order Completed</h5>
                                        </Col>
                                    )
                                }
                                {
                                    item?.isBooked && !item.respondedDrug.isDelivered && (
                                        <Col md={12} className="text-center mt-1">
                                            <button
                                                className="btn btn-primary btn-md shadow-sm px-4 py-1 rounded-pill"
                                                style={{
                                                    backgroundColor: "#007bff",
                                                    border: "none",
                                                    transition: "all 0.3s ease-in-out",
                                                }}
                                                onMouseOver={(e) => {
                                                    e.target.style.backgroundColor = "#0056b3";
                                                }}
                                                onMouseOut={(e) => {
                                                    e.target.style.backgroundColor = "#007bff";
                                                }}
                                                onClick={() => handleUpdateDrugStatus(item.respondedDrug._id)}
                                            >
                                                Click if the medicine is received by the Patient
                                            </button>
                                        </Col>
                                    )
                                }

                            </Row>
                        </Card>
                    ))}
                </div>
            </div>
        </Layout>
    );
};


export default DrugHistory;
