import React,{useState} from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from "react-router-dom";
import auth from "../../Model/auth";
import Swal from 'sweetalert2';
import Layout from '../../layout/fullpage';
import logo from "../../assets/logo.png";
const obj ={
    OTP : ""
}
const VerifyOtp = () => {
    const {state} = useLocation();
    const [email] = useState(state.email);
    const [data ,setData] = useState(obj)
    const navigate = useNavigate()
    const handleChange =(e)=>{
        let {name,value} = e.target;
        setData((prev)=>({
            ...data,
            [name]:value
        }))
    }
  
    const verify = ()=>{
        const sendData = {
            otp:data.OTP,
            email:state.email
        }
        auth
        .verifyOtp(sendData)
        .then((res)=>{
            Swal.fire({
                title: "OTP Verify Successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              })
              navigate("/auths/change-password",{
                state: {
                  email: email,
                }})

        })
        .catch((err)=>{
            Swal.fire({
                title: err.message,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
              })

        })
    }

  return (
    <Layout title="Forgot Password" centered>
    <div className="container p-2 p-sm-4">
        <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
            <Row className="g-0 flex-lg-row-reverse">
                <Col lg="5" className="shadow rounded ">
                    <Card.Body className="h-100 d-flex flex-column justify-content-center">
                        <div className="nk-block-head text-center">
                            <div className="nk-block-head-content">
                            <h3 className="nk-block-title mb-2">Verify OTP</h3>
                                <p className="small col-md-10 mx-auto">Please enter OTP recieve on your mail</p>
                            </div>
                        </div>
                        <div>
                            <Col>
                            <Form.Group className="form-group">
                                        <Form.Label htmlFor="email">OTP</Form.Label>
                                        <div className="form-control-wrap">
                                            <Form.Control type="text" id="email" name="OTP" onChange={(e)=> handleChange(e)}  placeholder="Enter OTP"/>
                                        </div>
                                    </Form.Group>
                            </Col>
                            <Col className='col-12 mt-2'>
                                <Button className='col-12'onClick={()=>verify()} >Verify OTP</Button>
                            </Col>
                          
                        </div>
                        <div className="text-center mt-4">
                            <p className="small"><Link to="/auths/auth-login">Back to Login</Link></p>
                        </div>
                    </Card.Body>
                </Col>
                <Col lg="7">
                    <Card.Body className="is-theme has-mask has-mask-1 h-100 d-flex flex-column align-items-center   ">
                       
                        <div className="brand-logo">
                        <img
                  src={logo}
                  alt="logo"
                  width={"300px"}
                  className="mt-3 "
                />
                        </div>
                        
                       
                        
                    </Card.Body>
                </Col>
            </Row>
        </Card>
    </div>
</Layout>
  )
}

export default VerifyOtp