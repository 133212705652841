import React from 'react'

function Footer() {
  return (
    <div className="nk-footer">
        <div className="container-fluid">
            <div className="nk-footer-wrap">
                <div className="nk-footer-copyright"> 
                    &copy; 2024 Pharmaconsults
                </div>
                <div className="nk-footer-links">
                 
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer