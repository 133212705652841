import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Button, Alert, Spinner, InputGroup } from 'react-bootstrap';
import Layout from "../../layout/default";
import Swal from "sweetalert2";
import auth from '../../Model/auth';


const ResetPassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [authUser, setAuthUser] = useState();
    const [passwordChecked, setPasswordChecked] = useState(false);
    const [errors, setErrors] = useState({});
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    

    useEffect(() => {
        getAuthUser();
    }, []);

    const getAuthUser = () => {
        auth.getAuthUser()
            .then((res) => {
                setAuthUser(res);
            }).catch((error) => {
                console.log("Error in Get auth user", error);
            }).finally(() => {
                setLoading(false);
            })
    };

    const validateFields = () => {
        const validationErrors = {};
        if (!currentPassword && !passwordChecked) {
            validationErrors.currentPassword = "Current password is required.";
        }
        if (passwordChecked) {
            if (!newPassword) {
                validationErrors.newPassword = "New password is required.";
            } else if (newPassword.length < 6) {
                validationErrors.newPassword = "Password must be at least 6 characters.";
            }
            if (!confirmPassword) {
                validationErrors.confirmPassword = "Confirm password is required.";
            } else if (newPassword !== confirmPassword) {
                validationErrors.confirmPassword = "Passwords do not match.";
            }
        }
        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleCheckPassword = () => {
        if (!validateFields()) return;

        setLoading(true);
        auth.checkPassword({ password: currentPassword })
            .then((res) => {
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setPasswordChecked(true);
                setErrors({});
                setCurrentPassword('');
            }).catch((error) => {
                Swal.fire({
                    title: error.error,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }).finally(() => {
                setLoading(false);
            })
    };

    const handleChangePassword = () => {
        if (!validateFields()) return;

        setLoading(true);
        auth.changePassword({ email: authUser?.email, newPassword })
            .then((res) => {
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setPasswordChecked(false);
                setNewPassword('');
                setConfirmPassword('');
            }).catch((error) => {
                Swal.fire({
                    title: error.error,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }).finally(() => {
                setLoading(false);
            })
    };

    return (
        <Layout title="Reset Password">
            <Row className="g-gs">
                <Col sm="12" md="8" lg="6" className="mx-auto">
                    <Card className="p-4 shadow">
                        <h3 className="text-center mb-4">Reset Password</h3>
                        {!passwordChecked ? (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Current Password</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type={showCurrentPassword ? "text" : "password"}
                                            placeholder="Enter current password"
                                            value={currentPassword}
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                        />
                                        <Button
                                            variant="light"
                                            onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                                        >
                                            {showCurrentPassword ? '👁️' : '👁️‍🗨️'}
                                        </Button>
                                    </InputGroup>
                                    {errors.currentPassword && (
                                        <Alert variant="danger" className="mt-2">
                                            {errors.currentPassword}
                                        </Alert>
                                    )}
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    className="w-100"
                                    onClick={handleCheckPassword}
                                    disabled={loading}
                                >
                                    {loading ? <Spinner animation="border" size="sm" /> : 'Verify Password'}
                                </Button>
                            </>
                        ) : (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>New Password</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type={showNewPassword ? "text" : "password"}
                                            placeholder="Enter new password"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                        <Button
                                            variant="light"
                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                        >
                                            {showNewPassword ? '👁️' : '👁️‍🗨️'}
                                        </Button>
                                    </InputGroup>
                                    {errors.newPassword && (
                                        <Alert variant="danger" className="mt-2">
                                            {errors.newPassword}
                                        </Alert>
                                    )}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            type={showConfirmPassword ? "text" : "password"}
                                            placeholder="Confirm new password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                        <Button
                                            variant="light"
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        >
                                            {showConfirmPassword ? '👁️' : '👁️‍🗨️'}
                                        </Button>
                                    </InputGroup>
                                    {errors.confirmPassword && (
                                        <Alert variant="danger" className="mt-2">
                                            {errors.confirmPassword}
                                        </Alert>
                                    )}
                                </Form.Group>
                                <Button
                                    variant="success"
                                    className="w-100"
                                    onClick={handleChangePassword}
                                    disabled={loading}
                                >
                                    {loading ? <Spinner animation="border" size="sm" /> : 'Reset Password'}
                                </Button>
                            </>
                        )}
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default ResetPassword;
