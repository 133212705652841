import axios from "../plugins/axios";

const moderator = {
    createModerator(Data) {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token);
                axios
                    .post(`/admin/create-moderator`, Data)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    getAllModerators() {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token);
                axios
                    .get(`/admin/get-all-moderators`)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
};

export default moderator;