import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from "react-bootstrap";
import Layout from "../../../../layout/default";
import payment from '../../../../Model/payment';
import { Media, Icon } from "../../../../components";


const CashierDashboard = () => {
    const [cashierWallet, setCashierWallet] = useState();
    

    useEffect(() => {
        getWalletBalance();
    }, []);

    const getWalletBalance = () => {
        payment.getWalletBalanceForCashier()
            .then((res) => {
                setCashierWallet(res);
            }).catch((error) => {
                console.log("Error ");
            })
    };



    return (
        <Layout title="Cashier Dashboard">
            <Row className="g-gs">
                <Col sm="6" xxl="3">
                    <Card className="h-100 p-4 shadow-sm" style={{ borderRadius: '10px', backgroundColor: '#f8f9fa' }}>
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h5 className="text-muted">Wallet Balance</h5>
                                    <h2 className="text-success mb-1">
                                        {cashierWallet?.balance} {cashierWallet?.currency}
                                    </h2>
                                    <small className="text-muted">Last updated: {new Date(cashierWallet?.updatedAt).toLocaleString()}</small>
                                </div>
                                <Media size="sm" shape="circle" style={{ backgroundColor: '#d1ecf1' }}>
                                    <Icon name="wallet" size="lg" color="#0c5460" />
                                </Media>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
};

export default CashierDashboard;
