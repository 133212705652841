import React from 'react';
import { Card, Row, Spinner } from 'react-bootstrap'
import Layout from "../../../../layout/default";
import { Input, Select } from 'antd';


const CreateCashier = ({
    cashierData,
    handelChange,
    error,
    setIsCreateCashier,
    showMessage,
    handleCreateCashier,
    loading
}) => {
    return (
        <Layout title="Create Cashier">
            <Card>
                <Row className='p-3'>
                    <div className="container d-flex">
                        <h2 className="my-4" style={{ cursor: "pointer" }} onClick={() => { setIsCreateCashier(false); showMessage(false); }}>Cashier /  </h2>
                        <h2 className="my-4" style={{ color: "#8CBF22" }}>{"Create Cashier"}</h2>
                    </div>
                    <div className='container d-flex flex-wrap '>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="name" className="form-label">
                                <strong>First Name</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter first name of the cashierData"
                                name='firstName'
                                value={cashierData?.firstName}
                                onChange={(e) => handelChange("firstName", e)}

                            />
                            {error?.firstName && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.firstName}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="full name" className="form-label">
                                <strong>Full Name</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter Full name of the Cashier"
                                name='name'
                                value={cashierData?.name}
                                onChange={(e) => handelChange("name", e)}

                            />
                            {error?.name && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.name}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="email" className="form-label">
                                <strong>Email </strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type="email"
                                id="email"
                                placeholder="Enter email"
                                name='email'
                                value={cashierData?.email}
                                onChange={(e) => handelChange("email", e)}
                                required

                            />
                            {error?.email && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.email}
                                </span>
                            )}
                        </div>
                        {!cashierData?._id && (
                            <div className='col-12 col-md-6 px-1'>
                                <label htmlFor="Password" className="form-label">
                                    <strong>Password</strong><span className='text-danger'>*</span>
                                </label>
                                <Input.Password
                                    value={cashierData?.password}
                                    onChange={(e) => handelChange("password", e)}

                                    name="password" placeholder="input password" />

                                {error?.password && (
                                    <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                        {" "}
                                        {error?.password}
                                    </span>
                                )}
                            </div>
                        )}
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="mobileNumber" className="form-label">
                                <strong>Contact Number</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter Contact Number"
                                name='mobileNumber'
                                value={cashierData?.mobileNumber}

                                onChange={(e) => handelChange("mobileNumber", e)}
                            />
                            {error?.mobileNumber && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.mobileNumber}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="sex" className="form-label">
                                <strong>Sex</strong><span className='text-danger'>*</span>
                            </label>
                            <Select
                                className="col-12"
                                placeholder="Please select sex"
                                value={cashierData?.sex}
                                name="sex"
                                onChange={(value) => handelChange("sex", value)}
                                options={[
                                    { label: "MALE", value: "MALE" },
                                    { label: "FEMALE", value: "FEMALE" },
                                    { label: "OTHER", value: "OTHER" }
                                ]}
                            />
                            {error?.sex && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.sex}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-center px-2 py-5">
                        <button
                            onClick={() => { handleCreateCashier() }}
                            className="col-4 primary-btn "
                            style={{ marginRight: "15px", backgroundColor: '#8CBF22' }}
                        >
                            {loading ? (
                                <Spinner animation="border" size="sm" />
                            ) : (
                                "Save"
                            )}
                        </button>
                    </div>

                </Row>
            </Card>
        </Layout>
    )
}

export default CreateCashier;
