import React from 'react'
import { Card, Row, Spinner } from 'react-bootstrap'
import Layout from "../../../../layout/default"
import { Input, Select } from 'antd';


const UpdateProfile = ({ user, setIsUpdateUser, showMessage, error, handelChnage, updateUser, loading }) => {

    
    return (
        <Layout>
            <Card>
                <Row className='p-3'>
                    <div className="container d-flex">
                        <h2 className="my-4" style={{ cursor: "pointer" }} onClick={() => { setIsUpdateUser(false); showMessage(false) }}>Profile /  </h2>
                        <h2 className="my-4" style={{ color: "#8CBF22" }}>{user?._id && "Update Details"}</h2>
                    </div>
                    <div className='container d-flex flex-wrap '>

                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="full name" className="form-label">
                                <strong>Full Name</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter Full name of the User"
                                name='name'
                                value={user?.name}
                                onChange={(e) => handelChnage("name", e)}
                            />
                            {error?.name && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.name}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="email" className="form-label">
                                <strong>Email </strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type="email"
                                id="email"
                                placeholder="Enter email"
                                name='email'
                                value={user?.email}
                                onChange={(e) => handelChnage("email", e)}
                                required
                            />
                            {error?.email && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.email}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="mobileNumber" className="form-label">
                                <strong>Contact Number</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter Contact Number"
                                name='mobileNumber'
                                value={user?.mobileNumber}
                                onChange={(e) => handelChnage("mobileNumber", e)}
                            />
                            {error?.mobileNumber && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.mobileNumber}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2'>
                            <label htmlFor="medicineImage" className="form-label">
                                <strong>Profile Image</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type="file"
                                name="image"
                                accept="image/*"
                                onChange={(e) => handelChnage("image", e)}
                            />

                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="customer_address" className="form-label">
                                <strong>Address </strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type="text"
                                id="customer_address"
                                placeholder="Enter Pharmacits Address"
                                name='customer_address'
                                value={user?.customer_address}
                                onChange={(e) => handelChnage("customer_address", e)}
                                required
                            />
                            {error?.address && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.address}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="customer_country" className="form-label">
                                <strong>Country</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                id="customer_country"
                                placeholder="Enter Pharmacits Country"
                                name='customer_country'
                                value={user?.customer_country}
                                onChange={(e) => handelChnage("customer_country", e)}
                                required
                                readOnly={true}
                                disabled={true}
                            />
                            {error?.country && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.country}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="customer_city" className="form-label">
                                <strong>City</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                id="customer_city"
                                placeholder="Enter Pharmacits City"
                                name='customer_city'
                                value={user?.customer_city}
                                onChange={(e) => handelChnage("customer_city", e)}
                                required
                            />
                            {error?.city && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.city}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="customer_state" className="form-label">
                                <strong>State</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type="email"
                                id="customer_state"
                                placeholder="Enter Pharmacits State"
                                name='customer_state'
                                value={user?.customer_state}
                                onChange={(e) => handelChnage("customer_state", e)}
                                required
                                readOnly={true}
                                disabled={true}
                            />
                            {error?.state && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.state}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="customer_zip_code" className="form-label">
                                <strong>Zip Code</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type="email"
                                id="customer_zip_code"
                                placeholder="Enter Pharmacits Zip code"
                                name='customer_zip_code'
                                value={user?.customer_zip_code}
                                onChange={(e) => handelChnage("customer_zip_code", e)}
                                required
                            />
                            {error?.zipCode && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.zipCode}
                                </span>
                            )}
                        </div>

                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="sex" className="form-label">
                                <strong>Sex</strong><span className='text-danger'>*</span>
                            </label>
                            <Select
                                className="col-12"
                                placeholder="Please select sex"
                                value={user?.sex}
                                name="sex"
                                onChange={(value) => handelChnage("sex", value)}
                                options={[
                                    { label: "MALE", value: "MALE" },
                                    { label: "FEMALE", value: "FEMALE" }
                                ]}
                            />
                            {error?.sex && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.sex}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-center px-2 py-5">
                        <button
                            onClick={() => { updateUser() }}
                            className="col-4 primary-btn "
                            style={{ marginRight: "15px", backgroundColor: '#8CBF22' }}
                        >
                            {loading ? <Spinner animation="border" size="sm" /> : "Update"}
                        </button>
                    </div>
                </Row>
            </Card>
        </Layout>
    )
};

export default UpdateProfile;