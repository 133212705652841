import React, { useState, useEffect } from "react";
import Layout from "../../../layout/default";
import { Card, Row, Col } from "react-bootstrap";
import users from "../../../Model/users";
import Swal from 'sweetalert2';
import { Input, Table } from "antd";
import profile from "../../../assets/images/profile.png"
import "./Transaction.css";
import SendMoneyPage from "./SendMoneyPage";


const TransferMoney = () => {
    const [pharmacistList, setPharmacistList] = useState([]);
    const [filteredPharmacistList, setFilteredPharmacistList] = useState([]);
    const [isTransferMoney, setIsTransferMoney] = useState(false);
    const [pharmacistData, setPharmacistData] = useState({});
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        getPharmacists();
    }, []);

    const getPharmacists = () => {
        users.getAllUsers()
            .then((res) => {
                const filteredData = res.users.filter(user => user.role === "PHARMACIST");
                setPharmacistList(filteredData);
                setFilteredPharmacistList(filteredData)
            }).catch((error) => {
                Swal.fire({
                    title: error.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }).finally(() => setLoading(false));
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();

        const searchedData = pharmacistList.filter((item) => {
            return item.name.toLowerCase().includes(value) || item.email.toLowerCase().includes(value) || item.mobileNumber.includes(value);
        });

        setFilteredPharmacistList(searchedData);
    };

    const handleSelectPharmacist = (item) => {
        setPharmacistData(item);
        setIsTransferMoney(true);
    };

    const columns = [
        {
            title: "Sr No",
            key: "number",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Pharmacist",
            key: "nameImage",
            render: (text, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={record.image ? record.image : profile}
                        alt="Pharmacist"
                        style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "10px" }}
                    />
                    <span>{record.name}</span>
                </div>
            ),
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Contact Number",
            dataIndex: "mobileNumber",
            render: (text, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span>+225{record.mobileNumber}</span>
                </div>
            ),
        },
    ];



    return (
        loading ? (
            <Layout title="Loading">
                <div className="loader-container">
                    <span className="loader"></span>
                </div>
            </Layout>

        ) : (
            isTransferMoney ? (
                <SendMoneyPage
                    pharmacistData={pharmacistData}
                    setIsTransferMoney={setIsTransferMoney}
                    setPharmacistData={setPharmacistData}
                />
            ) : (
                <Layout title='Transfer Money'>
                    <Row className="mb-2">
                        <Col md={6}>
                            <Input
                                type="text"
                                className="form-control w-50"
                                placeholder="Search Pharmacist"
                                onChange={(e) => handleSearch(e)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <Card>
                                <Table
                                    className="transfer-money-table"
                                    columns={columns}
                                    dataSource={filteredPharmacistList}
                                    onRow={(record) => ({
                                        onClick: () => handleSelectPharmacist(record),
                                    })}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Layout>
            )
        )
    )
}

export default TransferMoney;
