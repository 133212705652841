import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import Layout from "../../layout/default";

const ViewPharmacy = ({ setViewPharmacy, pharmacyData, setPharmacy, defaultPharmacy }) => {
    console.log("pharmacyData : ", pharmacyData);
    
    return (
        <Layout title="View Pharmacies Data" centered>
            <Card className="shadow-sm rounded border-0">
                <Card.Header className="bg-success text-white py-3">
                    <div className="d-flex align-items-center">
                        <h5 
                            className="m-0 text-white cursor-pointer" 
                            style={{ cursor: "pointer" }}
                            onClick={() => { setPharmacy(defaultPharmacy); setViewPharmacy(false) }}
                        >
                            Pharmacies
                        </h5>
                        <span className="mx-2">/</span>
                        <h5 className="m-0 text-white">{pharmacyData?.pharmacyName}</h5>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Container>
                        <Row className="mb-4">
                            <Col xs={12} className="text-center">
                                <img
                                    alt='Pharmacy'
                                    src={pharmacyData?.pharmacyImage}
                                    className="rounded shadow-sm"
                                    style={{ maxWidth: "50%", maxHeight: "200px", objectFit: "cover" }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {/* Owner Name and Address */}
                            <Col md={6} className="mb-3">
                                <h6 className="fw-bold">Owner Name:</h6>
                                <p className="text-muted">{pharmacyData?.ownerName}</p>
                            </Col>
                            <Col md={6} className="mb-3">
                                <h6 className="fw-bold">Address:</h6>
                                <p className="text-muted">{pharmacyData?.pharmacyAddress}</p>
                            </Col>

                            {/* Contact Numbers */}
                            <Col md={6} className="mb-3">
                                <h6 className="fw-bold">Contact Number:</h6>
                                <p className="text-muted">{pharmacyData?.contactNumber}</p>
                            </Col>
                            <Col md={6} className="mb-3">
                                <h6 className="fw-bold">WhatsApp Number:</h6>
                                <p className="text-muted">{pharmacyData?.whatsAppNumber}</p>
                            </Col>

                            {/* Geographic Location */}
                            <Col xs={12} className="mb-4">
                                <h6 className="fw-bold">Geographic Location:</h6>
                                <p className="text-muted">{pharmacyData?.geoGraphicLocation}</p>
                            </Col>

                            {/* Payment Methods */}
                            <Col md={6} className="mb-4">
                                <h6 className="fw-bold">Payment Methods:</h6>
                                <ul className="list-unstyled">
                                    {pharmacyData?.paymentMethods?.map((item, index) => (
                                        <li key={index} className="d-flex align-items-center mb-2">
                                            <img
                                                alt='Payment Method'
                                                src={item?.paymentMethodImage}
                                                className="me-2 rounded-circle shadow-sm"
                                                style={{ width: "40px", height: "40px", objectFit: "cover" }}
                                            />
                                            <span className="text-muted">{item.paymentMethodName}</span>
                                        </li>
                                    ))}
                                </ul>
                            </Col>

                            {/* Insurances */}
                            <Col md={6} className="mb-4">
                                <h6 className="fw-bold">Insurances:</h6>
                                <ul className="list-unstyled">
                                    {pharmacyData?.insurances?.map((item, index) => (
                                        <li key={index} className="d-flex align-items-center mb-2">
                                            <img
                                                alt='Insurance'
                                                src={item?.assuranceImage}
                                                className="me-2 rounded-circle shadow-sm"
                                                style={{ width: "40px", height: "40px", objectFit: "cover" }}
                                            />
                                            <span className="text-muted">{item.assuranceName}</span>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </Card.Body>
            </Card>
        </Layout>
    );
};

export default ViewPharmacy;
