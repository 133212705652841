import axios from "../plugins/axios";
const auth = {
  login(data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/login", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response?.data);
        });
    });
  },
  register(data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/register-user", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response?.data);
        });
    });
  },
  logOut() {
    return new Promise((resolve, reject) => {
      localStorage.getItem("token").then((value) => {
        axios.setToken(value);
        axios
          .post("/auth/logout")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response?.data);
          });
      });
    });
  },
  updateAuthUser(data) {
    return new Promise((resolve, reject) => {
      const token = JSON.parse(localStorage.getItem("token"));
      if (token) {
        axios.setToken(token);
        axios
          .put("/auth/update-user", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response?.data);
          });
      } else {
        reject({ message: "Token not found" });
      }
    });
  },
  getAuthUser() {
    return new Promise((resolve, reject) => {
      const token = JSON.parse(localStorage.getItem("token"));
      if (token) {
        axios.setToken(token);
        axios
          .get("/auth/get-auth-user")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response?.data);
          });
      } else {
        reject({ message: "Token not found" });
      }
    });
  },
  forgotpassword(data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/forgot-password", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response?.data);
        });
    });
  },
  verifyOtp(data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/verify-otp", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response?.data);
        });
    });
  },
  changePassword(data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/change-password", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response?.data);
        });
    });
  },
  checkPassword(data) {
    return new Promise((resolve, reject) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        axios.setToken(token);
        axios
          .post("/auth/check-password", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response?.data);
          });
      } else {
        reject({ message: 'Token not found' });
      }
    });
  },
  checkUser(data) {
    return new Promise((resolve, reject) => {
      const token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        axios.setToken(token);
        axios
          .post("/auth/check-user-with-mobile", data)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response?.data);
          });
      } else {
        reject({ message: 'Token not found' });
      }
    });
  },
  async getToken() {
    return await localStorage.getItem("token");
  },
  async saveToken(token) {
    await localStorage.setItem("token", token);
  },
  async destroyToken() {
    await localStorage.removeItem("token");
  },
};

export default auth;
