import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div>
            <hr />
            <footer className="py-4 px-4 ">
                <div style={{ padding: '0px 30px 0px 30px', marginBottom: '20px' }}> <img height={'75px'} width={'180px'} src={require('../../assets/logo.png')} /></div>
                <div className="container text-center">

                    <div className="row">
                        <div className="col-md-3  d-flex flex-column  align-items-center align-items-lg-start  mb-4">

                            <ul className="list-unstyled col-8 text-start">
                                <li><h5 style={{ color: '#262F63', }}>ACCES RAPIDES</h5></li>
                                <li><Link to={"contact-us"} className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Contact-nous</Link></li>
                                <li><Link to={"privacy-policy"} className="text-dark text-decoration-none footer-link " style={{ fontSize: '12px' }}>politique de confidentialité</Link></li>
                                <li><Link to={"term-condition-pharmacies"} className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Termes et conditions pour les pharmacies</Link></li>
                                <li><Link to={"term-condition-patient"} className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Termes et conditions pour les patient</Link></li>
                                <li><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Mon compte</a></li>
                                <li ><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Programme fidelite</a></li>
                                <li><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Rappel Produits</a></li>
                                <li><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Une question sur un produit ?</a></li>

                            </ul>
                        </div>

                        <div className="col-md-3 d-flex flex-column  align-items-center align-items-lg-start mb-4">
                            <ul className="list-unstyled col-8 text-start">
                                <li><h5 style={{ color: '#262F63', }}>INFOS PRATIQUES</h5></li>
                                <li><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>FAQ</a></li>
                                <li><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Conditions generales de vente</a></li>
                                <li><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Frais et delais de livraison</a></li>
                                <li><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Retour et remboursement</a></li>
                                <li><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Traitement et protection ddos donnoos persannallas</a></li>
                                <li><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Moyens de paiement</a></li>

                            </ul>
                        </div>

                        <div className="col-md-3 d-flex flex-column  align-items-center mb-4">

                            <ul className="list-unstyled col-8 text-start">
                                <li><h5 style={{ color: '#262F63', }}>NOUS CONNAITRE</h5></li>
                                <li ><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Qui sommes-nous</a></li>
                                <li ><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Nos engagements</a></li>

                            </ul>
                        </div>

                        <div className="col-md-3 d-flex flex-column  align-items-center">

                            <ul className="list-unstyled col-8 text-start">
                                <li><h5 style={{ color: '#262F63', }}>NOS PRODUITS</h5></li>
                                <li ><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Nas pramotions</a></li>
                                <li ><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Nas Bons Plans</a></li>
                                <li ><a href="#" className="text-dark text-decoration-none footer-link" style={{ fontSize: '12px' }}>Nas Nousveautes</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column flex-lg-row justify-content-between align-items-center my-2 mx-0 mx-sm-5 mx-lg-3 py-3 px-0 px-sm-5 px-lg-4'>
                    <div className=' d-flex flex-row flex-lg-column justify-content-center align-items-center'>
                        <div style={{ fontWeight: '500', padding: '7px', fontSize: '17px', color: '#262F63' }} >Our Socials</div>
                        <div className='d-flex' >
                            <div className='px-1'><img src={require('../../assets/facebook.png')} /></div>
                            <div className='px-1'><img src={require('../../assets/twitter.png')} /></div>
                            <div className='px-1'><img src={require('../../assets/instagram.png')} /></div>
                            <div className='px-1'><img src={require('../../assets/youtube.png')} /></div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='px-1'>
                            <img src={require('../../assets/visa.png')} /></div>
                        <div className='px-1'><img src={require('../../assets/mastercard.png')} /></div>
                        <div className='px-1'><img src={require('../../assets/lock.png')} /></div >
                        <div className='px-1'>Paiement 100% sécurisé</div></div>
                </div>
            </footer>
            <div className='text-center p-2' style={{ backgroundColor: '#262F63', color: '#fff' }}>
                ©2024 PharmaConsult Dotcom - Tous droits réservés - Image non libre de droits
            </div>

        </div>

    )
}
