import React from 'react';
import { Card, Row, Col, Container, Button } from 'react-bootstrap';
import Layout from "../../../layout/default";

const ViewDrug = ({ setViewDrug, data, setData, defaultDrug }) => {
    return (
        <Layout title="View Drug Data" centered>
            <Container>
                <Card className="p-4 shadow-sm">
                    {/* Header */}
                    <div className="d-flex align-items-center mb-4">
                        <h2
                            className="me-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => { setData(defaultDrug); setViewDrug(false); }}
                        >
                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                        </h2>
                        <h2
                            className="me-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => { setData(defaultDrug); setViewDrug(false); }}
                        >
                            Drugs /
                        </h2>
                        <h2 className="text-primary me-2">{data?.commercialName}</h2>
                    </div>

                    {/* Drug Details */}
                    <Row className="align-items-center">
                        <Col md={4} className="text-center">
                            <img
                                alt="Drug"
                                src={data?.medicineImage}
                                className="rounded img-fluid shadow-sm"
                                style={{ maxWidth: "250px" }}
                            />
                        </Col>
                        <Col md={8}>
                            <Card className="border-0">
                                <Card.Body>
                                    <Row className="g-3">
                                        <Col md={6}>
                                            <div className="fw-bold text-secondary">Drug Name</div>
                                            <div className="text-muted">{data?.commercialName}</div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="fw-bold text-secondary">Active Principle</div>
                                            <div className="text-muted">{data?.activePrinciple}</div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="fw-bold text-secondary">Price</div>
                                            <div className="text-muted">{data?.price} XOF</div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="fw-bold text-secondary">Substitute Drug</div>
                                            <div className="text-muted">{data?.substituteMedicine}</div>
                                        </Col>
                                    </Row>
                                    <div className="mt-3">
                                        <div className="fw-bold text-secondary">Notice</div>
                                        <div className="text-muted">{data?.notice}</div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </Layout>
    );
}

export default ViewDrug;
