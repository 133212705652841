import React, { useEffect, useState } from 'react'
import { Card, Modal, Row, Col } from 'react-bootstrap'
import Layout from "../../../layout/default"
import pharmacist from '../../../Model/pharmacist'
import Swal from 'sweetalert2'
import drugs from '../../../Model/drugs'
import "../../admin/Admin.css"
import { useAuth } from '../../../plugins/context/AppContext';
import io from 'socket.io-client';
import '../../../App.css';


// Connect to the backend
const socket = io(process.env.REACT_APP_SOCKET, {
    path: "/socket.io",
    transports: ["websocket"]
});


const DrugRequest = () => {
    const { user } = useAuth()
    const [drugRequest, setDrugRequest] = useState()
    const [pharmacyId, setPharmacyId] = useState()
    const [requestedImg, setRequestedImg] = useState()
    const [isModal, setIsModal] = useState()
    const [drugrequestId, setDrugRequestId] = useState()
    const [respondModal, setRespondModal] = useState()
    const [availability, setAvailability] = useState()


    useEffect(() => {
        getPharmacy()
    }, []);

    useEffect(() => {
        socket.on('newDrugRequest', (data) => {
            getPharmacy();
        });

        return () => {
            socket.off('newDrugRequest');
        };
    }, []);

    const getPharmacy = () => {
        pharmacist
            .getMyPharmacyData()
            .then((response) => {
                setPharmacyId(response?._id)
                getDrugRequest(response?._id)
            })
            .catch((error) => {
                Swal.fire({
                    title: error?.error,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    };


    const respondRequest = () => {
        const data = {
            drugRequestId: drugrequestId,
            availability: availability
        }
        drugs
            .respondRequest(data)
            .then((response) => {
                setAvailability()
                setDrugRequestId()
                setRespondModal()
                getPharmacy()

                if (response.data.availability === true) {
                    socket.emit('sendNotification', response.data);
                }

                Swal.fire({
                    title: "Respond to request successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch((error) => {
                setAvailability()
                setDrugRequestId()
                setRespondModal()
                Swal.fire({
                    title: error?.error,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const getDrugRequest = (id) => {
        drugs
            .getDrugRequest(id)
            .then((response) => {
                setDrugRequest(response)
            })
            .catch((error) => {
                Swal.fire({
                    title: error?.error,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
    return (
        <>
            <Modal centered show={isModal} onHide={() => setIsModal(false)}>
                <Modal.Body>
                    <p
                        className="text-center"
                        style={{ fontSize: "23px", fontWeight: "bold" }}
                    >
                        Prescription Image
                    </p>
                    <div className='d-flex justify-content-center'>
                        <img
                            src={requestedImg}
                            alt="Prescription"
                            style={{ width: "300px", height: "auto" }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="col-12 d-flex gap-2  justify-content-end"
                        style={{ marginTop: "20px" }}>
                        <button
                            className="col-4 primary-btn"
                            onClick={() => { setIsModal(false); setRequestedImg() }}>
                            Cancel
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal centered show={respondModal} onHide={() => setRespondModal(false)}>
                <Modal.Body>
                    <p
                        className="text-center"
                        style={{ fontSize: "23px", fontWeight: "bold" }}
                    >
                        Confirm Respond
                    </p>
                    <p className='text-center'>
                        Are you sure to respond to this request
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="col-12 d-flex gap-2  justify-content-center"
                        style={{ marginTop: "20px" }}>
                        <button
                            className="col-4 primary-btn"
                            onClick={() => { setRespondModal(false); setDrugRequestId() }}>
                            Cancel
                        </button>
                        <button
                            className="col-4 mx-2 primary-btn"
                            onClick={respondRequest}>
                            Confirm
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Layout title="Drug Request">
                <Card>
                    <Row className='p-3' style={{ height: '410px' }}>
                        <div>
                            <h2>Drug Requests</h2>
                        </div>
                        <div
                            style={{
                                maxHeight: '334px',
                                overflowY: 'auto',
                                scrollbarWidth: 'none',
                                msOverflowStyle: 'none',
                            }}
                            className="hide-scrollbar"
                        >
                            {drugRequest?.some(request =>
                                request?.responses?.some(
                                    response => response?.pharmacy === pharmacyId && response?.status === "PENDING"
                                )) ? (
                                drugRequest?.map((request) => {
                                    const pharmacyResponse = request?.responses?.find(
                                        (response) => response?.pharmacy === pharmacyId && response?.status === "PENDING"
                                    );

                                    if (pharmacyResponse) {
                                        return (
                                            <Card className="mb-3 p-3" key={request?._id}>
                                                <Row className="align-items-center mb-2">
                                                    <Col md={2} className="text-center">
                                                        <img
                                                            src={request?.prescriptionImage}
                                                            onClick={() => { setRequestedImg(request?.prescriptionImage); setIsModal(true); }}
                                                            alt="Prescription"
                                                            className="rounded"
                                                            style={{
                                                                width: "120px",
                                                                height: "120px",
                                                                objectFit: "cover",
                                                                border: "2px solid #007bff",
                                                                padding: "5px",
                                                                cursor: "pointer"
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md={8}>
                                                        <h5 className="fw-bold text-dark mb-2">
                                                            {request?.medicineName}
                                                        </h5>
                                                        <div className="d-flex justify-content-between flex-wrap mb-1">
                                                            <div>
                                                                <div className="text-muted">Requested Date:</div>
                                                                <div className="fw-bold">{request?.createdAt.split('T')[0]}</div>
                                                            </div>
                                                            <div>
                                                                <div className="text-muted">Requested Time :</div>
                                                                <div className="fw-bold">{request?.requestedTime}</div>
                                                            </div>
                                                            <div>
                                                                <div className="text-muted">Patient :</div>
                                                                <div className="fw-bold">{request?.patient?.name}</div>
                                                            </div>
                                                        </div>
                                                        {user?.role === "ORDER_MANAGER" && (
                                                            <div>
                                                                <button
                                                                    onClick={() => { setAvailability(true); setDrugRequestId(request?._id); setRespondModal(true); }}
                                                                    className="cancel-btn"
                                                                >
                                                                    AVAILABLE
                                                                </button>
                                                                <button
                                                                    onClick={() => { setAvailability(false); setDrugRequestId(request?._id); setRespondModal(true); }}
                                                                    className="delete-btn mx-3"
                                                                >
                                                                    NOT AVAILABLE
                                                                </button>
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Card>
                                        );
                                    }
                                    return null;
                                })
                            ) : (
                                <div className="no-data">
                                    <div className="no-data-message">
                                        <i className="fas fa-exclamation-circle"></i>
                                        <p>Drug Requests not available</p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                        </div>
                    </Row>
                </Card>
            </Layout>
        </>
    )
}

export default DrugRequest