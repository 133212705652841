import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';
import Layout from '../../../../layout/default';
import auth from '../../../../Model/auth';
import payment from '../../../../Model/payment';
import useValidator from '../../../../plugins/useValidator';
import Swal from 'sweetalert2'
import { Icon, Media } from '../../../../components';


const TransferMoneyToPatient = () => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [failureMessage, setFailureMessage] = useState('');
    const [validationError, setValidationError] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [receiverName, setReceiverName] = useState('');
    const [validator, showMessage] = useValidator();
    const [walletBalance, setWalletBalance] = useState({});


    useEffect(() => {
        getWalletBalance();
    }, []);

    const getWalletBalance = () => {
        payment.getWalletBalanceForCashier()
            .then((res) => {
                setWalletBalance(res);
            }).catch((error) => {
                console.log("Error ");
            })
    };


    const error = {
        receiverName: validator.message(
            "Reciver Name",
            receiverName,
            "required"
        ),
        amount: validator.message(
            "Amount",
            amount,
            "required"
        ),
    };

    const validateFields = () => {
        if (mobileNumber.length !== 10 || isNaN(mobileNumber)) {
            setValidationError('Mobile number must be 10 digits.');
            return false;
        }
        if (showForm && (!amount || isNaN(amount) || Number(amount) <= 0)) {
            setValidationError('Please enter a valid amount.');
            return false;
        }
        if (showForm && !receiverName.trim()) {
            setValidationError('Please enter a valid name.');
            return false;
        }
        setValidationError('');
        return true;
    };

    const handleCheckUser = () => {
        if (!validateFields()) {
            return;
        }

        setLoading(true);
        setSuccessMessage('');
        setFailureMessage('');
        setShowForm(false);

        const data = { mobileNumber };
        auth.checkUser(data)
            .then((res) => {
                setSuccessMessage(`Patient Found: ${res.name}`);
                setReceiverName(res.name);
                setShowForm(true);
            })
            .catch((error) => {
                if (error.error === 'User Not Found') {
                    setFailureMessage('New user detected. You can proceed with the transfer.');
                    setShowForm(true);
                    setReceiverName('');
                }
            })
            .finally(() => setLoading(false));
    };

    const handleSendMoneyToPatient = () => {
        const data = {
            mobileNumber: mobileNumber,
            amount: amount,
            receiverName: receiverName,
        };

        if (validator.allValid()) {
            setLoading(true);
            payment.sendMoneyToPatient(data)
                .then((res) => {
                    setMobileNumber('');
                    setAmount('');
                    setReceiverName('');
                    setShowForm(false);
                    setSuccessMessage('');
                    setFailureMessage('');
                    getWalletBalance();
                    Swal.fire({
                        title: 'Money sent successfully!',
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
                .catch((error) => {
                    Swal.fire({
                        title: error.error,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                })
                .finally(() => setLoading(false));
        } else {
            showMessage(true);
        }
    };


    return (
        <Layout>
            <Row className="justify-content-center">
                <Col md={8}>
                    <Card>
                        <Card.Body>
                            <div className="d-flex justify-content-center align-items-center">
                                <div className='text-center'>
                                    <h5 className="text-muted">Wallet Balance</h5>
                                    <h2 className="text-success mb-1">
                                        {walletBalance?.balance} {walletBalance?.currency}
                                    </h2>
                                    <small className="text-muted">Last updated: {new Date(walletBalance?.updatedAt).toLocaleString()}</small>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={8}>
                    <Card>
                        <Card.Body>
                            <Card.Title className="text-center">Transfer Money to Patient</Card.Title>
                            <Form>
                                <Form.Group className="mb-3" controlId="formMobileNumber">
                                    <Form.Label>Enter Mobile Number</Form.Label>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <img
                                            src="https://flagcdn.com/w40/ci.png"
                                            alt="Ivory Coast Flag"
                                            style={{
                                                width: '24px',
                                                height: '24px',
                                                marginRight: '8px'
                                            }}
                                        />
                                        <span style={{ marginRight: '8px', fontWeight: 'bold' }}>+225</span>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter mobile number"
                                            value={mobileNumber}
                                            onChange={(e) => {
                                                setMobileNumber(e.target.value);
                                                setShowForm(false);
                                                setSuccessMessage('');
                                                setFailureMessage('');
                                            }}
                                            disabled={loading}
                                            style={{ flex: 1 }}
                                        />
                                    </div>
                                </Form.Group>

                                <Button
                                    variant="primary"
                                    onClick={handleCheckUser}
                                    disabled={loading || !mobileNumber}
                                >
                                    {loading ? <Spinner animation="border" size="sm" /> : 'Check Patient'}
                                </Button>
                            </Form>

                            {validationError && (
                                <Alert variant="danger" className="mt-3">
                                    {validationError}
                                </Alert>
                            )}

                            {successMessage && (
                                <Alert variant="success" className="mt-3">
                                    {successMessage}
                                </Alert>
                            )}

                            {failureMessage && (
                                <Alert variant="danger" className="mt-3">
                                    {failureMessage}
                                </Alert>
                            )}

                            {showForm && (
                                <div className="mt-4">
                                    <h5>Send Money</h5>
                                    <Form.Group className="mb-3" controlId="formAmount">
                                        <Form.Label>Enter Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter name"
                                            value={receiverName}
                                            onChange={(event) => setReceiverName(event.target.value)}
                                        />
                                        {error?.receiverName && (
                                            <span className="error" style={{ color: "red", fontSize: 11 }}>
                                                {" "}
                                                {error?.receiverName}
                                            </span>
                                        )}
                                        <br />
                                        <Form.Label>Enter Amount</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter amount"
                                            value={amount}
                                            onChange={(event) => setAmount(event.target.value)}
                                        />
                                        {error?.amount && (
                                            <span className="error" style={{ color: "red", fontSize: 11 }}>
                                                {" "}
                                                {error?.amount}
                                            </span>
                                        )}
                                    </Form.Group>
                                    <Button
                                        variant="success"
                                        onClick={handleSendMoneyToPatient}
                                        disabled={loading}
                                    >
                                        {loading ? <Spinner animation="border" size="sm" /> : 'Send Money'}
                                    </Button>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default TransferMoneyToPatient;
