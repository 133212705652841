import React, { useEffect, useState } from 'react'
import Layout from "../../../layout/default"
import { Card, Row } from 'react-bootstrap'
import commune from '../../../Model/commune'
import Swal from 'sweetalert2'
import pharmacy from '../../../Model/pharmacy'
import { Select, Table } from 'antd'
import admin from '../../../Model/admin'

const ViewPharmacies = () => {
    const [communeData, setCommuneData] = useState()
    const [pharmaciesData, setPharmaciesData] = useState()
    const [pharmacyData, setPharmacy] = useState()
    const [viewPharmacy, setViewPharmacy] = useState(false)
    const [searchData, setSearchData] = useState()

    useEffect(() => {
        getAllCommune()
        getAllPharmacies()
    }, [])

    const getAllCommune = () => {
        commune
            .getAllCommune()
            .then((response) => {
                setCommuneData(response.communes)
            })
            .catch((error) => {
                Swal.fire({
                    title: error?.error,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const getAllPharmacies = () => {
        admin
            .getAllPharmacies()
            .then((response) => {
                setPharmaciesData(response)
            })
            .catch((error) => {
                Swal.fire({
                    title: error?.error,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const getAllPharmacyByCommunId = (id)=>{
        pharmacy
        .getAllPharmacyByCommunId(id)
        .then((response)=>{
            setPharmaciesData(response.pharmacies)
        })
        .catch((error)=>{
            Swal.fire({
                title:error?.error,
                icon:"error",
                showConfirmButton:false,
                timer:1500
            })
        })
    }

    const column = [
        {
            title: "SrNo",
            key: "number",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Pharmacy Name",
            dataIndex: "pharmacyName"
            // render: (text, record) => (
            //     <div

            //         style={{ color: "blue", cursor: "pointer" }}
            //     >
            //         {text}
            //     </div>
            // ),
        },
        {
            title: "Owner Name",
            dataIndex: "ownerName",
        },
        {
            title: "Address",
            dataIndex: "pharmacyAddress",
        },
        {
            title: "Contact Number",
            dataIndex: "contactNumber",
        },
    ]
    const onSearching = (e) => {
        const { value } = e?.target;
        setSearchData(() => {
            return pharmaciesData?.filter((item) => {
                return (
                    item?.pharmacyName?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.ownerName?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.pharmacyAddress?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.contactNumber?.includes(value)||
                    item?.commune?.includes(value)
                );
            });
        });
    };
    return (
        <>
            {!viewPharmacy ? (
                <Layout title="View Pharmacies">
                    <Card>
                        <Row className='p-3'>
                            <div><h2>Pharmacies</h2></div>
                            <div className='my-2 d-flex justify-content-between'>
                                <input
                                    type="text"
                                    className="form-control w-25"
                                    placeholder="Search Pharmacy..."
                                    onChange={(e) => onSearching(e)}
                                />

                                <Select
                                    className='col-4 h-100'
                                    placeholder="Select Commune"
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(value) => getAllPharmacyByCommunId(value)}
                                    options={
                                        communeData?.map((item) => ({
                                            label: item?.communeName,
                                            value: item?._id,

                                        }))
                                    } />
                            </div>
                            <div className='mt-3'>
                                <Table columns={column} dataSource={searchData || pharmaciesData} />
                            </div>

                        </Row>
                    </Card>
                </Layout>
            ) :
                (
                    <>

                    </>
                )}
        </>

    )
}

export default ViewPharmacies