import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { Card, Modal, Row } from 'react-bootstrap';
import { Table } from 'antd';
import "../Admin.css"
import users from '../../../Model/users';
import Layout from "../../../layout/default"
import { Icon } from '@iconify/react/dist/iconify.js';
import UpdateUser from './UpdateUser';
import UserProfile from './UserProfile';
import useValidator from '../../../plugins/useValidator';

const defaultuser = {
    firstName:"",
    name:"",
    email:"",
    mobileNumber:"",
    image:""

}
const UsersDetails = () => {
    const [usersData, setUsersData] = useState()
    const [deleteId, setdeleteId] = useState()
    const [isDelete, setIsDelete] = useState()
    const [searchData, setSearchData] = useState()
    const [user, setUser] = useState(defaultuser)
    const [isUpdateUser,setIsUpdateUser]= useState(false)
    const [viewUser,setViewUser] = useState(false)
    const [validator,showMessage]=useValidator()
    useEffect(() => {
        getAllUsers();
    }, [])

    const getAllUsers = () => {
        users
            .getAllUsers()
            .then((resposne) => {
                setUsersData(resposne.users.filter(user => user.role === "PATIENT"))
            })
            .catch((error) => {
                Swal.fire({
                    title: error.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
    }

    const error ={
        firstName: validator.message("First Name", user.firstName, "required"),
        name: validator.message("Full Name", user?.name, "required"),
        email: validator.message("Email", user?.email, "required|email"),
        mobileNumber: validator.message("Contact", user?.mobileNumber, "required|phone|regex:^\\d{10}$"),
        sex: validator.message("sex", user?.sex, "required"),
    }

    const columns = [
        {
            title: "SrNo",
            key: "number",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Patient Name",
            dataIndex: "name",
            render: (text, record) => (
                <div onClick={() => {setUser(record); setViewUser(true)}} style={{ color: "blue", cursor: "pointer" }}>{text}</div>

            )
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Contact Number",
            dataIndex: "mobileNumber",
        },
        {
            title: "Actions",
            render: (text, record) => (
                <div class="dropdown">
                    <span className="dropdown-toggle" data-bs-toggle="dropdown">
                    </span>
                    <ul class="dropdown-menu" style={{ height: 'fit-content' }}>
                        <Icon name="more-v"></Icon>
                        {/* <li>
                            <p
                                class="dropdown-item d-flex align-items-center p-1 text-warning "
                                onClick={() => { setUser(record); setIsUpdateUser(true) }}
                            >
                                <Icon icon="wpf:edit" className="me-1 " />
                                <span className="col-5 ">Update</span>
                            </p>
                        </li> */}
                        <li>
                            <p
                                class="dropdown-item d-flex align-items-center p-1 text-danger "
                                onClick={() => { setIsDelete(true); setdeleteId(record._id) }}
                            >
                                <Icon icon="mingcute:delete-2-line" className="me-1 " />
                                <span className="col-5 ">Delete</span>
                            </p>
                        </li>
                    </ul>
                </div>
            ),
        },
    ]

    const deleteConfirm = () => {
        users
            .deleteUser(deleteId)
            .then((resposne) => {
                setIsDelete(false)
                setdeleteId()
                Swal.fire({
                    title: "Patient delete successfuly",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                })
                getAllUsers();
            })
            .catch((error) => {
                Swal.fire({
                    title: error.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                })

            })
    }

    const onSearching = (e) => {
        const { value } = e?.target;
        setSearchData(() => {
            return usersData?.filter((item) => {
                return (
                    item?.name?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.email?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.mobileNumber?.includes(value)
                );
            });
        });
    };

    const handelChnage = (name, e) => {
        if (name === "image") {
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result.split(",")[1];
                    setUser((prev) => ({
                        ...prev,
                        [name]: base64String,
                    }));
                };
                reader.readAsDataURL(file);
            }
        }
        else {
            setUser((prev) => ({
                ...prev,
                [name]: e.target.value
            }))
        }
    }

    const updateUser = () => {
        if(validator.allValid()){
        const data = {
            firstName: user?.firstName,
            name: user?.name,
            email: user?.email,
            mobileNumber: user?.mobileNumber,
            image: user?.image
        }
        users
            .updateUser(user?._id, data)
            .then((response) => {
                Swal.fire({
                    title: "Patient update successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                })
                getAllUsers();
                setUser(defaultuser)
                setIsUpdateUser(false)

            })
            .catch((error) => {
                Swal.fire({
                    title: error.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                })
                setUser(defaultuser)
                setIsUpdateUser(false)

            })
        }
        else{
            showMessage(true)
        }
    }
    return (
        <>
            <Modal centered show={isDelete} onHide={() => setIsDelete(false)}>
                <Modal.Body>
                    <p
                        className="text-center"
                        style={{ fontSize: "23px", fontWeight: "bold" }}>
                        Confirm delete
                    </p>
                    <p className="text-center">
                        <p className='mt-2'>Are you sure to delete this Patient</p>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="col-12 d-flex gap-2  justify-content-center"
                        style={{ marginTop: "20px" }}
                    >
                        <button
                            className="col-4 cancel-btn"
                            onClick={() => { setIsDelete(false); setdeleteId() }}>
                            Cancel
                        </button>
                        <button
                            onClick={() => deleteConfirm()}
                            className="col-4 delete-btn"
                            style={{ marginRight: "15px" }}>
                            Delete
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            {!viewUser ? (
            !isUpdateUser ?(
            <Layout title="Manage Users Data" centered>
                <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth ">
                    <Row className=" p-3">
                        <div>
                            <h2 className="my-4">Patient</h2>
                            <div className="mb-3 d-flex justify-content-between">
                                <input
                                    type="text"
                                    className="form-control w-50"
                                    placeholder="Search Users..."
                                    onChange={(e) => onSearching(e)}
                                />
                               
                            </div>

                            <div>
                                <Table  columns={columns} dataSource={searchData || usersData} />
                            </div>
                        </div>
                    </Row>
                </Card>
            </Layout>
            ):(
                <>
                <UpdateUser user={user}
                error={error}
                showMessage={showMessage}
                handelChnage={handelChnage}
                setIsUpdateUser={setIsUpdateUser}
                updateUser={updateUser} />
                </>
            )
        ) :(
            <>
            <UserProfile user={user} setViewUser={setViewUser} setUser={setUser} defaultUser={defaultuser} />
            </>
        )}
        </>

    )
}

export default UsersDetails