import React, { useEffect } from "react";
import classNames from "classnames";

// import getParents from '../../../utilities/getParents';
import slideUp from "../../../utilities/slideUp";
import slideDown from "../../../utilities/slideDown";
import getParents from "../../../utilities/getParents";

import { NavLink, Link } from "react-router-dom";
import { useAuth } from "../../../plugins/context/AppContext";

// function MenuHeading({ className, text, ...props }) {
//   const compClass = classNames({
//     "nk-menu-heading": true,
//     [className]: className,
//   });
//   return (
//     <li className={compClass}>
//       <h6 className="overline-title">{text || props.children}</h6>
//     </li>
//   );
// }

function MenuItemTemplate({ text, icon }) {
  return (
    <>
      {icon && (
        <span className="nk-menu-icon">
          <em>
            <i class={`fa fa-${icon}`} aria-hidden="true"></i>
          </em>
        </span>
      )}
      {text && <span className="nk-menu-text">{text}</span>}
    </>
  );
}

function MenuItemLink({ text, icon, sub, to, blank, onClick }) {
  return (
    <>
      {!blank && !sub && (
        <NavLink className="nk-menu-link" to={to}>
          <MenuItemTemplate icon={icon} text={text} />
        </NavLink>
      )}
      {blank && (
        <Link className="nk-menu-link" to={to} target="_blank">
          <MenuItemTemplate icon={icon} text={text} />
        </Link>
      )}
      {sub && (
        <a
          className="nk-menu-link nk-menu-toggle"
          onClick={onClick}
          href="#expand"
        >
          <MenuItemTemplate icon={icon} text={text} />
        </a>
      )}
    </>
  );
}

function MenuItem({ sub, className, ...props }) {
  const compClass = classNames({
    "nk-menu-item": true,
    "has-sub": sub,
    [className]: className,
  });
  return <li className={compClass}>{props.children}</li>;
}

function MenuSub({ mega, className, ...props }) {
  const compClass = classNames({
    "nk-menu-sub": true,
    [className]: className,
  });
  return <ul className={compClass}>{props.children}</ul>;
}

function MenuList({ className, ...props }) {

  const compClass = classNames({
    "nk-menu": true,
    [className]: className,
  });
  return <ul className={compClass}>{props.children}</ul>;
}

function Menu() {
  const { user } = useAuth();
  // variables for Sidebar
  let menu = {
    classes: {
      main: "nk-menu",
      item: "nk-menu-item",
      link: "nk-menu-link",
      toggle: "nk-menu-toggle",
      sub: "nk-menu-sub",
      subparent: "has-sub",
      active: "active",
      current: "current-page",
    },
  };

  let currentLink = function (selector) {
    let elm = document.querySelectorAll(selector);
    elm.forEach(function (item) {
      var activeRouterLink = item.classList.contains("active");
      if (activeRouterLink) {
        let parents = getParents(
          item,
          `.${menu.classes.main}`,
          menu.classes.item
        );
        parents.forEach((parentElemets) => {
          parentElemets.classList.add(
            menu.classes.active,
            menu.classes.current
          );
          let subItem = parentElemets.querySelector(`.${menu.classes.sub}`);
          subItem !== null && (subItem.style.display = "block");
        });
      } else {
        item.parentElement.classList.remove(
          menu.classes.active,
          menu.classes.current
        );
      }
    });
  };

  // dropdown toggle
  let dropdownToggle = function (elm) {
    let parent = elm.parentElement;
    let nextelm = elm.nextElementSibling;
    let speed =
      nextelm.children.length > 5 ? 400 + nextelm.children.length * 10 : 400;
    if (!parent.classList.contains(menu.classes.active)) {
      parent.classList.add(menu.classes.active);
      slideDown(nextelm, speed);
    } else {
      parent.classList.remove(menu.classes.active);
      slideUp(nextelm, speed);
    }
  };

  // dropdown close siblings
  let closeSiblings = function (elm) {
    let parent = elm.parentElement;
    let siblings = parent.parentElement.children;
    Array.from(siblings).forEach((item) => {
      if (item !== parent) {
        item.classList.remove(menu.classes.active);
        if (item.classList.contains(menu.classes.subparent)) {
          let subitem = item.querySelectorAll(`.${menu.classes.sub}`);
          subitem.forEach((child) => {
            child.parentElement.classList.remove(menu.classes.active);
            slideUp(child, 400);
          });
        }
      }
    });
  };

  let menuToggle = function (e) {
    e.preventDefault();
    let item = e.target.closest(`.${menu.classes.toggle}`);
    dropdownToggle(item);
    closeSiblings(item);
  };

  useEffect(() => {
    currentLink(`.${menu.classes.link}`);
    // eslint-disable-next-line
  }, [null]);

  return (
    <MenuList>
      {user?.token && (
        <>
          {user?.role === "ADMIN" && (
            <>
              <MenuItem sub>
                <MenuItemLink
                  icon={"th-large"}
                  text="Dashboard"
                  onClick={menuToggle}
                  sub
                />
                <MenuSub>
                  <MenuItem>
                    <MenuItemLink text="Dashboard" to="/dashboard" />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink text="Users" to="/users-details" />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink
                      text="Moderators"
                      to="/moderator-details"
                    />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink
                      text="Pharmacists"
                      to="/pharmacits-details"
                    />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink
                      text="Assurance"
                      to="/assurances"
                    />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink
                      text="Payment Method"
                      to="/payment-methods"
                    />
                  </MenuItem>
                </MenuSub>
              </MenuItem>

              <MenuItem sub>
                <MenuItemLink
                  icon={"map-marker"}
                  text="Commune"
                  onClick={menuToggle}
                  sub
                />
                <MenuSub>
                  <MenuItem>
                    <MenuItemLink text="Commune" to="/commune" />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink text="Pharmacies" to="/pharmacies" />
                  </MenuItem>
                </MenuSub>
              </MenuItem>

              <MenuItem sub>
                <MenuItemLink
                  icon={"credit-card-alt"}
                  text="Transaction"
                  onClick={menuToggle}
                  sub
                />
                <MenuSub>
                  <MenuItem>
                    <MenuItemLink text="Transaction Limit" to="/transaction-limit" />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink text="Transfer Money" to="/transfer-money" />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink text="Transaction History" to="/transaction-history" />
                  </MenuItem>
                </MenuSub>
              </MenuItem>

              <MenuItem sub>
                <MenuItemLink
                  icon={"plus-square"}
                  text="Drugs"
                  onClick={menuToggle}
                  sub
                />
                <MenuSub>
                  <MenuItem>
                    <MenuItemLink text="Create Drug" to="/drugs" />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink text="Drug Sheet" to="/drug-sheet" />
                  </MenuItem>
                </MenuSub>
              </MenuItem>
            </>
          )}

          {user?.role === "MODERATOR" && (
            <>
              <MenuItem sub>
                <MenuItemLink
                  icon={"th-large"}
                  text="Moderator Dashboard"
                  onClick={menuToggle}
                  sub
                />
                <MenuSub>
                  <MenuItem>
                    <MenuItemLink text="Dashboard" to="/dashboard" />
                  </MenuItem>
                </MenuSub>
              </MenuItem>

              <MenuItem sub>
                <MenuItemLink
                  icon={"map-marker"}
                  text="Commune"
                  onClick={menuToggle}
                  sub
                />
                <MenuSub>
                  <MenuItem>
                    <MenuItemLink text="Commune" to="/commune" />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink text="Pharmacies" to="/pharmacies" />
                  </MenuItem>
                </MenuSub>
              </MenuItem>

              <MenuItem sub>
                <MenuItemLink
                  icon={"plus-square"}
                  text="Drugs"
                  onClick={menuToggle}
                  sub
                />
                <MenuSub>
                  <MenuItem>
                    <MenuItemLink text="Create Drug" to="/drugs" />
                  </MenuItem>
                </MenuSub>
              </MenuItem>
            </>
          )}

          {user?.role === "PHARMACIST" && (
            <>
              <MenuItem>
                <MenuItemLink
                  icon={"th-large"}
                  text="Pharmacist Dashboard"
                  to="/pharmacist-dashboard"
                />
              </MenuItem>

              <MenuItem sub>
                <MenuItemLink
                  icon={"fa fa-cogs"}
                  text="Management"
                  onClick={menuToggle}
                  sub
                />
                <MenuSub>
                  <MenuItem>
                    <MenuItemLink text="Order Manager" to="/order-manager" />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink text="Cashier" to="/cashier" />
                  </MenuItem>
                </MenuSub>
              </MenuItem>

              <MenuItem sub>
                <MenuItemLink
                  icon={"fa fa-bell"}
                  text="Request"
                  onClick={menuToggle}
                  sub
                />
                <MenuSub>
                  <MenuItem>
                    <MenuItemLink text="Drug Request" to="/drug-request" />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink text="Drug History" to="/drug-history" />
                  </MenuItem>
                </MenuSub>
              </MenuItem>
            </>
          )}

          {user?.role === "ORDER_MANAGER" && (
            <>
              <MenuItem>
                <MenuItemLink
                  icon={"th-large"}
                  text="Order Manager Dashboard"
                  to="/dashboard"
                />
              </MenuItem>
              <MenuItem sub>
                <MenuItemLink
                  icon={"fa fa-bell"}
                  text="Request"
                  onClick={menuToggle}
                  sub
                />
                <MenuSub>
                  <MenuItem>
                    <MenuItemLink text="Drug Request" to="/drug-request" />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink text="Drug History" to="/drug-history" />
                  </MenuItem>
                </MenuSub>
              </MenuItem>
            </>
          )}

          {user?.role === "CASHIER" && (
            <>
              <MenuItem>
                <MenuItemLink
                  icon={"th-large"}
                  text="Cashier Dashboard"
                  to="/dashboard"
                />
              </MenuItem>
              <MenuItem sub>
                <MenuItemLink
                  icon={"coins"}
                  text="Payments"
                  onClick={menuToggle}
                  sub
                />
                <MenuSub>
                  <MenuItem>
                    <MenuItemLink text="Transfer Money" to="/transfer-money-to-patient" />
                  </MenuItem>
                  <MenuItem>
                    <MenuItemLink text="Transaction History" to="/transaction-history" />
                  </MenuItem>
                </MenuSub>
              </MenuItem>
            </>
          )}

        </>
      )}
    </MenuList>
  );
}

export default Menu;
