import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import admin from '../../Model/admin';
import { Card, Modal, Row } from 'react-bootstrap';
import "../admin/Admin.css"
import Swal from 'sweetalert2';
import commune from '../../Model/commune';
import Layout from "../../layout/default"
import useValidator from '../../plugins/useValidator';
import { useAuth } from '../../plugins/context/AppContext';


const defaultCommune = {
    communeName: ''
};


const Commune = () => {
    const [communeData, setCommuneData] = useState(defaultCommune);
    const [modal, setModal] = useState(false);
    const [allCommunes, setAllCommunes] = useState([]);
    const [filteredCommunes, setFilteredCommunes] = useState([]);
    const [deleteId, setDeleteId] = useState()
    const [isDelete, setIsDelete] = useState(false)
    const navigate = useNavigate();
    const [validtor, showMessage] = useValidator();
    const { user } = useAuth();


    useEffect(() => {
        getAllCommunes();
    }, []);

    const error = {
        name: validtor.message("Commune Name", communeData?.communeName, "required"),
    };

    const getAllCommunes = async () => {
        try {
            const communes = await commune.getAllCommune();
            setFilteredCommunes(communes.communes);
            setAllCommunes(communes.communes);
        } catch (error) {
            console.log("Error : ", error);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCommuneData(prev => ({
            ...prev,
            [name]: value
        }))
    };

    const handleSearchCommune = (event) => {
        const { value } = event.target;
        const filterddata = allCommunes.filter(item => item.communeName.toLowerCase().includes(value.toLowerCase()));
        setFilteredCommunes(filterddata)
    };

    const handleSubmit = async () => {
        if (validtor.allValid()) {
            try {
                const res = await admin.createCommune(communeData);
                getAllCommunes();
                setCommuneData(defaultCommune);
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } catch (error) {
                Swal.fire({
                    title: error.error,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            } finally {
                setModal(false);
            }
        } else {
            showMessage(true)
        }
    };

    const deleteConfirm = () => {
        commune
            .deleteCommune(deleteId)
            .then((response) => {
                setIsDelete(false)
                setDeleteId()
                getAllCommunes();
                Swal.fire({
                    title: "Commune delete successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
            })
            .catch((error) => {
                Swal.fire({
                    title: error.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            });
    };

    const updateCommune = () => {
        if (validtor.allValid()) {
            commune
                .updateCommune(communeData._id, communeData)
                .then((resposne) => {
                    Swal.fire({
                        title: "Commune update successfully",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    setModal(false)
                    setCommuneData(defaultCommune)
                    getAllCommunes();

                })
                .catch((error) => {
                    Swal.fire({
                        title: error.message,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    setModal(false)
                    setCommuneData(defaultCommune)

                })
        } else {
            showMessage(true)
        }
    }

    return (
        <>
            <Layout title="Commune Data" centered>
                <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth ">
                    <Row className=" p-3">
                        <div className="container">
                            <h2 className="my-4">Commune Management</h2>
                            <div className="mb-3 d-flex justify-content-between">
                                <input
                                    type="text"
                                    className="form-control w-50"
                                    placeholder="Search Commune..."
                                    onChange={handleSearchCommune}
                                />
                                {user?.role === "ADMIN" && (
                                    <button className="btn btn-success" onClick={() => setModal(true)}>
                                        <i className="bi bi-plus-circle"></i> Add Commune
                                    </button>
                                )}
                            </div>

                            <div className="row d-flex flex-wrap align-items-stretch">
                                {filteredCommunes?.map((item, index) => (
                                    <div
                                        key={index}
                                        className="col-sm-12 col-md-3 my-3"
                                        onClick={() => navigate(`/pharmacy/${item._id}`, { state: item?.communeName })}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div
                                            className="card h-100 shadow-sm"
                                            style={{
                                                background: 'linear-gradient(135deg,rgb(137, 225, 254) 0%, #66a6ff 100%)',
                                                // backgroundColor: '#bde0fe',
                                                borderRadius: '15px',
                                                transition: 'transform 0.2s, box-shadow 0.2s',
                                            }}
                                            onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                                            onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                                        >
                                            <div className="card-body d-flex flex-column justify-content-between text-white">
                                                <h6
                                                    className="card-title mb-3"
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '1.2rem',
                                                        letterSpacing: '0.5px',
                                                    }}
                                                >
                                                    {item?.communeName}
                                                </h6>
                                                {user?.role === "ADMIN" && (
                                                    <div className="d-flex justify-content-end">
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setModal(true);
                                                                setCommuneData(item);
                                                            }}
                                                            className="btn btn-light btn-sm py-1 me-2"
                                                            style={{
                                                                borderRadius: '50%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '35px',
                                                                height: '35px',
                                                            }}
                                                        >
                                                            <i className="fa fa-pencil" style={{ color: '#007bff' }}></i>
                                                        </button>
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setDeleteId(item._id);
                                                                setIsDelete(true);
                                                            }}
                                                            className="btn btn-light btn-sm py-1 me-2"
                                                            style={{
                                                                borderRadius: '50%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '35px',
                                                                height: '35px',
                                                            }}
                                                        >
                                                            <i className="fa fa-trash" style={{ color: '#dc3545' }}></i>
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Row>
                </Card>
            </Layout>

            <Modal centered show={isDelete} onHide={() => setIsDelete(false)}>
                <Modal.Body>
                    <p
                        className="text-center"
                        style={{ fontSize: "23px", fontWeight: "bold" }}
                    >
                        Confirm delete
                    </p>
                    <p className="text-center">

                        <p className='mt-2'>Are you sure to delete this Commune</p>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="col-12 d-flex gap-2  justify-content-center"
                        style={{ marginTop: "20px" }}
                    >
                        <button
                            className="col-4 cancel-btn"
                            onClick={() => { setIsDelete(false); setDeleteId() }}>
                            Cancel
                        </button>
                        <button
                            onClick={() => deleteConfirm()}

                            className="col-4 delete-btn"
                            style={{ marginRight: "15px" }}
                        >
                            Delete
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal centered show={modal} onHide={() => { setModal(false); setCommuneData(defaultCommune) }}>
                <Modal.Body>
                    <p
                        className="text-center"
                        style={{ fontSize: "23px", fontWeight: "bold" }}
                    >
                        {communeData?._id ? 'Update Commune' : 'Create Commune'}
                    </p>
                    <div className='container'>
                        <label htmlFor="communeName" className="form-label">
                            <strong>Commune Name</strong>
                        </label>
                        <input
                            type={"text"}
                            className="form-control"
                            id="communeName"
                            placeholder="Enter name of the Commune"
                            name='communeName'
                            value={communeData?.communeName}
                            onChange={handleChange}
                        />
                        {error?.name && (
                            <p className="error" style={{ color: "red", fontSize: 11 }}>
                                {" "}
                                {error?.name}
                            </p>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="col-12 d-flex gap-2  justify-content-center"
                        style={{ marginTop: "20px" }}
                    >
                        <button
                            className="col-4 primary-btn"
                            onClick={() => { setModal(false); setCommuneData(defaultCommune) }}>
                            Cancel
                        </button>
                        <button
                            onClick={() => { communeData?._id ? updateCommune() : handleSubmit() }}
                            className="col-4 primary-btn"
                            style={{ marginRight: "15px" }}
                        >
                            {communeData?._id ? 'Update' : 'Create'}
                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </>
    )
}

export default Commune;
