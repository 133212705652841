import axios from "../plugins/axios";

const admin = {
    createCommune(data) {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token); 
                axios
                    .post('/admin/create-commune', data)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    getAllPharmacies() {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token); 
                axios
                    .get('/admin/get-all-pharmacies-admin')
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    getAllDrugSheets() {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token); 
                axios
                    .get('/admin/get-drug-sheet')
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
}

export default admin;