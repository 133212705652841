import React, { useState, useEffect } from "react";
import Layout from "../../../layout/default";
import { Card, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import payment from "../../../Model/payment";
import useValidator from "../../../plugins/useValidator";
import Swal from "sweetalert2";


const defaultTransactionData = {
    minAmountToSend: "",
    maxAmountToSend: "",
    minWalletAmountPatient: "",
    minWalletAmountPharmacy: "",
    maxWalletAmountPatient: "",
    maxWalletAmountPharmacy: "",
};

const TransactionLimits = () => {
    const [transactionData, setTransactionData] = useState(defaultTransactionData);
    const [isEdit, setIsEdit] = useState(false);
    const [validator, showMessage] = useValidator();
    const [loading, setLoading] = useState(false);


    const error = {
        minAmountToSend: validator.message(
            "Minimum Amount To Send",
            transactionData?.minAmountToSend,
            "required"
        ),
        maxAmountToSend: validator.message(
            "Maximum Amount To Send",
            transactionData?.maxAmountToSend,
            "required"
        ),
        minWalletAmountPatient: validator.message(
            "Minimum Wallet Amount of Patient",
            transactionData?.minWalletAmountPatient,
            "required"
        ),
        minWalletAmountPharmacy: validator.message(
            "Minimum Wallet Amount of Pharmacy",
            transactionData?.minWalletAmountPharmacy,
            "required"
        ),
        maxWalletAmountPatient: validator.message(
            "Maximium Wallet Amount of Patient",
            transactionData?.maxWalletAmountPatient,
            "required"
        ),
        maxWalletAmountPharmacy: validator.message(
            "Maximium Wallet Amount of Pharmacy",
            transactionData?.maxWalletAmountPharmacy,
            "required"
        )
    };


    useEffect(() => {
        payment.getTransactionLimits()
            .then((res) => {
                if (res._id) {
                    console.log("Got it ");
                    setTransactionData(res);
                    setIsEdit(true);
                }
            })
            .catch((error) => {
                console.error("Error fetching transaction limits:", error);
            });
    }, []);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTransactionData({ ...transactionData, [name]: value });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        if (isEdit) {
            if (validator.allValid()) {
                setLoading(true);
                payment.updateTransactionLimits(transactionData._id, transactionData)
                    .then((res) => {
                        Swal.fire({
                            title: res.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .catch((error) => {
                        Swal.fire({
                            title: error.error,
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    })
                    .finally(() => setLoading(false))
            } else {
                showMessage(true);
            }
        } else {
            if (validator.allValid()) {
                payment.createTransactionLimits(transactionData)
                    .then((res) => {
                        Swal.fire({
                            title: res.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        setIsEdit(true);
                    })
                    .catch((error) => {
                        Swal.fire({
                            title: error.error,
                            icon: "error",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    });
            } else {
                showMessage(true);
            }
        }
    };


    return (
        <Layout title="Transaction Limits">
            <Row className="g-gs">
                <Col className="mx-auto">
                    <Card>
                        <Card.Body>
                            <h2>Manage Transaction Limits</h2>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Min Amount to Send</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="minAmountToSend"
                                                value={transactionData.minAmountToSend}
                                                onChange={handleInputChange}
                                                min="0"
                                            />
                                            {error?.minAmountToSend && (
                                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                                    {" "}
                                                    {error?.minAmountToSend}
                                                </p>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Max Amount to Send</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="maxAmountToSend"
                                                value={transactionData.maxAmountToSend}
                                                onChange={handleInputChange}
                                                min={0}
                                            />
                                            {error?.maxAmountToSend && (
                                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                                    {" "}
                                                    {error?.maxAmountToSend}
                                                </p>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Min Wallet Amount (Patient)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="minWalletAmountPatient"
                                                value={transactionData.minWalletAmountPatient}
                                                onChange={handleInputChange}
                                            />
                                            {error?.minWalletAmountPatient && (
                                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                                    {" "}
                                                    {error?.minWalletAmountPatient}
                                                </p>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Min Wallet Amount (Pharmacy)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="minWalletAmountPharmacy"
                                                value={transactionData.minWalletAmountPharmacy}
                                                onChange={handleInputChange}
                                            />
                                            {error?.minWalletAmountPharmacy && (
                                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                                    {" "}
                                                    {error?.minWalletAmountPharmacy}
                                                </p>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Max Wallet Amount (Patient)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="maxWalletAmountPatient"
                                                value={transactionData.maxWalletAmountPatient}
                                                onChange={handleInputChange}
                                            />
                                            {error?.maxWalletAmountPatient && (
                                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                                    {" "}
                                                    {error?.maxWalletAmountPatient}
                                                </p>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Max Wallet Amount (Pharmacy)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="maxWalletAmountPharmacy"
                                                value={transactionData.maxWalletAmountPharmacy}
                                                onChange={handleInputChange}
                                            />
                                            {error?.maxWalletAmountPharmacy && (
                                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                                    {" "}
                                                    {error?.maxWalletAmountPharmacy}
                                                </p>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="d-flex justify-content-center">
                                    <Col sm={5}>
                                        <Button variant="primary" type="submit" style={{ width: '100%' }}>
                                            {
                                                loading ? (
                                                    <Spinner animation="border" size="sm" />
                                                ) : (
                                                    isEdit ? "Update Limits" : "Create Limits"
                                                )
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default TransactionLimits;
