import React, { useEffect, useState } from 'react';
import Layout from "../../../layout/default";
import { Card, Row } from 'react-bootstrap';
import { Table } from 'antd';
import { Icon } from '@iconify/react/dist/iconify.js';
import CreateModerator from './CreateModerator';
import moderator from '../../../Model/moderator';
import Swal from 'sweetalert2'
import useValidator from '../../../plugins/useValidator';
import profile from "../../../assets/images/profile.png"
import UserProfile from '../Users/UserProfile';


const defaultUser = {
    firstName: '',
    name: '',
    email: '',
    mobileNumber: '',
    countryCode: {
        code: 'CI',
        dial_code: '+225',
        flag: '🇨🇮',
    },
    sex: '',
    password: '',
    fcmToken: ''
};


const ModeratorList = () => {
    const [moderatorList, setModeratorList] = useState([]);
    const [filteredModeratorList, setFilteredModeratorList] = useState([]);
    const [user, setUser] = useState(defaultUser);
    const [viewUser, setViewUser] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [deleteId, setdeleteId] = useState('');
    const [isCreateModerator, setIsCreateModerator] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validator, showMessage] = useValidator();

    useEffect(() => {
        getModerators();
    }, []);

    const error = {
        firstName: validator.message("First Name", user.firstName, "required"),
        name: validator.message("Full Name", user?.name, "required"),
        email: validator.message("Email", user?.email, "required|email"),
        mobileNumber: validator.message("Contact", user?.mobileNumber, "required|phone|regex:^\\d{10}$"),
        password: validator.message("Password", user?.password, "required|min:6"),
        sex: validator.message("sex", user?.sex, "required"),
    };

    const getModerators = () => {
        moderator.getAllModerators()
            .then((res) => {
                setFilteredModeratorList(res);
                setModeratorList(res);
            }).catch((error) => {
                console.log("Error : ", error);
            })
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();

        const filteredData = moderatorList?.filter((item) => {
            return item.name.toLowerCase().includes(value) ||
                item.email.toLowerCase().includes(value) ||
                item.mobileNumber.includes(value)
        });

        setFilteredModeratorList(filteredData);
    };

    const handleChange = (name, event) => {
        if (name === "sex") {
            setUser((prev) => ({
                ...prev,
                [name]: event
            }))
        } else {
            setUser((prev) => ({
                ...prev,
                [name]: event.target.value
            }))
        }
    };

    const handleCreateModerator = (data) => {
        if (validator.allValid()) {
            setLoading(true);
            moderator.createModerator(data)
                .then((res) => {
                    setIsCreateModerator(false);
                    setUser(defaultUser);
                    getModerators();
                    showMessage(false);
                    Swal.fire({
                        title: res.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }).catch((error) => {
                    Swal.fire({
                        title: error.error,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }).finally(() => setLoading(false))
        } else {
            showMessage(true);
        }
    };

    const columns = [
        {
            title: "SrNo",
            key: "number",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Moderator",
            dataIndex: "name",
            render: (text, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={record.image ? record.image : profile}
                        alt="Pharmacist"
                        style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "10px" }}
                    />
                    <span
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={() => { setUser(record); setViewUser(true) }}
                    >
                        {record.name}
                    </span>
                </div>
            )
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Contact Number",
            dataIndex: "mobileNumber",
            render: (text, record) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span>+225{record.mobileNumber}</span>
                </div>
            )
        },
        {
            title: "Actions",
            render: (text, record) => (
                <div class="dropdown">
                    <span className="dropdown-toggle" data-bs-toggle="dropdown">
                    </span>
                    <ul class="dropdown-menu" style={{ height: 'fit-content' }}>
                        <Icon name="more-v"></Icon>
                        <li>
                            <p
                                class="dropdown-item d-flex align-items-center p-1 text-danger "
                                onClick={() => { setIsDelete(true); setdeleteId(record._id) }}
                            >
                                <Icon icon="mingcute:delete-2-line" className="me-1 " />
                                <span className="col-5 ">Delete</span>
                            </p>
                        </li>
                    </ul>
                </div>
            ),
        },
    ];


    return (
        viewUser ? (
            <UserProfile user={user} setViewUser={setViewUser} setUser={setUser} defaultUser={defaultUser} />
        ) :
            isCreateModerator ? (
                <CreateModerator
                    user={user}
                    setIsCreateModerator={setIsCreateModerator}
                    handleChange={handleChange}
                    error={error}
                    handleCreateModerator={handleCreateModerator}
                    loading={loading}
                    defaultUser={defaultUser}
                    setUser={setUser}
                    showMessage={showMessage}
                />
            ) : (
                <Layout title="Moderator List">
                    <Card>
                        <Row className='px-3'>
                            <h2 className="my-4">Moderators</h2 >
                            <div className="mb-3 d-flex justify-content-between">
                                <input
                                    type="text"
                                    className="form-control w-50"
                                    placeholder="Search Moderator..."
                                    onChange={(event) => handleSearch(event)}
                                />
                                <button className="btn btn-success" onClick={() => setIsCreateModerator(true)}>
                                    <i className="bi bi-plus-circle"></i> Add Moderator
                                </button>
                            </div>
                        </Row >
                        <Row className='px-3'>
                            <Table columns={columns} dataSource={filteredModeratorList} />
                        </Row>
                    </Card >
                </Layout >
            )
    )
};

export default ModeratorList;
