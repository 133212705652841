import React, { useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import auth from "../../Model/auth";
import Swal from "sweetalert2";
import Layout from "../../layout/fullpage";
import logo from "../../assets/logo.png";

let obj = {
  newPassword: "",
  ConfirmPassword: "",
};
const ChangePassword = () => {
  const { state } = useLocation();
  const [data, setData] = useState(obj);
  const navigate = useNavigate();

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangePassword = () => {
    const sendData = {
      email: state.email,
      newPassword: data.newPassword,
    };

    if (!sendData.newPassword && !data.ConfirmPassword) {
      Swal.fire({
        title: "Please provide  Password and Confirm Password",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    if (sendData.newPassword !== data.ConfirmPassword) {
      Swal.fire({
        title: "Password and Confirm Password should be same",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    if (sendData.newPassword.length < 6) {
      Swal.fire({
        title: "Password must be at least 6 characters long",
        icon: "error",
        showConfirmButton: false,
        timer: 3500,
      });
      return;
    }

    auth
      .changePassword(sendData)
      .then((res) => {
        Swal.fire({
          title: "Password change successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/auths/auth-login");
      })
      .catch((err) => {
        Swal.fire({
          title: "Error while updating password",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  return (
    <Layout title="Chnage Password">
      <div className="container p-2 p-sm-4">
        <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
          <Row className="g-0 flex-lg-row-reverse">
            <Col lg="5" className="shadow rounded ">
              <Card.Body className="h-100 d-flex flex-column justify-content-center">
                <div className="nk-block-head text-center">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title mb-2">Change Password</h3>
                  </div>
                </div>
                <div>
                  <Col>
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">New Password</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="newPassword"
                          name="newPassword"
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter New Password"
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email">Confirm Password</Form.Label>
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="confirmPassword"
                          name="ConfirmPassword"
                          onChange={(e) => handleChange(e)}
                          placeholder="Enter Confirm Password"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 mt-2">
                    <Button
                      className="col-12"
                      onClick={() => onChangePassword()}
                    >
                      Change Password
                    </Button>
                  </Col>
                </div>
                <div className="text-center mt-4">
                  <p className="small">
                    <Link to="/auths/auth-login">Back to Login</Link>
                  </p>
                </div>
              </Card.Body>
            </Col>
            <Col lg="7">
              <Card.Body className="is-theme has-mask has-mask-1 h-100 d-flex flex-column align-items-center   ">
                <div className="brand-logo">
                  <img
                    src={logo}
                    alt="logo"
                    width={"300px"}
                    className="mt-3 "
                  />
                </div>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </div>
    </Layout>
  );
};

export default ChangePassword;
