import React from 'react'
import Layout from "../../../layout/default"
import { Card, Row } from 'react-bootstrap'
import { Input, Select } from 'antd'
const CreatePharmacits = ({ user, handelChnage, error, setIsCreatePharmacist, showMessage, registerUser, updateUser, setUser, defaultUser, isPharmacySelected, setIsPharmacySelected, pharmacyId, pharmacyData, ownerName,setPharmacyId }) => {
    return (
        <Layout title="Create Pharmacist">
            <Card>
                <Row className='p-3'>
                    <div className="container d-flex">
                        <h2 className="my-4" style={{ cursor: "pointer" }} onClick={() => { setIsCreatePharmacist(false); showMessage(false); setUser(defaultUser); setIsPharmacySelected(false);setPharmacyId() }}>Pharmacists /  </h2>
                        <h2 className="my-4" style={{ color: "#8CBF22" }}>{user?._id ? "Update Pharmacist" : "Create Pharmacist"}</h2>
                    </div>
                    <div className='container d-flex flex-wrap '>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="Pharmacy" className="form-label">
                                <strong>Pharmacy</strong><span className='text-danger'>*</span>
                            </label>
                            <Select
                                className="col-12"
                                placeholder="Please select Pharmacy"
                                value={pharmacyId || ""}
                                name="pharmacyId"
                                onChange={(value) => handelChnage("pharmacyId", value)}
                                options={pharmacyData?.map((i) => ({
                                    label: i?.pharmacyName,
                                    value: i?._id,
                                }))}
                                showSearch
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                }
                            />

                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="ownerName" className="form-label">
                                <strong>Owner Name</strong>
                            </label>
                            <Input
                                placeholder="Owner Name"
                                name='ownerName'
                                value={ownerName}
                                disabled={!isPharmacySelected}
                                readOnly
                            />

                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="name" className="form-label">
                                <strong>First Name</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter first name of the user"
                                name='firstName'
                                value={user?.firstName}
                                onChange={(e) => handelChnage("firstName", e)}
                                disabled={!isPharmacySelected}
                            />
                            {error?.firstName && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.firstName}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="full name" className="form-label">
                                <strong>Full Name</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter Full name of the User"
                                name='name'
                                value={user?.name}
                                onChange={(e) => handelChnage("name", e)}
                                disabled={!isPharmacySelected}
                            />
                            {error?.name && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.name}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="email" className="form-label">
                                <strong>Email </strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type="email"
                                id="email"
                                placeholder="Enter email"
                                name='email'
                                value={user?.email}
                                onChange={(e) => handelChnage("email", e)}
                                required
                                disabled={!isPharmacySelected}
                            />
                            {error?.email && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.email}
                                </span>
                            )}
                        </div>
                        {!user?._id && (
                            <div className='col-12 col-md-6 px-1'>
                                <label htmlFor="Password" className="form-label">
                                    <strong>Password</strong><span className='text-danger'>*</span>
                                </label>
                                <Input.Password
                                    value={user?.password}
                                    onChange={(e) => handelChnage("password", e)}
                                    disabled={!isPharmacySelected}
                                    name="password" placeholder="input password" />

                                {error?.password && (
                                    <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                        {" "}
                                        {error?.password}
                                    </span>
                                )}
                            </div>
                        )}
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="mobileNumber" className="form-label">
                                <strong>Contact Number</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter Contact Number"
                                name='mobileNumber'
                                value={user?.mobileNumber}
                                disabled={!isPharmacySelected}
                                onChange={(e) => handelChnage("mobileNumber", e)}
                            />
                            {error?.mobileNumber && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.mobileNumber}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="sex" className="form-label">
                                <strong>Sex</strong><span className='text-danger'>*</span>
                            </label>
                            <Select
                                className="col-12"
                                disabled={!isPharmacySelected}
                                placeholder="Please select sex"
                                value={user?.sex} // This ensures the selected value is displayed
                                name="sex"
                                onChange={(value) => handelChnage("sex", value)} // Pass the selected value directly
                                options={[
                                    { label: "MALE", value: "MALE" },
                                    { label: "FEMALE", value: "FEMALE" },
                                    { label: "OTHER", value: "OTHER" }
                                ]}
                            />
                            {error?.sex && (
                                <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                    {" "}
                                    {error?.sex}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-center px-2 py-5">
                        <button
                            onClick={() => { user?._id ? updateUser() : registerUser() }}
                            className="col-4 primary-btn "
                            style={{ marginRight: "15px", backgroundColor: '#8CBF22' }}
                        >
                            {user?._id ? "Update" : "Save"}
                        </button>
                    </div>
                </Row>
            </Card>

        </Layout>
    )
}

export default CreatePharmacits