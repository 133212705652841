import React, { useEffect, useState } from 'react'
import { Card, Modal, Row } from 'react-bootstrap'
import Layout from "../../../../layout/default"
import useValidator from '../../../../plugins/useValidator'
import pharmacist from '../../../../Model/pharmacist'
import Swal from 'sweetalert2'
import { Table } from 'antd'
import { Icon } from '@iconify/react/dist/iconify.js'
import CreateOrdermanagers from './CreateOrdermanagers'

const defaultData = {
    firstName: '',
    name: '',
    email: '',
    mobileNumber: '',
    countryCode: {
        code: 'CI',
        dial_code: '+225',
        flag: '🇨🇮',
    },
    sex: '',
    password: '',
};


const OrderManagersDetails = () => {
    const [validator, showMessage] = useValidator()
    const [orderManager, setOrderManager] = useState(defaultData)
    const [orderManagerData, setOrderManagerData] = useState()
    const [isCreateOrderManager, setIsCreateOrderManager] = useState(false)
    const [isDelete, setIsDelete] = useState()
    const [deleteId, setDeleteId] = useState()

    const handelChnage = (name, e) => {
        if (name === "sex") {
            setOrderManager((prev) => ({
                ...prev,
                [name]: e
            }))
        }
        else {
            setOrderManager((prev) => ({
                ...prev,
                [name]: e.target.value
            }))
        }
    };

    const error = {
        firstName: validator.message("First Name", orderManager.firstName, "required"),
        name: validator.message("Full Name", orderManager?.name, "required"),
        email: validator.message("Email", orderManager?.email, "required|email"),
        mobileNumber: validator.message("Contact", orderManager?.mobileNumber, "required|phone|min:10|max:10"),
        password: validator.message("Password", orderManager?.password, "required"),
        sex: validator.message("sex", orderManager?.sex, "required"),
    }

    useEffect(() => {
        getOrderManager()
    }, [])

    const createOrdermanager = () => {
        if (validator.allValid()) {
            pharmacist
                .createOrderManager(orderManager, orderManagerData?._id)
                .then((response) => {
                    Swal.fire({
                        title: 'Order Manager create successfully',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    getOrderManager()
                    setIsCreateOrderManager(false)
                    setOrderManager(defaultData)
                })
                .catch((error) => {
                    Swal.fire({
                        title: error.error,
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500,
                    })
                })
        }
        else {
            showMessage(true)
        }
    }

    const getOrderManager = () => {
        pharmacist
            .getMyPharmacyData()
            .then((response) => {
                setOrderManagerData(response)
            })
            .catch((error) => {
                Swal.fire({
                    title: error.error,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
    }

    const columns = [
        {
            title: "SrNo",
            key: "number",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Order Manager Name",
            dataIndex: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Contact Number",
            dataIndex: "mobileNumber",
        },
        // {
        //     title: "Actions",
        //     render: (text, record) => (
        //         <div class="dropdown">
        //             <span className="dropdown-toggle" data-bs-toggle="dropdown">
        //             </span>
        //             <ul class="dropdown-menu" style={{ height: 'fit-content' }}>
        //                 <Icon name="more-v"></Icon>
        //                 <li>
        //                     <p
        //                         class="dropdown-item d-flex align-items-center p-1 text-danger "
        //                         onClick={() => { setDeleteId(record?._id); setIsDelete(true) }}
        //                     >
        //                         <Icon icon="mingcute:delete-2-line" className="me-1 " />
        //                         <span className="col-5 ">Delete</span>
        //                     </p>
        //                 </li>
        //             </ul>
        //         </div>
        //     ),
        // },
    ]

    const deleteConfirm = () => {
        pharmacist
            .deleteOrderManager(deleteId)
            .then((response) => {
                Swal.fire({
                    title: "Order Manager Delete Successfully",
                    timer: 1500,
                    showConfirmButton: false,
                    icon: "success"
                })
                setDeleteId()
                setIsDelete(false)
            })
            .catch((error) => {
                Swal.fire({
                    title: error?.error,
                    timer: 1500,
                    icon: "error",
                    showConfirmButton: false,
                })
            })
    }

    return (
        <>
            <Modal centered show={isDelete} onHide={() => setIsDelete(false)}>
                <Modal.Body>
                    <p
                        className="text-center"
                        style={{ fontSize: "23px", fontWeight: "bold" }}>
                        Confirm delete
                    </p>
                    <p className="text-center">
                        <p className='mt-2'>Are you sure to delete this Order Manager</p>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="col-12 d-flex gap-2  justify-content-center"
                        style={{ marginTop: "20px" }}
                    >
                        <button
                            className="col-4 cancel-btn"
                            onClick={() => { setIsDelete(false); setDeleteId() }}>
                            Cancel
                        </button>
                        <button
                            onClick={() => deleteConfirm()}
                            className="col-4 delete-btn"
                            style={{ marginRight: "15px" }}>
                            Delete
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            {!isCreateOrderManager ? (
                <Layout title="Order Manager">
                    <Card>
                        <Row className='p-3'>
                            <div className='d-flex justify-content-between'>
                                <div><h2 className="">Order Manager</h2></div>
                                {!orderManagerData?.orderManager && (
                                    <button onClick={() => setIsCreateOrderManager(true)} className="btn btn-success" >
                                        <i className="bi bi-plus-circle"></i> Add Order Manager
                                    </button>
                                )}
                            </div>

                            <div className='mt-3'>
                                <Table columns={columns} dataSource={orderManagerData?.orderManager ? [orderManagerData.orderManager] : []} />
                            </div>
                        </Row>
                    </Card>
                </Layout>
            ) : (
                <>
                    <CreateOrdermanagers
                        orderManager={orderManager}
                        handelChnage={handelChnage}
                        error={error}
                        setIsCreateOrderManager={setIsCreateOrderManager}
                        createOrdermanager={createOrdermanager}
                        showMessage={showMessage}
                    />
                </>
            )}
        </>
    )
}

export default OrderManagersDetails