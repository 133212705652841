import React, { useEffect, useState } from 'react';
import Layout from "../../../layout/default";
import { Card, Row } from 'react-bootstrap';
import { Table } from 'antd';
import admin from '../../../Model/admin';


const DrugSheet = () => {
    const [drugSheetList, setDrugSheetList] = useState([]);
    const [filterDrugSheetList, setfilterDrugSheetList] = useState([]);
    

    useEffect(() => {
        getAllDrugSheets();
    }, []);

    const getAllDrugSheets = () => {
        admin.getAllDrugSheets()
            .then((res) => {
                setDrugSheetList(res);
                setfilterDrugSheetList(res);
            }).catch((error) => {
                console.log("error : ", error);
            })
    };

    const columns = [
        {
            title: "Sr No",
            key: "number",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Drug Name",
            dataIndex: "drugName",
        },
        {
            title: "Created By",
            dataIndex: "createdBy",
            render: (text) => (
                <p>{text.name}</p>
            ),
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            render: (text) => (
                <p>{text.split('T')[0]}</p>
            ),
        },
        {
            title: "Prescription Image",
            dataIndex: "prescriptionImage",
            render: (url) => (
                <img
                    src={url}
                    alt="prescriptionImage"
                    style={{ width: "65px", height: "65px", borderRadius: "5px", objectFit: "cover" }}
                />
            ),
        },
    ];


    return (
        <Layout title="Manage Users Data" centered>
            <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth ">
                <Row className="p-3">
                    <div>
                        <h2 className="my-4">Drug Sheets</h2>

                        <div>
                            <Table columns={columns} dataSource={filterDrugSheetList} />
                        </div>
                    </div>
                </Row>
            </Card>

        </Layout>
    )
};

export default DrugSheet;
