import React, { useContext, useState } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import Layout from "../../layout/fullpage";
import { Icon } from "../../components";
import Swal from "sweetalert2";
import auth from "../../Model/auth";
import logo from "../../assets/logo.png";
import AuthContext from "../../plugins/context/AppContext";

let obj = {
  email: "",
  password: "",
};
const AuthLoginPage = () => {
  const [loginData, setLoginData] = useState(obj);
  const [eyeVisible, setEyeVisible] = useState(false);
  const naviagte = useNavigate();
  const {setUser} = useContext(AuthContext)

  //   -------To take inputs from the fields
  const handleChange = (e) => {
    const { name, value } = e?.target;

    setLoginData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // To handle visibility of the password
  const handleVisibiltyPassword = () => setEyeVisible((prev) => !prev);

  //   ----To hit api to login
  const onLogin = (e) => {
    if (loginData.email === "" && loginData.password === "") {
      Swal.fire({
        title: "Email and Password filed is required",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    e?.preventDefault();
    auth
      .login(loginData)
      .then((res) => {
        setUser(res)
        localStorage.setItem("user", JSON.stringify(res));
        localStorage.setItem("token", JSON.stringify(res.token));
        console.log("login", res);
        Swal.fire({
          title: "Logged In Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        naviagte("/dashboard");
      })
      .catch((err) => {
        Swal.fire({
          title: err.error,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };
  return (
    <>
      <Layout title="Login" centered>
        <div className="container p-2 p-sm-4">
          <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
            <Row className="g-0 flex-lg-row-reverse flex-column-reverse  ">
              <Col lg="5" className="shadow rounded ">
                <Card.Body className="h-100 d-flex flex-column justify-content-center">
                  <div className="nk-block-head text-center">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title mb-1">Login to Account</h3>
                      <p className="small">
                        Please sign-in to your account and start the adventure.
                      </p>
                    </div>
                  </div>
                  <Form method="post">
                    <Row className="gy-3">
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="email">Email</Form.Label>
                          <div className="form-control-wrap">
                            <Form.Control
                              type="email"
                              id="email"
                              placeholder="Enter email"
                              value={loginData?.email}
                              name="email"
                              onChange={(e) => handleChange(e)}
                              required
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="password">Password</Form.Label>
                          <div className="form-control-wrap">
                            {!eyeVisible ? (
                              <div
                                className="position-absolute"
                                style={{
                                  top: "30%",
                                  right: "2%",
                                  cursor: "pointer",
                                }}
                                onClick={handleVisibiltyPassword}
                              >
                                <Icon name="eye-off" />
                              </div>
                            ) : (
                              <div
                                className="position-absolute"
                                style={{
                                  top: "30%",
                                  right: "2%",
                                  cursor: "pointer",
                                }}
                                onClick={handleVisibiltyPassword}
                              >
                                <Icon name="eye" />
                              </div>
                            )}
                            <Form.Control
                              type={eyeVisible ? "text" : "password"}
                              id="password"
                              placeholder="Enter password"
                              value={loginData?.password}
                              name="password"
                              onChange={(e) => handleChange(e)}
                              required
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <div className="d-flex flex-wrap justify-content-between">
                          {/* <Form.Check
                            className="form-check-sm"
                            type="checkbox"
                            id="rememberMe"
                            label="Remember Me"
                          /> */}
                          <Link to="/auths/auth-reset" className="small">
                            Forgot Password?
                          </Link>
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="d-grid">
                          <Button type="submit" onClick={(e) => onLogin(e)}>
                            Login to account
                          </Button>
                        </div>
                      </Col>
                      {/* <Col className="col-12">
                        <div>
                            Don't have an account <span className="text-primary" onClick={()=> naviagte("/auths/register")} style={{cursor:"pointer"}}>Register ?</span>
                        </div>
                      </Col> */}
                    </Row>
                  </Form>
                
                </Card.Body>
              </Col>
              <Col lg="7">
                <Card.Body className="is-theme has-mask has-mask-1 h-100 d-flex flex-column align-items-center   ">
                  <div className="brand-logo">
                    {/* <Logo /> */}
                    <img
                      src={logo}
                      alt="logo"
                      width={"300px"}
                      className="mt-3 "
                    />
                  </div>
                  <div className="row">
                    <div className="col-sm-11">
                      <div className="mt-4">
                        <div className="h1 text-center text-black title mb-3">
                          Welcome back to  Pharmaconsults
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </div>
      </Layout>
    </>
  );
};

export default AuthLoginPage;
