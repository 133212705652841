import React, { useState } from "react";
import { Card, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Layout from "../../../../layout/default";
import payment from "../../../../Model/payment";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";


const RechargeWallet = () => {
    const [amount, setAmount] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    const handleRecharge = () => {
        if (!amount || isNaN(amount) || parseFloat(amount) <= 0) {
            setError("Please enter a valid amount.");
            return;
        }

        setLoading(true);
        setError("");

        payment
            .getPaymentLink({ amount: parseFloat(amount) })
            .then((res) => {
                const { payment_url } = res.data;
                if (payment_url) {
                    window.location.href = payment_url;
                } else {
                    setError("Failed to generate payment link. Please try again.");
                }
            })
            .catch((error) => {
                setError("Error occurred while generating payment link.");
                Swal.fire({
                    title: error.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return (
        <Layout>
            <Card>
                <Row className="p-3">
                    <Col md={6} className="mx-auto">
                        <div className="d-flex align-items-center position-relative mb-2">
                            <span
                                onClick={() => navigate("/dashboard")}
                                style={{ position: "absolute", right: 0, cursor: "pointer" }}
                            >
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </span>
                            <div className="mx-auto fw-bold text-center" style={{ fontSize: "20px" }}>
                                Recharge Wallet
                            </div>
                        </div>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form>
                            <Form.Group controlId="formAmount">
                                <Form.Label>Enter Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    disabled={loading}
                                />
                            </Form.Group>
                            <Button
                                variant="primary"
                                className="mt-3"
                                onClick={handleRecharge}
                                disabled={loading}
                            >
                                {loading ? "Processing..." : "Generate Payment Link"}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Card>
        </Layout>
    );
};

export default RechargeWallet;
