import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import pharmacy from "../../Model/pharmacy";
import { Table } from "antd";
import { Card, Modal, Row } from "react-bootstrap";
import "../admin/Admin.css";
import useValidator from "../../plugins/useValidator";
import Layout from "../../layout/default";
import Swal from "sweetalert2";
import { Icon } from "@iconify/react/dist/iconify.js";
import CreatePharmacy from "./CreatePharmacy";
import ViewPharmacy from "./ViewPharmacy";
import { useAuth } from "../../plugins/context/AppContext";


const defaultPharmacy = {
    pharmacyName: "",
    ownerName: "",
    pharmacyImage: "",
    pharmacyAddress: "",
    geoGraphicLocation: "",
    contactNumber: "",
    whatsAppNumber: "",
    startDate: "",
    endDate: "",
    paymentMethods: [],
    insurances: [],
};


const PharmacyPage = () => {
    const navigate = useNavigate()
    const state = useLocation(0);
    const { communeId } = useParams();
    const [isDelete, setIsDelete] = useState(false);
    const [deleteId, setdeleteId] = useState();
    const [pharmacyData, setPharmacy] = useState(defaultPharmacy);
    const [allPharmayData, setAllPharmayData] = useState([]);
    const [validator, showMessage] = useValidator();
    const [searchData, setSearchData] = useState();
    const [isCreatePharmacy, setIsCreatePharmacy] = useState(false);
    const [viewPharmacy, setViewPharmacy] = useState(false);
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();
    console.log("user : ", user);


    const handleChange = (value, field) => {
        if (field === "startDate" || field === "endDate") {
            const formattedDate = value ? value.format("YYYY-MM-DD") : null;
            setPharmacy((prev) => ({
                ...prev,
                [field]: formattedDate,
            }));
        } else if (field === "paymentMethods") {
            setPharmacy((prev) => ({
                ...prev,
                paymentMethods: value,
            }));
        } else if (field === "insurances") {
            setPharmacy((prev) => ({
                ...prev,
                insurances: value,
            }));
        } else if (field === "pharmacyImage") {
            const file = value.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result.split(",")[1];
                    setPharmacy((prev) => ({
                        ...prev,
                        [field]: base64String,
                    }));
                };
                reader.readAsDataURL(file);
            }
        } else {
            setPharmacy((prev) => ({
                ...prev,
                [field]:
                    (field === "whatsAppNumber" || field === "contactNumber")
                        ? `+225${value.target.value.replace(/^\+225/, '')}`
                        : value.target.value,
            }));

        }
    };

    useEffect(() => {
        getPharmacy();
    }, []);

    const getPharmacy = () => {
        pharmacy
            .getAllPharmacyByCommunId(communeId)
            .then((response) => {
                setAllPharmayData(response.pharmacies);
            })
            .catch((error) => {
                console.log("Error : ", error);
            });
    };

    const error = {
        pharmacyName: validator.message(
            "Pharmacy Name",
            pharmacyData?.pharmacyName,
            "required"
        ),
        ownerName: validator.message(
            "Owner Name",
            pharmacyData?.ownerName,
            "required"
        ),
        pharmacyAddress: validator.message(
            "Address",
            pharmacyData?.pharmacyAddress,
            "required"
        ),
        geoGraphicLocation: validator.message(
            "Geo Graphic Location",
            pharmacyData?.geoGraphicLocation,
            "required"
        ),
        contactNumber: validator.message(
            "Contact Number",
            pharmacyData?.contactNumber?.replace(/^\+225/, ''),
            "required|phone|regex:^\\d{10}$"
        ),
        image: validator.message(
            "Pharmacy Image",
            pharmacyData?.pharmacyImage,
            "required"
        ),
        whatsAppNumber: validator.message(
            "WhatsApp Number",
            pharmacyData?.whatsAppNumber?.replace(/^\+225/, ''),
            "required|phone|regex:^\\d{10}$"
        ),
        startDate: validator.message(
            "Start Date",
            pharmacyData?.startDate,
            "required"
        ),
        endDate: validator.message("End Date", pharmacyData?.endDate, "required"),
        paymentMethods: validator.message(
            "Payment Method",
            pharmacyData?.paymentMethods,
            "required"
        ),
        insurances: validator.message(
            "Insurances",
            pharmacyData?.insurances,
            "required"
        ),
    };

    const savePharmacy = () => {
        if (validator.allValid()) {
            setLoading(true);
            pharmacy
                .savePharmacy(pharmacyData, communeId)
                .then((response) => {
                    Swal.fire({
                        title: "Pharmacy save successfully",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    getPharmacy();
                    setIsCreatePharmacy(false);
                    setPharmacy(defaultPharmacy);
                })
                .catch((error) => {
                    Swal.fire({
                        title: error.error,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    setIsCreatePharmacy(false);
                })
                .finally(() => setLoading(false))
        } else {
            showMessage(true);
        }
    };

    const onSearching = (e) => {
        const { value } = e?.target;
        setSearchData(() => {
            return allPharmayData?.filter((item) => {
                return (
                    item?.pharmacyName?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.ownerName?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.pharmacyAddress?.toLowerCase().includes(value?.toLowerCase()) ||
                    item?.contactNumber?.includes(value)
                );
            });
        });
    };

    const deleteConfirm = () => {
        pharmacy
            .deletePharmacy(deleteId)
            .then((response) => {
                Swal.fire({
                    title: "Pharmacy delete successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });

                getPharmacy();
                setdeleteId();
                setIsDelete(false);
            })
            .catch((error) => {
                Swal.fire({
                    title: error.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            });
    };

    const updatePharmacy = () => {
        if (validator.allValid()) {
            setLoading(true);
            pharmacy
                .updatePharmacy(pharmacyData?._id, pharmacyData)
                .then((response) => {
                    Swal.fire({
                        title: "Pharmacy update successfully",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    setIsCreatePharmacy(false);
                    setPharmacy(defaultPharmacy);
                    getPharmacy();
                })
                .catch((error) => {
                    Swal.fire({
                        title: error.message,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    setIsCreatePharmacy(false);
                    setPharmacy(defaultPharmacy);
                })
                .finally(() => setLoading(false))
        } else {
            showMessage(true);
        }
    };


    const columns = [
        {
            title: "SrNo",
            key: "number",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Pharmacy Name",
            dataIndex: "pharmacyName",
            render: (text, record) => (
                <div
                    onClick={() => {
                        setPharmacy(record);
                        setViewPharmacy(true);
                    }}
                    style={{ color: "blue", cursor: "pointer" }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: "Owner Name",
            dataIndex: "ownerName",
        },
        {
            title: "Address",
            dataIndex: "pharmacyAddress",
        },
        {
            title: "Contact Number",
            dataIndex: "contactNumber",
        },
        {
            title: "Actions",
            render: (text, record) => (
                <div className="dropdown">
                    <i
                        className="fa fa-ellipsis-v"
                        data-bs-toggle="dropdown"
                        aria-hidden="true"
                        style={{ cursor: "pointer", marginRight: 5 }}
                    ></i>
                    <ul className="dropdown-menu" style={{ height: "fit-content" }}>
                        <li>
                            <p
                                className="dropdown-item d-flex align-items-center p-1 text-warning "
                                onClick={() => {
                                    setPharmacy(record);
                                    setIsCreatePharmacy(true);
                                }}
                            >
                                <Icon icon="wpf:edit" className="me-1 " />
                                <span className="col-5 ">Update</span>
                            </p>
                        </li>
                        {user?.role === "ADMIN" && (
                            <li>
                                <p
                                    className="dropdown-item d-flex align-items-center p-1 text-danger "
                                    onClick={() => {
                                        setIsDelete(true);
                                        setdeleteId(record._id);
                                    }}
                                >
                                    <Icon icon="mingcute:delete-2-line" className="me-1 " />
                                    <span className="col-5 ">Delete</span>
                                </p>
                            </li>
                        )}
                    </ul>
                </div>
            ),
        },
    ];


    return (
        <>
            <Modal centered show={isDelete} onHide={() => setIsDelete(false)}>
                <Modal.Body>
                    <p
                        className="text-center"
                        style={{ fontSize: "23px", fontWeight: "bold" }}
                    >
                        Confirm delete
                    </p>
                    <p className="text-center">
                        <p className="mt-2">Are you sure to delete this Pharmacy</p>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="col-12 d-flex gap-2  justify-content-center"
                        style={{ marginTop: "20px" }}
                    >
                        <button
                            className="col-4 cancel-btn"
                            onClick={() => {
                                setIsDelete(false);
                                setdeleteId();
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => deleteConfirm()}
                            className="col-4 delete-btn "
                            style={{ marginRight: "15px" }}
                        >
                            Delete
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            {!viewPharmacy ? (
                !isCreatePharmacy ? (
                    <Layout title="Manage Pharmacies Data" centered>
                        <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth ">
                            <Row className=" p-3">
                                <div className="container">
                                    <h2 className="my-4">
                                        <span onClick={() => navigate(-1)} style={{ marginRight: "5px", cursor: "pointer" }}>
                                            <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                        </span>
                                        {" "}
                                        {state.state}
                                    </h2>
                                    <div className="mb-3 d-flex justify-content-between">
                                        <input
                                            type="text"
                                            className="form-control w-50"
                                            placeholder="Search Pharmacy..."
                                            onChange={(e) => onSearching(e)}
                                        />
                                        <button
                                            className="btn btn-success"
                                            onClick={() => setIsCreatePharmacy(true)}
                                        >
                                            <i className="bi bi-plus-circle"></i> Add Pharmacy
                                        </button>
                                    </div>
                                    <div>
                                        <Table columns={columns} dataSource={searchData || allPharmayData} />
                                    </div>
                                </div>
                            </Row>
                        </Card>
                    </Layout>
                ) : (
                    <CreatePharmacy
                        setIsCreatePharmacy={setIsCreatePharmacy}
                        pharmacyData={pharmacyData}
                        setPharmacy={setPharmacy}
                        updatePharmacy={updatePharmacy}
                        defaultPharmacy={defaultPharmacy}
                        savePharmacy={savePharmacy}
                        error={error}
                        handleChange={handleChange}
                        showMessage={showMessage}
                        loading={loading}
                    />
                )
            ) : (
                <>
                    <ViewPharmacy
                        setViewPharmacy={setViewPharmacy}
                        pharmacyData={pharmacyData}
                        setPharmacy={setPharmacy}
                        defaultPharmacy={defaultPharmacy}
                    />
                </>
            )}
        </>
    );
};

export default PharmacyPage;
