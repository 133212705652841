import React from 'react';

// import NavBar from '../component/NavBar'

import "../../../components/NavBar.css"
import Form from 'react-bootstrap/Form';

import InputGroup from 'react-bootstrap/InputGroup';
import { FaHeart } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import CarousulComponent from '../../../components/CarousulBanner/CarousulComponent';

export default function LandingPage() {


    return (
        <div className='col-sm-12'>

        <CarousulComponent />
        <div className=' container d-flex justify-content-between py-2 px-4' style={{ marginTop: '15px' }}>
            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>MOST PURCHASED PRODUCTS</div>
            <div><a href="#">View More</a></div>
        </div>
        <div className='container'>
            <div className='d-flex flex-column align-items-center flex-md-row py-2 px-4  justify-content-sm-center justify-content-lg-between'>
                {
                    [1, 2, 3, 4, 5].map((item, index) => (
                        <div key={index} class="card text-center align-items-center justify-content-center shadow-sm responsive-width " style={{ marginBottom: '10px', width: '200px' }}>
                            <div className='d-flex justify-content-between w-100 , ' style={{ padding: 5 }}>
                                <div style={{ fontSize: '10px', backgroundColor: '#F9595F', textAlign: 'center', padding: 3, borderRadius: 3 }}>-10% off</div>
                                <div><FaHeart className='fav' style={{ fontSize: '20px', color: '#F9595F' }} /></div>
                            </div>
                            <img src={require('../../../assets/cardimg.png')} class="card-img" alt="..." style={{ width: '130px', height: '110px' }} />
                            <div class="card-body">
                                <h5 class="card-title">Avène</h5>
                                <p class="card-text" style={{ fontSize: '10px', fontWeight: '500' }}>CICALFATE+ - Crème Réparatrice Protectrice - Visage et Corps - Peaux Sensibles Irritées, 40ml</p>
                                <p class="card-text" style={{ fontSize: '10px', fontWeight: 'bold' }}>7,49 €  <i

                                    className={`bi bi-star-fill fs-7 text-warning `}
                                /><i

                                        className={`bi bi-star-fill fs-7 text-warning m-1`}
                                    /><i

                                        className={`bi bi-star-fill fs-7 text-warning `}
                                    /><i

                                        className={`bi bi-star-fill fs-7 text-warning m-1`}
                                    /><i

                                        className={`bi bi-star-fill fs-7 text-warning `}
                                    />(21)</p>
                                <a href="#" class="btn w-100 addbtn" style={{ backgroundColor: '#94C11F', borderRadius: '20px', color: '#fff' }}>Add to Cart</a>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>

        <div className='container'>
            <div className=' d-flex justify-content-between py-2 px-4' style={{ marginTop: '15px' }}>
                <div style={{ fontSize: '20px', fontWeight: 'bold' }}>GOOD DEALS</div>
                <div><a href="#">View More</a></div>
            </div>

            <div className='row px-4 g-0'>
                <div className='col-6 p-1'>
                    <img src={require('../../../assets/5900784.png')} width={'50%'} class="card-img" alt="..." />
                </div>
                <div className='col-6 p-1'>
                    <img src={require('../../../assets/9549650.png')} width={'50%'} class="card-img" alt="..." />
                </div>
                <div className='col-6 p-1'>
                    <img src={require('../../../assets/1026499.png')} width={'50%'} class="card-img" alt="..." />
                </div>
                <div className='col-6 p-1'>
                    <img src={require('../../../assets/9954293.png')} width={'50%'} class="card-img" alt="..." />
                </div>

            </div>
        </div>

        <div className='container'>
            <div className='d-flex justify-content-between py-2 px-4' style={{ marginTop: '15px' }}>
                <div style={{ fontSize: '20px', fontWeight: 'bold' }}>Articles or Blogs</div>
                <div><a href="#">View More</a></div>
            </div>
            <div className='d-flex flex-column align-items-center flex-md-row py-2 px-4  justify-content-sm-center justify-content-lg-between'>
                {
                    [1, 2, 3, 4].map((item, index) => (
                        <div key={index} class="card text-center shadow-sm responsive-width1" style={{ marginBottom: '10px' }}>
                            <img src={require(index == 0 || index == 2 ? '../../../assets/successful-medical-team.png' : '../../../assets/collection-medicine-bottles-including-one-that-sayssyringe.png')} class="card-img" alt="..." />
                            <div class="card-body">
                                <h5 class="card-title text-start">Blog title</h5>
                                <p class="card-text text-start" style={{ fontSize: '11px' }}>Lorem ipsum dolor sit amet, consectetur adipisci elit, sed do eiusmod.....</p>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div>
                                        <p class="card-text" style={{ fontSize: '11px', textAlign: 'center' }}>10 Nov 2025</p>
                                    </div>
                                    <a href="#" class="btn addbtn " style={{ backgroundColor: '#94C11F', borderRadius: '10px', color: '#fff', paddingLeft: '20px', paddingRight: '20px', paddingTop: '2px' }}>Read</a>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>

        <div className='d-flex flex-column flex-lg-row justify-content-evenly  mt-5 pb-5  py-3 px-5 shadow-sm ' style={{ borderTop: '1px solid #E5E4E2', borderBottom: '1px solid #E5E4E2' }} >
            <div className='text-center'>
                <div className='py-2'><img src={require('../../../assets/Group1000003446.png')} alt="..." /></div>
                <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>PRODUITS AUTHENTIQUES</div>
                <div style={{ fontSize: '11px' }}>En Qualite Pharmaceutique</div>
            </div>
            <div className='text-center'>
                <div className='py-2'><img src={require('../../../assets/Group1000003447.png')} alt="..." /></div>
                <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>LIVRAISON RAPIDE</div>
                <div style={{ fontSize: '11px' }}>24h a Abidjan et dans les regions</div>
            </div>
            <div className='text-center'>
                <div className='py-2'><img src={require('../../../assets/Group1000003448.png')} alt="..." /></div>
                <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>PAIEMENT AU CHOIX</div>
                <div style={{ fontSize: '11px' }}>Cash Mobile Money - Carte bancaire</div>
            </div>
            <div className='text-center'>
                <div className='py-2'><img src={require('../../../assets/Group1000003449.png')} alt="..." /></div>
                <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>RETOURS SIMPLES & RAPIDES</div>
                <div style={{ fontSize: '11px' }}>Pour les produits endommages ou non confrme</div>
            </div>
        </div>
        <div style={{ backgroundColor: '#ebedef' }}>
            <div className='container'>
                <div className='text-center px-2 '>
                    <div className='pt-5'>
                        <p style={{ color: '#262F63', fontSize: '28px', fontWeight: '700' }}>Articles or Blogs</p>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <p className='w-sm-70 w-lg-50 col-12 col-sm-10 col-lg-6' style={{ fontSize: '16px' }}>
                            PharmaConsults vous permet de bénéficier de 7 services essentiels pour simplifier vos démarches de santé au quotidien
                        </p>
                    </div>
                </div>
                <div >
                    <div className='d-flex flex-column flex-lg-row bg-grey py-sm-0 py-lg-3 px-4 gap-2'>
                        <div className='text-center  bg-white p-4 ' style={{ borderRadius: '10px', flex: 1 }}>
                            <div className='py-2'><img src={require('../../../assets/Group2.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Pharmacies de garde par commune</div>
                            <div style={{ fontSize: '11px' }}>Trouvez en un clin d’œil une pharmacie ouverte près de chez vous, où que vous soyez.</div>
                        </div>
                        <div className='text-center  bg-white p-4 ' style={{ borderRadius: '10px', flex: 1 }}>
                            <div className='py-2'><img src={require('../../../assets/Group3.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Recherche de médicaments</div>
                            <div style={{ fontSize: '11px' }}>Vous avez une ordonnance ? Envoyez une requête pour vérifier la disponibilité de vos médicaments dans toutes les pharmacies de Côte d’Ivoire et réservez en un clic.</div>
                        </div>
                        <div className='text-center  bg-white p-4 ' style={{ borderRadius: '10px', flex: 1 }}>
                            <div className='py-2'><img src={require('../../../assets/Group4.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Assurances acceptées</div>
                            <div style={{ fontSize: '11px' }}>Plus besoin de vous déplacer inutilement. Vérifiez à l'avance quelles pharmacies acceptent votre assurance santé</div>
                        </div>
                        <div className='text-center  bg-white p-4 ' style={{ borderRadius: '10px', flex: 1 }}>
                            <div className='py-2'><img src={require('../../../assets/Group5.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Portefeuille électronique</div>
                            <div style={{ fontSize: '11px' }}>Fini les soucis de petite monnaie ! Recevez votre reste de monnaie directement sur votre téléphone et utilisez-le dans n’importe quelle pharmacie partenaire.</div>
                        </div>
                    </div>

                    <div className='d-flex flex-column flex-lg-row justify-content-center   py-sm-0 py-lg-3 px-4  '>
                        <div className='text-center col-sm-12 col-lg-3 bg-white p-4 my-2 my-lg-0 ' style={{ borderRadius: '10px' }}>
                            <div className='py-2'><img style={{ backgroundColor: '#94C11F', padding: 12, borderRadius: '50px' }} src={require('../../../assets/conversation1.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Fiche et prix des médicaments </div>
                            <div style={{ fontSize: '11px' }}>Consultez les prix et les fiches complètes de vos médicaments avant de vous rendre en pharmacie.</div>
                        </div>
                        <div className='text-center col-sm-12 col-lg-3 bg-white p-4 mx-sm-0 mx-lg-2 my-2 my-lg-0 ' style={{ borderRadius: '10px' }}>
                            <div className='py-2'><img src={require('../../../assets/Group1.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Vaccination</div>
                            <div style={{ fontSize: '11px' }}>Ne manquez plus aucun vaccin ! Suivez vos calendriers vaccinaux, activez des rappels automatiques et consultez les prix des vaccins en quelques clics</div>
                        </div>
                        <div className='text-center col-sm-12 col-lg-3 bg-white p-4 my-2 my-lg-0 ' style={{ borderRadius: '10px' }}>
                            <div className='py-2'><img src={require('../../../assets/Group6.png')} alt="..." /></div>
                            <div style={{ color: '#262F63', fontSize: '12px', fontWeight: 'bold' }}>Marketplace de produits cosmétiques </div>
                            <div style={{ fontSize: '11px' }}>Prenez soin de vous avec notre boutique en ligne dédiée aux produits cosmétiques et de parapharmacie.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        {/* <div className='my-5  py-3 px-5 mx-0 mx-lg-5' style={{ backgroundColor: '#ebedef' }}>
            <div class="card mb-3 border-0 mx-0 mx-lg-4" style={{ backgroundColor: '#ebedef' }} >
                <div class="row g-0">
                    <div class="col-md-8">
                        <div class="card-body" style={{ fontSize: '14px' }}>
                            <p class="card-text">PharmaConsults vous permet de bénéficier de 7 services essentiels pour simplifier vos démarches de santé au quotidien :
                            </p>
                            <p>
                                <strong>⦁	 Pharmacies de garde par commune :</strong> Trouvez en un clin d’œil une pharmacie ouverte près de chez vous, où que vous soyez.
                            </p>
                            <p>
                                <strong>⦁	 Recherche de médicaments :</strong> Vous avez une ordonnance ? Envoyez une requête pour vérifier la disponibilité de vos médicaments dans toutes les pharmacies de Côte d’Ivoire et réservez en un clic.
                            </p>
                            <p>
                                <strong>⦁	Assurances acceptées :</strong> Plus besoin de vous déplacer inutilement. Vérifiez à l'avance quelles pharmacies acceptent votre assurance santé.
                            </p>
                            <p>
                                <strong>⦁	Portefeuille électronique :</strong> Fini les soucis de petite monnaie ! Recevez votre reste de monnaie directement sur votre téléphone et utilisez-le dans n’importe quelle pharmacie partenaire
                            </p>
                            <p>
                                <strong>⦁	Fiche et prix des médicaments :</strong> Consultez les prix et les fiches complètes de vos médicaments avant de vous rendre en pharmacie.
                            </p>
                            <p>
                                <strong>⦁	Marketplace de produits cosmétiques :</strong> Prenez soin de vous avec notre boutique en ligne dédiée aux produits cosmétiques et de parapharmacie.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 py-3">
                        <img src={require('../../../assets/Group1000003453.png')} class="img-fluid rounded-start" alt="..." />
                    </div>
                </div>
            </div>
        </div> */}
        <div className=' container '>
            <div className='d-flex justify-content-between align-items-center row my-2 mx-2 mx-lg-4 py-3 px-2 ' style={{ backgroundColor: '#262F63' }}>
                <div className='text-white fs-2 col-sm-12 col-lg-4 ps-5'>Subscribe to the NEWSLETTER</div>
                <div className='text-white col-sm-12 col-lg-4 ps-5 ps-lg-0 '>and enjoy more boticinal tips and offers</div>
                <div className='col-sm-12 col-lg-4 px-5 px-lg-0 py-3 py-lg-0'> <InputGroup className="text-white">
                    <Form.Control
                        placeholder="Enter your email..."
                        aria-label="Enter your email..."
                        aria-describedby="basic-addon2"
                    />
                    <Button variant="outline-secondary" id="button-addon2" style={{ backgroundColor: '#94C11F', }}>
                        Subscribe
                    </Button>
                </InputGroup>
                </div>
            </div>
        </div>
        <div className='' style={{ backgroundColor: '#FFFFFF' }}>
            <p className='text-center px-5'><span style={{ color: "#262F63", fontWeight: 'bold' }}>Service client :</span> 0225 0708592565 / 2722252547 du lundi au vendredi de 08h30 à 18h30 et le samedi de 09h à 17h  </p>
            <p className='text-center'><span style={{ color: "#262F63", fontWeight: 'bold' }}>Mail :</span> serviceclient@pharma-consults.com </p>
        </div>



    </div >


    )
}
