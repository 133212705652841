import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import Layout from "../../../layout/default";
import { Card, Modal, Row } from 'react-bootstrap';
import { Input, Table } from 'antd';
import paymentMethod from '../../../Model/paymentMethod';
import useValidator from '../../../plugins/useValidator';


const defaultPaymentMethodData = {
    paymentMethodName: '',
    paymentMethodImage: '',
    status: true
};


const PaymentMethodList = () => {
    const [paymentMethodData, setPaymentMethodData] = useState(defaultPaymentMethodData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [deletePaymentMethodId, setdeletePaymentMethodId] = useState(false);
    const [paymentMethodList, setPaymentMethodList] = useState([]);
    const [filteredPaymentMethods, setFilteredPaymentMethods] = useState([]);
    const [validator, showMessage] = useValidator();

    const error = {
        paymentMethodName: validator.message(
            "Payment Method Name",
            paymentMethodData?.paymentMethodName,
            "required"
        ),
        paymentMethodImage: validator.message(
            "Payment Method Image",
            paymentMethodData?.paymentMethodImage,
            "required"
        ),
    };


    useEffect(() => {
        getAllPaymentMethods();
    }, []);

    const getAllPaymentMethods = () => {
        paymentMethod.getAllPaymentMethods()
            .then((res) => {
                setPaymentMethodList(res);
                setFilteredPaymentMethods(res);
            }).catch((error) => {
                console.log("Error in get payment methods : ", error);
            })
    };

    const handleSearch = (value) => {
        const newValue = value.toLowerCase();
        const filteredData = paymentMethodList.filter(item => item.paymentMethodName.toLowerCase().includes(newValue));
        setFilteredPaymentMethods(filteredData);
    };

    const handleChange = (value, field) => {
        if (field === "paymentMethodImage") {
            const file = value.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result.split(",")[1];
                    setPaymentMethodData((prev) => ({
                        ...prev,
                        [field]: base64String,
                    }));
                };
                reader.readAsDataURL(file);
            }
        } else {
            setPaymentMethodData((prev) => ({
                ...prev,
                [field]: value
            }))
        }
    };

    const handleCreatePaymentMethod = () => {
        if (validator.allValid()) {
            paymentMethod.createPaymentMethod(paymentMethodData)
                .then((res) => {
                    getAllPaymentMethods();
                }).catch((error) => {
                    console.log("Error in create payment method : ", error);
                }).finally(() => {
                    setIsModalOpen(false);
                })
        } else {
            showMessage(true);
        }
    };

    const handleDeletePaymentMethod = (id) => {
        paymentMethod.deletePaymentMethod(id)
            .then((res) => {
                getAllPaymentMethods();
            }).catch((error) => {
                console.log("Error in delete payment method : ", error);
            }).finally(() => {
                setIsDeleteModal(false);
            })
    };


    const columns = [
        {
            title: "Sr No",
            key: "number",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Payment Method Name",
            dataIndex: "paymentMethodName",
        },
        {
            title: "Payment Method Image",
            dataIndex: "paymentMethodImage",
            render: (url) => (
                <img
                    src={url}
                    alt="paymentMethod"
                    style={{ width: "65px", height: "65px", borderRadius: "5px", objectFit: "cover" }}
                />
            ),
        },
        {
            title: "Actions",
            render: (text, record) => (
                <div class="dropdown">
                    <span className="dropdown-toggle" data-bs-toggle="dropdown">
                    </span>
                    <ul class="dropdown-menu" style={{ height: 'fit-content' }}>
                        <Icon name="more-v"></Icon>
                        <li>
                            <p
                                class="dropdown-item d-flex align-items-center p-1 text-danger "
                                onClick={() => {
                                    setdeletePaymentMethodId(record._id);
                                    setIsDeleteModal(true);
                                }}
                            >
                                <Icon icon="mingcute:delete-2-line" className="me-1 " />
                                <span className="col-5 ">Delete</span>
                            </p>
                        </li>
                    </ul>
                </div>
            ),
        },
    ];

    return (
        <Layout title="Manage Users Data" centered>
            <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth ">
                <Row className=" p-3">
                    <div>
                        <h2 className="my-4">Payment Methods</h2>
                        <div className="mb-3 d-flex justify-content-between">
                            <input
                                type="text"
                                className="form-control w-50"
                                placeholder="Search Payment Methods..."
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            <button className="btn btn-success"
                                onClick={() => setIsModalOpen(true)}
                            >
                                <i className="bi bi-plus-circle"></i> Create Payment Method
                            </button>
                        </div>

                        <div>
                            <Table columns={columns} dataSource={filteredPaymentMethods} />
                        </div>
                    </div>
                </Row>
            </Card>

            <Modal centered show={isModalOpen} onHide={() => setIsModalOpen(false)}            >
                <Modal.Body>
                    <p
                        className="text-center"
                        style={{ fontSize: "23px", fontWeight: "bold" }}>
                        Create Payment Method
                    </p>
                    <div className='container d-flex flex-wrap '>
                        <div className='col-12 px-1'>
                            <label htmlFor="name" className="form-label">
                                <strong>Payment Method Name</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter name of Payment Method"
                                name='assuranceName'
                                value={paymentMethodData?.paymentMethodName || ''}
                                onChange={(e) => handleChange(e.target.value, "paymentMethodName",)}
                            />
                            {error?.paymentMethodName && (
                                <span className="error" style={{ color: "red", fontSize: 11 }}>
                                    {" "}
                                    {error?.paymentMethodName}
                                </span>
                            )}
                        </div>
                        <div className='col-12 px-1'>
                            <label htmlFor="name" className="form-label">
                                <strong>Assurance Image</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type="file"
                                name="paymentMethodImage"
                                accept="image/*"
                                onChange={(e) => handleChange(e, "paymentMethodImage")}
                            />
                            {error?.paymentMethodImage && (
                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                    {" "}
                                    {error?.paymentMethodImage}
                                </p>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="col-12 d-flex gap-2  justify-content-center"
                        style={{ marginTop: "20px" }}
                    >
                        <button
                            className="col-4 delete-btn"
                            onClick={() => {
                                setIsModalOpen(false);
                                setPaymentMethodData(defaultPaymentMethodData);
                                showMessage(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => handleCreatePaymentMethod()}
                            className="col-4  cancel-btn"
                            style={{ marginRight: "15px" }}>
                            Create
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal centered show={isDeleteModal} onHide={() => setIsDeleteModal(false)}            >
                <Modal.Body>
                    <p
                        className="text-center"
                        style={{ fontSize: "23px", fontWeight: "bold" }}>
                        Delete Payment Method
                    </p>
                    <div className='container d-flex flex-wrap '>
                        <div className='col-12 px-1'>
                            <h6>Do you want to delete Payment Method</h6>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        className="col-12 d-flex gap-2  justify-content-center"
                        style={{ marginTop: "20px" }}
                    >
                        <button
                            className="col-4 cancel-btn"
                            onClick={() => { setIsDeleteModal(false) }}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => handleDeletePaymentMethod(deletePaymentMethodId)}
                            className="col-4 delete-btn"
                            style={{ marginRight: "15px" }}>
                            Delete
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </Layout>
    )
}

export default PaymentMethodList;
