import axios from "../plugins/axios";

const payment = {
    getMyWalletAmount() {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token);
                axios
                    .get(`/wallet`)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    getWalletBalanceForCashier() {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token);
                axios
                    .get(`/wallet/get-wallet-cashier`)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    getPaymentLink(data) {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token);
                axios
                    .post('/get-payment-link', data)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    createTransactionLimits(data) {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token);
                axios
                    .post('/set-transaction-limits', data)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    getTransactionLimits() {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token);
                axios
                    .get('/get-transaction-limits')
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    updateTransactionLimits(id, data) {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token);
                axios
                    .put(`/update-transaction-limits/${id}`, data)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    sendMoneyToPharmacist(data) {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token);
                axios
                    .post('/transfer-to-pharmacist', data)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    sendMoneyToPatient(data) {
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token);
                axios
                    .post('/transfer-money-to-patient', data)
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    },
    getTransactionHistory() {        
        return new Promise((resolve, reject) => {
            const token = JSON.parse(localStorage.getItem('token'));
            if (token) {
                axios.setToken(token);
                axios
                    .get('/transactions')
                    .then(({ data }) => {
                        resolve(data);
                    })
                    .catch(({ response }) => {
                        reject(response?.data);
                    });
            } else {
                reject({ message: 'Token not found' });
            }
        });
    }
};

export default payment;