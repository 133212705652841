import React,{useState} from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import logo from "../../assets/logo.png";
import Layout from '../../layout/fullpage';
import auth from '../../Model/auth';

let obj ={
    email:""
}
function AuthResetPage() {
const [email,setEmail] = useState(obj);
const navigate = useNavigate();
const handleChange =(e) =>{
    const { name, value } = e?.target;
    setEmail((prev)=>({
        ...prev,
        [name]:value,
    }));
}
    const ForgotPassword =()=>{
        if(!email.email){
            Swal.fire({
                title: "Email is required",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
              })
              return
        }
        auth
        .forgotpassword(email)
        .then((res)=>{
            Swal.fire({
                title: "OTP Send Successfully",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              })
              navigate("/auths/verify-otp",{
                state: {
                  email: email.email,
                }})
        })
        .catch((err)=>{
            Swal.fire({
                title: err?.response?.data?.error,
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
              })
        })
    }
  return (
    <Layout title="Forgot Password" centered>
        <div className="container p-2 p-sm-4">
            <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                <Row className="g-0 flex-lg-row-reverse">
                    <Col lg="5" className="shadow rounded ">
                        <Card.Body className="h-100 d-flex flex-column justify-content-center">
                            <div className="nk-block-head text-center">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title mb-2">Reset password</h3>
                                    <p className="small col-md-10 mx-auto">If you forgot your password, don't worry! we’ll email you instructions to reset your password.</p>
                                </div>
                            </div>
                            <div>
                                <Col>
                                <Form.Group className="form-group">
                                            <Form.Label htmlFor="email">Email</Form.Label>
                                            <div className="form-control-wrap">
                                                <Form.Control type="email" id="email" name="email" onChange={(e)=> handleChange(e)} placeholder="Enter email address"/>
                                            </div>
                                        </Form.Group>
                                </Col>
                                <Col className='col-12 mt-2'>
                                    <Button className='col-12' onClick={()=> ForgotPassword()}>Send OTP</Button>
                                </Col>
                            </div>
                            <div className="text-center mt-4">
                                <p className="small"><Link to="/auths/auth-login">Back to Login</Link></p>
                            </div>
                        </Card.Body>
                    </Col>
                    <Col lg="7">
                        <Card.Body className="is-theme has-mask has-mask-1 h-100 d-flex flex-column align-items-center   ">
                           
                            <div className="brand-logo">
                            <img
                      src={logo}
                      alt="logo"
                      width={"300px"}
                      className="mt-3 "
                    />
                            </div>
                            
                           
                            
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </div>
    </Layout>
  )
}

export default AuthResetPage;
