import React, { useState } from "react";
import Layout from "../../../layout/default";
import { Card, Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import payment from "../../../Model/payment";
import Swal from "sweetalert2";


const SendMoneyPage = ({ pharmacistData, setIsTransferMoney, setPharmacistData }) => {
    const [loading, setLoading] = useState(false);
    const [sendMoneyData, setSendMoneyData] = useState({
        amount: '',
        receiverId: pharmacistData._id
    });
    const [error, setError] = useState("");


    const handleChange = (e) => {
        const { name, value } = e.target;
        setSendMoneyData({ ...sendMoneyData, [name]: value });
    };

    const handleSendMoney = () => {
        if (!sendMoneyData.amount || sendMoneyData.amount <= 0) {
            setError("Please enter a valid amount.");
            return;
        }
        setError("");
        setLoading(true);
        payment.sendMoneyToPharmacist(sendMoneyData)
            .then((res) => {
                Swal.fire({
                    title: res.message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setPharmacistData({});
                setIsTransferMoney(false);
            })
            .catch((error) => {
                Swal.fire({
                    title: error.error,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                });
            })
            .finally(() => setLoading(false));
    };


    return (
        <Layout title="Send Money To Pharmacist">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5>Send Money to {pharmacistData.name}</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="text-center mb-3">
                                <img
                                    src={pharmacistData.image}
                                    alt="Pharmacist"
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                    }}
                                />
                                <h6 className="mt-2">{pharmacistData.name}</h6>
                                <p className="text-muted">{pharmacistData.email}</p>
                            </div>
                            <Form>
                                {error && <Alert variant="danger">{error}</Alert>}
                                <Form.Group className="mb-3">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="amount"
                                        placeholder="Enter amount"
                                        value={sendMoneyData.amount}
                                        onChange={handleChange}
                                        disabled={loading}
                                    />
                                </Form.Group>
                                <div className="d-flex justify-content-between">
                                    <Button
                                        variant="secondary"
                                        onClick={() => setIsTransferMoney(false)}
                                        disabled={loading}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={handleSendMoney}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <Spinner animation="border" size="sm" />
                                        ) : (
                                            "Send Money"
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default SendMoneyPage;
