import React, { useEffect, useState } from 'react'
import useValidator from '../../../../plugins/useValidator'
import drugs from '../../../../Model/drugs'
import Swal from 'sweetalert2'
import { Icon } from '@iconify/react/dist/iconify.js'
import { Card, Modal, Row } from 'react-bootstrap'
import Layout from "../../../../layout/default"
import { Table } from 'antd'
import CreateDrugs from '../../../admin/Drugs/CreateDrugs'
import ViewDrug from '../../../admin/Drugs/ViewDrug'

const defaultDrug = {
  commercialName: "",
  activePrinciple: "",
  medicineImage: "",
  notice: "",
  price: "",
  substituteMedicine: ""
}
const PharmacistsDrugs = () => {
  const [drugsData, setDrugsData] = useState()
  const [searchData, setSearchData] = useState()
  const [data, setData] = useState(defaultDrug)
  const [deleteId, setDeleteId] = useState()
  const [isDelete, setIsDelete] = useState()
  const [validator, showMessage] = useValidator()
  const [isCreateDrug, setIsCreateDrug] = useState(false);
  const [viewDrug, setViewDrug] = useState(false)
  const handleChange = (e, name) => {
    if (name === "medicineImage") {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result.split(",")[1];
          setData((prev) => ({
            ...prev,
            [name]: base64String,
          }));
        };
        reader.readAsDataURL(file);
      }
    }
    else {
      setData((prev) => ({
        ...prev,
        [name]: e.target.value

      }))
    }
  }

  useEffect(() => {
    getAllDrugs()
  }, [])
  const getAllDrugs = () => {
    drugs
      .getAllDrugsDataByLoginUser()
      .then((res) => {
        setDrugsData(res)
      })
      .catch((error) => {
        Swal.fire({
          title: error.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }

  const columns = [
    {
      title: "SrNo",
      key: "number",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Medicine Name",
      dataIndex: "commercialName",
      render: (text, record) => (
        <div onClick={() => { setData(record); setViewDrug(true) }} style={{ color: "blue", cursor: "pointer" }}>{text}</div>

      )
    },
    {
      title: "Ative Principle",
      dataIndex: "activePrinciple",
    },
    {
      title: "Notice",
      dataIndex: "notice",
    },
    {
      title: "Substitute Medicine",
      dataIndex: "substituteMedicine",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div class="dropdown">
          <span className="dropdown-toggle" data-bs-toggle="dropdown">
          </span>
          <ul class="dropdown-menu" style={{ height: 'fit-content' }}>
            <Icon name="more-v"></Icon>
            <li>
              <p
                class="dropdown-item d-flex align-items-center p-1 text-warning "
                onClick={() => { setData(record); setIsCreateDrug(true) }}
              >
                <Icon icon="wpf:edit" className="me-1 " />
                <span className="col-5 ">Update</span>
              </p>
            </li>
            <li>
              <p
                class="dropdown-item d-flex align-items-center p-1 text-danger "
                onClick={() => { setIsDelete(true); setDeleteId(record._id) }}
              >
                <Icon icon="mingcute:delete-2-line" className="me-1 " />
                <span className="col-5 ">Delete</span>
              </p>
            </li>
          </ul>
        </div>
      ),
    },
  ]

  const error = {
    commercialName : validator.message("Medicine Name", data?.commercialName , "required"),
    activePrinciple: validator.message("Owner Name", data?.activePrinciple, "required"),
    notice: validator.message("Address", data?.notice, "required"),
    image:validator.message("Image" ,data?.medicineImage,"required"),
    price:validator.message("price" ,data?.price,"required"),
    substituteMedicine:validator.message("Substitute Medicine" ,data?.substituteMedicine,"required"),
}

  const onSearching = (e) => {
    const { value } = e?.target;
    setSearchData(() => {
      return drugsData?.filter((item) => {
        return (
          item?.commercialName?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.precautions?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.substituteMedicine?.toLowerCase().includes(value?.toLowerCase()) ||
          item?.sideEffects?.toLowerCase().includes(value?.toLowerCase())
        );
      });
    });
  };


  const deleteConfirm = () => {
    drugs
      .deleteDrug(deleteId)
      .then((res) => {
        setDeleteId()
        setIsDelete(false)
        getAllDrugs()
        Swal.fire({
          title: res.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        })
      })
      .catch((error) => {
        Swal.fire({
          title: error.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        })
        setDeleteId()
        setIsDelete(false)
      })
  }

  const updateDrug = () => {
    if (validator.allValid()) {
      drugs
        .updateDrug(data._id, data)
        .then((res) => {
          Swal.fire({
            title: res.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          })
          getAllDrugs()
          setIsCreateDrug(false)
          setData(defaultDrug)

        })
        .catch((error) => {
          setIsCreateDrug(false)
          setData(defaultDrug)
          Swal.fire({
            title: error.message,
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          })
        })
    }
    else {
      showMessage(true)
    }
  }

  const saveDrug = () => {
    if (validator.allValid()) {
      drugs
        .saveDrug(data)
        .then((res) => {
          Swal.fire({
            title: res.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          })
          getAllDrugs()
          setIsCreateDrug(false)
          setData(defaultDrug)
        })
        .catch((error) => {
          setData(defaultDrug)
          setIsCreateDrug(false)
          Swal.fire({
            title: error.message,
            icon: "error",
            showConfirmButton: false,
            timer: 1500,
          })
        })
    }
    else {
      showMessage(true)
    }
  }

  return (
    <>
      <Modal centered show={isDelete} onHide={() => setIsDelete(false)}>
        <Modal.Body>
          <p
            className="text-center"
            style={{ fontSize: "23px", fontWeight: "bold" }}
          >
            Confirm delete
          </p>
          <p className="text-center">

            <p className='mt-2'>Are you sure to delete this Drug</p>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div
            className="col-12 d-flex gap-2  justify-content-center"
            style={{ marginTop: "20px" }}
          >
            <button
              className="col-4 cancel-btn"
              onClick={() => { setIsDelete(false); setDeleteId() }}

            >
              Cancel
            </button>
            <button
              onClick={() => deleteConfirm()}

              className="col-4 delete-btn "
              style={{ marginRight: "15px" }}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>


      {!viewDrug ? (
        !isCreateDrug ? (
          <Layout title="Manage Drugs Data" centered>
            <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth ">
              <Row className=" p-3">
                <div className="container">
                  <h2 className="my-4">Drugs Details</h2>
                  <div className="mb-3 d-flex justify-content-between">
                    <input
                      type="text"
                      className="form-control w-50"
                      placeholder="Search Pharmacy..."
                      onChange={(e) => onSearching(e)}
                    />
                    <button className="btn btn-success" onClick={() => setIsCreateDrug(true)}>
                      <i className="bi bi-plus-circle"></i> Add Drug
                    </button>
                  </div>
                  <div>
                    <Table columns={columns} dataSource={searchData || drugsData} />
                  </div>
                </div>

              </Row>
            </Card>

          </Layout>
        ) : (
          <>
            <CreateDrugs
              data={data}
              setData={setData}
              handleChange={handleChange}
              error={error}
              showMessage={showMessage}
              defaultDrug={defaultDrug}
              setIsCreateDrug={setIsCreateDrug}
              saveDrug={saveDrug}
              updateDrug={updateDrug}
            /></>
        )
      ) : (
        <>
          <ViewDrug setViewDrug={setViewDrug}
            data={data}
            setData={setData}
            defaultDrug={defaultDrug} />
        </>
      )}
    </>
  )
}

export default PharmacistsDrugs