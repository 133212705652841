import React, { useEffect, useState } from 'react'
import { Card, Row } from 'react-bootstrap'
import Layout from "../../../../layout/default"
import users from '../../../../Model/users';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

import "../pharmacists.css"

const ProfileTimeline = () => {
  const [timelineData, setTimeLineData] = useState()
  const navigate = useNavigate();
  useEffect(() => {
    getTimeLineData()
  }, [])

  

  const getTimeLineData = () => {
    users
      .getTimelineData()
      .then((response) => [
        setTimeLineData(response)
      ])
      .catch((error) => {
        Swal.fire({
          title: error.message,
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        })
      })
  }
  return (
    <Layout title="Timeline">
      <Card>
        <Row className='p-3'>
         
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div className='d-flex justify-content-between'>
                  <span onClick={() => navigate("/dashboard")} style={{ marginRight: "5px", cursor: "pointer" }}><i class="fa fa-arrow-left" aria-hidden="true"></i></span>
                </div>
                <div className='fw-bold text-center' style={{ fontSize: "20px" }}>Modification History</div>
                { !timelineData ? (
                <div id="content" className=' mt-3'>
                  <ul class="timeline">
                    {timelineData?.map((item) => (
                      <li class="event" data-date={item?.date}>
                        <h3>{item?.author?.name}</h3>
                        <p>Update Information</p>
                      </li>
                    ))}
                  </ul>
                </div>
                ) : (
                  <div className='mt-3'>
                    <p className='text-center'>No Modification History Found</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Row>
      </Card>
    </Layout>
  )
}

export default ProfileTimeline