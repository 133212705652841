import React, { useEffect, useState } from 'react';
import Layout from "../../layout/default";
import { Card, Row, Spinner } from 'react-bootstrap';
import { DatePicker, Input, Select, } from 'antd';
import flag from '../../assets/flag.png';
import moment from 'moment';
import assurance from '../../Model/assurance';
import paymentMethod from '../../Model/paymentMethod';


const CreatePharmacy = ({
    setIsCreatePharmacy,
    pharmacyData,
    setPharmacy,
    updatePharmacy,
    defaultPharmacy,
    savePharmacy,
    error,
    handleChange,
    showMessage,
    loading
}) => {
    const [assuranceData, setAssuranceData] = useState([]);
    const [paymentMethodData, setPaymentMethodData] = useState([]);


    useEffect(() => {
        getAllAssurance();
        getPaymentMethods();
    }, []);

    const getAllAssurance = () => {
        assurance.getAllAssurances()
            .then((res) => {
                setAssuranceData(res);
            }).catch((error) => {
                console.log("Error in get all assurnace");
            })
    };

    const getPaymentMethods = () => {
        paymentMethod.getAllPaymentMethods()
            .then((res) => {
                setPaymentMethodData(res);
            }).catch((error) => {
                console.log("Error in get payment method data");
            })
    };


    return (
        <Layout title="Manage Pharmacies Data" centered>
            <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth ">
                <Row className=" p-3">
                    <div className="container d-flex">
                        <h2 className="my-4" style={{ cursor: "pointer" }} onClick={() => { setPharmacy(defaultPharmacy); setIsCreatePharmacy(false); showMessage(false) }}>Pharmacies /  </h2>
                        <h2 className="my-4" style={{ color: "#8CBF22" }}>{pharmacyData?._id ? "Update Pharmacy" : "Create Pharmacy"}</h2>
                    </div>

                    <div className='container d-flex flex-wrap ' >
                        <div className='col-12 col-md-6 px-2 my-1'>
                            <label htmlFor="pharmacyName" className="form-label">
                                <strong>Pharmacy Name</strong><span className='text-danger'>*</span>
                            </label>
                            <Input

                                placeholder="Enter name of the Pharmacy"
                                name='pharmacyName'
                                value={pharmacyData?.pharmacyName}
                                onChange={(e) => handleChange(e, "pharmacyName")}
                            />
                            {error?.pharmacyName && (
                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                    {" "}
                                    {error?.pharmacyName}
                                </p>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2 my-1'>
                            <label htmlFor="ownerName" className="form-label">
                                <strong>Owner Name</strong><span className='text-danger'>*</span>
                            </label>
                            <Input

                                placeholder="Enter name of the Owner"
                                name='ownerName'
                                value={pharmacyData?.ownerName}
                                onChange={(e) => handleChange(e, "ownerName")}
                            />
                            {error?.ownerName && (
                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                    {" "}
                                    {error?.ownerName}
                                </p>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2 my-1'>
                            <label htmlFor="pharmacyAddress" className="form-label">
                                <strong>Pharmacy Address</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter name of the Pharmacy"
                                name='pharmacyAddress'
                                value={pharmacyData?.pharmacyAddress}
                                onChange={(e) => handleChange(e, "pharmacyAddress")}
                            />
                            {error?.pharmacyAddress && (
                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                    {" "}
                                    {error?.pharmacyAddress}
                                </p>
                            )}
                        </div>

                        <div className='col-12 col-md-6 px-2 my-1'>
                            <label htmlFor="geoGraphicLocation" className="form-label">
                                <strong>Geo Graphic Location</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type={"text"}
                                placeholder="Enter Geo Graphic Location"
                                name='geoGraphicLocation'
                                value={pharmacyData?.geoGraphicLocation}
                                onChange={(e) => handleChange(e, "geoGraphicLocation")}
                            />
                            {error?.geoGraphicLocation && (
                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                    {" "}
                                    {error?.geoGraphicLocation}
                                </p>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2 my-1'>
                            <label htmlFor="contactNumber" className="form-label">
                                <strong>Contact Number</strong><span className='text-danger'>*</span>
                            </label>
                            <div className='d-flex'>
                                <div className='rounded-2 align-items-center d-flex px-2 py-1 mx-1' style={{ background: '#f2f0f0' }}>
                                    <img style={{ height: "15px", width: "15px" }} src={flag} alt='' />
                                    <span style={{ fontSize: "12px" }} className='fw-bold mx-2'>+225</span>
                                </div>
                                <Input
                                    placeholder="Enter Contact Number"
                                    name='contactNumber'
                                    value={pharmacyData?.contactNumber?.replace(/^\+225/, '')}
                                    onChange={(e) => handleChange(e, "contactNumber")}
                                />
                            </div>
                            {error?.contactNumber && (
                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                    {" "}
                                    {error?.contactNumber}
                                </p>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2 my-1'>
                            <label htmlFor="whatsAppNumber" className="form-label">
                                <strong>WhatsApp Number</strong><span className='text-danger'>*</span>
                            </label>
                            <div className='d-flex'>
                                <div className='rounded-2 align-items-center d-flex px-2 py-1 mx-1' style={{ background: '#f2f0f0' }}>
                                    <img style={{ height: "15px", width: "15px" }} src={flag} alt='' />
                                    <span style={{ fontSize: "12px" }} className='fw-bold mx-2'>+225</span>
                                </div>
                                <Input
                                    type={"text"}
                                    placeholder="Enter WhatsApp Number"
                                    name='whatsAppNumber'
                                    value={pharmacyData?.whatsAppNumber?.replace(/^\+225/, '')}
                                    onChange={(e) => handleChange(e, "whatsAppNumber")}
                                />
                            </div>
                            {error?.whatsAppNumber && (
                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                    {" "}
                                    {error?.whatsAppNumber}
                                </p>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2 my-1'>
                            <label htmlFor="startDate" className="form-label">
                                <strong>Start Date</strong><span className='text-danger'>*</span>
                            </label>
                            <div>
                                <DatePicker
                                    className='col-12'
                                    name="startDate"
                                    value={pharmacyData?.startDate ? moment(pharmacyData.startDate) : null}
                                    onChange={(date) => handleChange(date, "startDate")}
                                />
                            </div>
                            {error?.startDate && (
                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                    {" "}
                                    {error?.startDate}
                                </p>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2 my-1'>
                            <label htmlFor="endDate" className="form-label">
                                <strong>End Date</strong><span className='text-danger'>*</span>
                            </label>
                            <div>
                                <DatePicker
                                    className='col-12'
                                    name="endDate"
                                    value={pharmacyData?.endDate ? moment(pharmacyData.endDate) : null}
                                    onChange={(date) => handleChange(date, "endDate")}
                                />
                            </div>
                            {error?.endDate && (
                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                    {" "}
                                    {error?.endDate}
                                </p>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2 my-1'>
                            <label htmlFor="paymentMethods" className="form-label">
                                <strong>Payment Methods</strong><span className='text-danger'>*</span>
                            </label>
                            <div>
                                <Select
                                    className='col-12'
                                    mode="multiple"
                                    placeholder="Please select payment method"
                                    value={pharmacyData?.paymentMethods}
                                    onChange={(value) => handleChange(value, "paymentMethods")}
                                    options={
                                        paymentMethodData?.map((item) => ({
                                            label: (
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <img
                                                        src={item.paymentMethodImage}
                                                        alt={item.paymentMethodName}
                                                        style={{ width: 20, height: 20, marginRight: 8 }}
                                                    />
                                                    {item.paymentMethodName}
                                                </div>
                                            ),
                                            value: item?._id,
                                            text: item.paymentMethodName
                                        }))
                                    }
                                    filterOption={(input, option) =>
                                        option?.text.toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </div>
                            {error?.paymentMethods && (
                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                    {" "}
                                    {error?.paymentMethods}
                                </p>
                            )}
                        </div>

                        <div className='col-12 col-md-6 px-2 my-1'>
                            <label htmlFor="insurances" className="form-label">
                                <strong>Insurances</strong><span className='text-danger'>*</span>
                            </label>
                            <Select
                                className='col-12'
                                mode="multiple"
                                placeholder="Please select insurance"
                                value={pharmacyData?.insurances}
                                onChange={(value) => handleChange(value, "insurances")}
                                options={
                                    assuranceData?.map((item) => ({
                                        label: (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <img
                                                    src={item.assuranceImage}
                                                    alt={item.assuranceName}
                                                    style={{ width: 20, height: 20, marginRight: 8 }}
                                                />
                                                {item.assuranceName}
                                            </div>
                                        ),
                                        value: item?._id,
                                        text: item.assuranceName
                                    }))
                                }
                                filterOption={(input, option) =>
                                    option?.text.toLowerCase().includes(input.toLowerCase())
                                }
                            />
                            {error?.insurances && (
                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                    {" "}
                                    {error?.insurances}
                                </p>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-2 my-1'>
                            <label htmlFor="pharmacyImage" className="form-label">
                                <strong>Pharmacy Image</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type="file"
                                name="pharmacyImage"
                                accept="image/*"
                                onChange={(e) => handleChange(e, "pharmacyImage")}
                            />
                            {error?.image && (
                                <p className="error" style={{ color: "red", fontSize: 11 }}>
                                    {" "}
                                    {error?.image}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="col-12 d-flex justify-content-center px-2 py-5">
                        <button
                            onClick={() => { pharmacyData?._id ? updatePharmacy() : savePharmacy() }}
                            className="col-4 primary-btn "
                            style={{ marginRight: "15px", backgroundColor: '#8CBF22' }}
                        >
                            {loading ? (
                                <Spinner animation="border" size="sm" />
                            ) : (
                                pharmacyData?._id ? "Update" : "Save"
                            )}
                        </button>
                    </div>
                </Row>
            </Card>

        </Layout>
    )
}

export default CreatePharmacy;
