import { Input } from 'antd'
import React from 'react'
import { Card, Row } from 'react-bootstrap'
import Layout from "../../../layout/default"

const UpdateUser = ({ user, handelChnage, setIsUpdateUser, updateUser,error,showMessage }) => {
    return (
        <Layout title="Manage Users Data" centered>
            <Card>
                <Row className=" p-3">
                    <div className="container d-flex">
                        <h2 className="my-4" style={{ cursor: "pointer" }} onClick={() => { setIsUpdateUser(false);showMessage(false) }}>Patients /  </h2>
                        <h2 className="my-4" style={{ color: "#8CBF22" }}>{user?._id && "Update Patient"}</h2>
                    </div>
                    <div className='container d-flex flex-wrap '>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="pharmacyName" className="form-label">
                                <strong>Patient First Name</strong>
                            </label>
                            <Input
                                placeholder="Enter first name of the user"
                                name='firstName'
                                value={user?.firstName}
                                onChange={(e) => handelChnage("firstName", e)}

                            />
                            {error?.firstName && (
                            <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                {" "}
                                {error?.firstName}
                            </span>
                        )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="pharmacyName" className="form-label">
                                <strong>Patient Full Name</strong>
                            </label>
                            <Input
                                placeholder="Enter Full name of the User"
                                name='name'
                                value={user?.name}
                                onChange={(e) => handelChnage("name", e)}
                            />
                            {error?.name && (
                            <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                {" "}
                                {error?.name}
                            </span>
                        )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="ownerName" className="form-label">
                                <strong>Email </strong>
                            </label>
                            <Input
                                placeholder="Enter email of the user"
                                name='email'
                                value={user?.email}
                                onChange={(e) => handelChnage("email", e)}
                            />
                            {error?.email && (
                            <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                {" "}
                                {error?.email}
                            </span>
                        )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="pharmacyImage" className="form-label">
                                <strong>Patient Image</strong>
                            </label>
                            <Input
                                type="file"
                                name="image"
                                accept="image/*"
                                onChange={(e) => handelChnage("image", e)}
                            />
                            {/* {error?.substituteMedicine && (
                            <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                {" "}
                                {error?.substituteMedicine}
                            </span>
                        )} */}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="pharmacyAddress" className="form-label">
                                <strong>Contact number</strong>
                            </label>
                            <Input
                                placeholder="Enter Contact Number"
                                name='mobileNumber'
                                value={user?.mobileNumber}
                                onChange={(e) => handelChnage("mobileNumber", e)}
                            />
                            {error?.mobileNumber && (
                            <span className="error" style={{ color: "red", fontSize: "11px" }}>
                                {" "}
                                {error?.mobileNumber}
                            </span>
                        )}
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-center px-2 py-5">
                        <button
                            onClick={() => updateUser()}
                            className="col-4 primary-btn "
                            style={{ marginRight: "15px", backgroundColor: '#8CBF22' }}
                        >
                            Update
                        </button>
                    </div>
                </Row>
            </Card>
        </Layout>
    )
}

export default UpdateUser