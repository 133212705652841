import React, { useEffect, useState } from 'react'
import Layout from "../../../../layout/default"
import { Button, Card, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import users from '../../../../Model/users'
import Swal from 'sweetalert2'
import profile from "../../../../assets/images/profile.png"
import useValidator from '../../../../plugins/useValidator'
import auth from '../../../../Model/auth'
import UpdateProfile from './UpdateProfile'


const MyProfile = () => {
    const [user, setUser] = useState();
    const [isUpdateUser, setIsUpdateUser] = useState(false);
    const [validator, showMessage] = useValidator();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();


    useEffect(() => {
        getAuthUser();
    }, []);

    const handelChnage = (name, e) => {
        if (name === "image") {
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result.split(",")[1];
                    setUser((prev) => ({
                        ...prev,
                        [name]: base64String,
                    }));
                };
                reader.readAsDataURL(file);
            }
        }
        else {
            setUser((prev) => ({
                ...prev,
                [name]: e.target.value
            }))
        }
    };

    const error = {
        name: validator.message("Name", user?.name, "required"),
        email: validator.message("Email", user?.email, "required"),
        mobileNumber: validator.message("Contact Number", user?.mobileNumber, "required|phone|regex:^\\d{10}$"),
        city: validator.message("City", user?.customer_city, "required"),
        address: validator.message("address", user?.customer_address, "required"),
        country: validator.message("country", user?.customer_country, "required"),
        state: validator.message("state", user?.customer_state, "required"),
        zipCode: validator.message("zip code", user?.customer_zip_code, "required"),
        sex: validator.message("sex", user?.sex, "required"),
    };

    const updateUser = () => {
        if (validator.allValid()) {
            setLoading(true);
            auth
                .updateAuthUser(user)
                .then((response) => {
                    Swal.fire({
                        title: "Profile Updated Successfully",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    setIsUpdateUser(false);
                    getAuthUser();
                }).catch((error) => {
                    Swal.fire({
                        title: error.message,
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    })
                }).finally(() => setLoading(false));
        }
        else {
            showMessage(true)
        }
    };

    const getAuthUser = () => {
        users
            .getAuthUser()
            .then((response) => {
                setUser(response)
            })
            .catch((error) => {
                Swal.fire({
                    title: error.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 1500,
                })

            })
    };


    return (
        <>
            {!isUpdateUser ? (
                <Layout title="My Profile">
                    <Card className="shadow-sm p-4" style={{ borderRadius: "10px", backgroundColor: "#f9f9f9" }}>
                        <Row>
                            {/* Header Section */}
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <span
                                    onClick={() => navigate("/dashboard")}
                                    style={{ marginRight: "5px", cursor: "pointer", fontSize: "1.2rem", color: "#007bff" }}
                                >
                                    <i className="fa fa-arrow-left"></i>
                                </span>
                                <Button
                                    onClick={() => setIsUpdateUser(true)}
                                    className="btn btn-primary"
                                    style={{ borderRadius: "20px" }}
                                >
                                    Update Profile
                                </Button>
                            </div>

                            {/* Profile Card */}
                            <Card
                                style={{
                                    padding: "20px",
                                    backgroundColor: "#f9f9f9",
                                    border: 'none'
                                }}
                            >
                                {/* Profile Image and Name */}
                                <div className="text-center mb-4">
                                    <img
                                        src={user?.image ? user?.image : profile}
                                        alt="userImage"
                                        style={{
                                            height: "120px",
                                            width: "120px",
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                            border: "3px solid #007bff",
                                        }}
                                    />
                                    <h5 className="mt-3" style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                                        {user?.name || "User Name"}
                                    </h5>
                                </div>

                                {/* User Information */}
                                <div className="row">
                                    {[
                                        { label: "Email", value: user?.email },
                                        { label: "Contact Number", value: `+225${user?.mobileNumber}` },
                                        { label: "Address", value: user?.customer_address },
                                        { label: "Country", value: user?.customer_country },
                                        { label: "City", value: user?.customer_city },
                                        { label: "State", value: user?.customer_state },
                                    ]?.map((info, index) => (
                                        <div key={index} className="col-sm-6 col-lg-4 mb-3">
                                            <div style={{ fontSize: "0.9rem", fontWeight: "600", color: "#333" }}>
                                                {info.label}:
                                            </div>
                                            <div style={{ fontSize: "0.85rem", color: "#555" }}>{info.value || "N/A"}</div>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </Row>
                    </Card>
                </Layout>
            ) : (
                <>
                    <UpdateProfile
                        user={user}
                        setIsUpdateUser={setIsUpdateUser}
                        showMessage={showMessage}
                        error={error}
                        handelChnage={handelChnage}
                        updateUser={updateUser}
                        loading={loading}
                    />
                </>
            )}
        </>
    )
}

export default MyProfile