import React from 'react'
import { Card, Row } from 'react-bootstrap'
import Layout from "../../../../layout/default"
import { Input, Select } from 'antd'
const CreateOrdermanagers = ({orderManager,handelChnage,error,setIsCreateOrderManager,createOrdermanager,showMessage}) => {
  return (
    <Layout title="Create Order Manager">
        <Card>
            <Row className='p-3'>
            <div className="container d-flex">
                        <h2 className="my-4" style={{ cursor: "pointer" }} onClick={() => { setIsCreateOrderManager(false); showMessage(false); }}>OrderManager /  </h2>
                        <h2 className="my-4" style={{ color: "#8CBF22" }}>{"Create Order Manager"}</h2>
                    </div>
                    <div className='container d-flex flex-wrap '>
                        
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="name" className="form-label">
                                <strong>First Name</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter first name of the orderManager"
                                name='firstName'
                                value={orderManager?.firstName}
                                onChange={(e) => handelChnage("firstName", e)}
                              
                            />
                            {error?.firstName && (
                                <span className="error" style={{ color: "red",fontSize:"11px" }}>
                                    {" "}
                                    {error?.firstName}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="full name" className="form-label">
                                <strong>Full Name</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter Full name of the orderManager"
                                name='name'
                                value={orderManager?.name}
                                onChange={(e) => handelChnage("name", e)}
                              
                            />
                            {error?.name && (
                                <span className="error" style={{ color: "red",fontSize:"11px" }}>
                                    {" "}
                                    {error?.name}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="email" className="form-label">
                                <strong>Email </strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                type="email"
                                id="email"
                                placeholder="Enter email"
                                name='email'
                                value={orderManager?.email}
                                onChange={(e) => handelChnage("email", e)}
                                required
                              
                            />
                            {error?.email && (
                                <span className="error" style={{ color: "red",fontSize:"11px" }}>
                                    {" "}
                                    {error?.email}
                                </span>
                            )}
                        </div>
                        {!orderManager?._id && (
                            <div className='col-12 col-md-6 px-1'>
                                <label htmlFor="Password" className="form-label">
                                    <strong>Password</strong><span className='text-danger'>*</span>
                                </label>
                                <Input.Password
                                    value={orderManager?.password}
                                    onChange={(e) => handelChnage("password", e)}
                                  
                                    name="password" placeholder="input password" />

                                {error?.password && (
                                    <span className="error" style={{ color: "red",fontSize:"11px" }}>
                                        {" "}
                                        {error?.password}
                                    </span>
                                )}
                            </div>
                        )}
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="mobileNumber" className="form-label">
                                <strong>Contact Number</strong><span className='text-danger'>*</span>
                            </label>
                            <Input
                                placeholder="Enter Contact Number"
                                name='mobileNumber'
                                value={orderManager?.mobileNumber}
                              
                                onChange={(e) => handelChnage("mobileNumber", e)}
                            />
                            {error?.mobileNumber && (
                                <span className="error" style={{ color: "red",fontSize:"11px" }}>
                                    {" "}
                                    {error?.mobileNumber}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-6 px-1'>
                            <label htmlFor="sex" className="form-label">
                                <strong>Sex</strong><span className='text-danger'>*</span>
                            </label>
                            <Select
                                className="col-12"
                                placeholder="Please select sex"
                                value={orderManager?.sex}
                                name="sex"
                                onChange={(value) => handelChnage("sex", value)}
                                options={[
                                    { label: "MALE", value: "MALE" },
                                    { label: "FEMALE", value: "FEMALE" },
                                    { label: "OTHER", value: "OTHER" }
                                ]}
                            />
                            {error?.sex && (
                                <span className="error" style={{ color: "red",fontSize:"11px" }}>
                                    {" "}
                                    {error?.sex}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-center px-2 py-5">
                        <button
                            onClick={() => {createOrdermanager()}}
                            className="col-4 primary-btn "
                            style={{ marginRight: "15px", backgroundColor: '#8CBF22' }}
                        >
                            Save
                        </button>
                    </div>

            </Row>
        </Card>
    </Layout>
        
  )
}

export default CreateOrdermanagers